import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import { FunctionComponent } from 'react';
import { ComboButton, ComboButtonAction, ComboButtonContent, ComboButtonTitle } from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';

interface AddGuestButtonProps {
    onClick(): void;
}

export const AddGuestButton: FunctionComponent<AddGuestButtonProps> = ({ onClick, children }) => {
    return (
        <ComboButton color="transparent" onClick={onClick}>
            <ComboButtonAction color="iceCubeBlue">
                <FontAwesomeIcon icon={faUserFriends} width={32} />
            </ComboButtonAction>

            <ComboButtonContent>
                <ComboButtonTitle>{children}</ComboButtonTitle>
            </ComboButtonContent>

            <ComboButtonAction color="iceCubeBlue">
                <FontAwesomeIcon icon={faPlus} width={20} />
            </ComboButtonAction>
        </ComboButton>
    );
};
