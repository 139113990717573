import clsx from 'clsx';
import { forwardRef, TextareaHTMLAttributes } from 'react';
import classes from './TextAreaField.module.scss';

interface TextAreaFieldProps
    extends Pick<
        TextareaHTMLAttributes<HTMLTextAreaElement>,
        'name' | 'value' | 'defaultValue' | 'disabled' | 'rows' | 'onBlur' | 'onChange'
    > {
    label: string;
    placeholder?: string;
    className?: string;
}

/**
 * A simple text area without border used for embedding in a ExpandedComboButton.
 */
export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
    ({ name, value, defaultValue, disabled, rows, onBlur, onChange, label, placeholder, className }, ref) => {
        return (
            <textarea
                ref={ref}
                name={name}
                value={value}
                defaultValue={defaultValue}
                disabled={disabled}
                rows={rows}
                onBlur={onBlur}
                onChange={onChange}
                aria-label={label}
                placeholder={placeholder}
                className={clsx(classes.textarea, className)}
            />
        );
    },
);
