import clsx from 'clsx';
import { FunctionComponent, MouseEventHandler } from 'react';
import { Ripple } from '../../utils/Ripple/Ripple';
import classes from './Button.module.scss';

interface ButtonProps {
    type?: 'button' | 'submit' | 'reset';
    color?:
        | 'ballPitBlue'
        | 'dinosaurGreen'
        | 'raceCarRed'
        | 'minionYellow'
        | 'grandparentGrey50'
        | 'grandparentGrey900'
        | 'sunnyYellow'
        | 'transparent';
    fullwidth?: boolean;
    autoWidth?: boolean;
    autoHeight?: boolean;
    disabled?: boolean;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Design system Button with different color options.
 */
export const Button: FunctionComponent<ButtonProps> = ({
    type = 'button',
    color = 'ballPitBlue',
    fullwidth,
    autoWidth,
    autoHeight,
    disabled,
    className,
    onClick,
    children,
}) => {
    const classNames = clsx(classes.container, classes[color], className, {
        [classes.fullwidth]: fullwidth,
        [classes.autoWidth]: autoWidth,
        [classes.autoHeight]: autoHeight,
    });

    return (
        <Ripple disabled={disabled} className={classes.ripple}>
            {(props, ripples) => (
                <button type={type} disabled={disabled} className={classNames} onClick={onClick} {...props}>
                    {ripples}
                    <span className={classes.content}>{children}</span>
                </button>
            )}
        </Ripple>
    );
};
