import { Guest } from 'model';
import { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertText } from '../../../components/common/Alert/Alert';
import { AlertDialog } from '../../../components/common/AlertDialog/AlertDialog';
import { Button } from '../../../components/common/Button/Button';
import { Card, CardText, CardTitle } from '../../../components/common/Card/Card';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { Typography } from '../../../components/common/Typography/Typography';
import { FadeIn } from '../../../components/utils/FadeIn/FadeIn';
import { useApi } from '../../../contexts/ApiProvider';
import { useBooking } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import { AddGuestButton } from '../../../features/edit-guests/AddGuestButton/AddGuestButton';
import { AddGuestForm } from '../../../features/edit-guests/AddGuestForm/AddGuestForm';
import { BirthdayGuestCard } from '../../../features/edit-guests/BirthdayGuestCard/BirthdayGuestCard';
import { GuestCard } from '../../../features/edit-guests/GuestCard/GuestCard';
import useApiError from '../../../hooks/useApiError';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { Stage } from '../../../model/stage';
import { getFirstEmptyGuest, isEmptyGuest } from '../../../utils';
import classes from './EditGuests.module.scss';

export const EditGuests: FunctionComponent = () => {
    const booking = useBooking();
    const { formatMessage } = useIntl();
    const { openStage } = useBookingParams();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { toastApiError } = useApiError();
    const { client } = useApi();

    const [maxGuestsDialogOpen, setMaxGuestsDialogOpen] = useState<boolean>(false);
    const [remindDialogOpen, setRemindDialogOpen] = useState<boolean>(false);
    const [guestToEdit, setGuestToEdit] = useState<Guest | null>(null);

    // If any of the guests have entered allergy information, we will show an alert with additional information.
    const hasGuestAllergies = useMemo(() => {
        if (!booking.details || booking.details.guests.length === 0) {
            return false;
        }

        return booking.details.guests.find((g) => !!g.guestallergymessage) !== undefined;
    }, [booking.details]);

    if (!booking.details) {
        return null;
    }

    const emptyGuest = getFirstEmptyGuest(booking.details.guestList);

    const addGuestClicked = () => {
        if (emptyGuest) {
            setGuestToEdit(emptyGuest);
            window.scrollTo(0, 0);
        } else {
            setMaxGuestsDialogOpen(true);
        }
    };

    const onGuestClick = (guest: Guest) => {
        setGuestToEdit(guest);
        window.scrollTo(0, 0);
    };

    async function onRemindDialogClose(confirmed: boolean) {
        setRemindDialogOpen(false);

        if (!confirmed || !booking.details) {
            return;
        }

        incrementLoader();

        try {
            await client.remindPendingGuests(booking.details.guid);
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();
    }

    const onBackClick = () => {
        if (guestToEdit) {
            setGuestToEdit(null);
            window.scrollTo(0, 0);
        } else {
            openStage(Stage.BookingOverview, booking.details);
        }
    };

    const onEditInvitationClick = () => {
        openStage(Stage.EditInvitation, booking.details);
    };

    const onMaxGuestsDialogClose = (confirmed: boolean) => {
        if (confirmed) {
            openStage(Stage.EditGuestCount, booking.details);
        } else {
            setMaxGuestsDialogOpen(false);
        }
    };

    return (
        <>
            <AlertDialog
                type="warning"
                open={maxGuestsDialogOpen}
                className={classes.alertDialog}
                text={formatMessage({
                    id: 'edit-guests.max-guests-alert-text',
                    defaultMessage: 'Din gästlista är full! Öka antalet gäster för att kunna lägga till fler.',
                })}
            >
                <Button color="minionYellow" onClick={() => onMaxGuestsDialogClose(true)}>
                    <FormattedMessage id="edit-guests.max-guests-alert-button" defaultMessage="Redigera gästantalet" />
                </Button>

                <Button color="grandparentGrey900" onClick={() => onMaxGuestsDialogClose(false)}>
                    <FormattedMessage id="general.close" defaultMessage="Stäng" />
                </Button>
            </AlertDialog>

            <AlertDialog
                type="warning"
                open={remindDialogOpen}
                className={classes.alertDialog}
                text={formatMessage({
                    id: 'edit-guests.remind-guests-alert-text',
                    defaultMessage: 'Är du säker på att du vill påminna alla gäster som inte har svarat?',
                })}
            >
                <Button color="minionYellow" onClick={() => onRemindDialogClose(true)}>
                    <FormattedMessage id="general.yes" defaultMessage="Ja" />
                </Button>

                <Button color="grandparentGrey900" onClick={() => onRemindDialogClose(false)}>
                    <FormattedMessage id="general.cancel" defaultMessage="Avbryt" />
                </Button>
            </AlertDialog>

            <PageContainer
                title={
                    guestToEdit
                        ? isEmptyGuest(guestToEdit)
                            ? formatMessage({ id: 'edit-guests.add-guest-title', defaultMessage: 'Bjud in gäst' })
                            : formatMessage({ id: 'edit-guests.edit-guest-title', defaultMessage: 'Redigera gäst' })
                        : formatMessage({ id: 'edit-guests.title', defaultMessage: 'Inbjudningar' })
                }
                onBackClick={onBackClick}
            >
                {!booking.details.canChangeGuests && (
                    <Alert className={classes.alertBoxNoInvite}>
                        <AlertText>
                            <FormattedMessage
                                id="alerts.invitation-disabled"
                                defaultMessage="Du kan tyvärr inte bjuda in eller ta bort gäster när det är mindre än 4 dagar kvar till kalaset."
                            />
                        </AlertText>
                    </Alert>
                )}

                {guestToEdit && (
                    <FadeIn enter enterOnMount className={classes.fadeIn}>
                        <AddGuestForm booking={booking} guest={guestToEdit} onBackClick={onBackClick} />
                    </FadeIn>
                )}

                {!guestToEdit && (
                    <FadeIn enter enterOnMount className={classes.fadeIn}>
                        <Card dark className={classes.card}>
                            <CardTitle>
                                <FormattedMessage id="edit-guests.card-title" defaultMessage="Nu är ditt inbjudningskort klart!" />
                            </CardTitle>

                            <CardText component="div">
                                <FormattedMessage
                                    id="edit-guests.card-text"
                                    defaultMessage="<p>Nu återstår bara att bjuda in gäster. Du bjuder enkelt in via SMS eller e-post, kan se vilka som kommer, inte har svarat och kan påminna.</p><p>Om någon tackar nej behöver du ta bort den gästen för att justera gästantalet. Du betalar för ditt angivna gästantal, observera minimiantal för vald dag. Kom ihåg att justera ditt gästantal senast 5 dagar före ditt kalas.</p>"
                                    values={{ p: (...chunks: any[]) => <p className={classes.cardParagraph}>{chunks}</p> }}
                                />
                            </CardText>
                        </Card>

                        <div className={classes.guestCount}>
                            {booking.details.birthdayKids.length + booking.details.guests.length}/{booking.details.guestCount}
                        </div>

                        <Typography variant="title5" className={classes.label}>
                            <FormattedMessage id="edit-guests.birthday-guests-title" defaultMessage="Födelsedagsbarn" />
                        </Typography>

                        <div className={classes.birthdayGuestList}>
                            {booking.details.birthdayKids.map((guest) => (
                                <BirthdayGuestCard key={guest.inviteid} guest={guest} />
                            ))}
                        </div>

                        {(booking.details.canChangeGuests || booking.details.guests.length > 0) && (
                            <div className={classes.guestListWrapper}>
                                <Typography variant="title5" className={classes.label}>
                                    <FormattedMessage id="edit-guests.guests-title" defaultMessage="Gäster" />
                                </Typography>

                                <div className={classes.guestList}>
                                    {booking.details.guests.map((guest) => (
                                        <GuestCard key={guest.inviteid} guest={guest} onClick={onGuestClick} />
                                    ))}

                                    {booking.details.canChangeGuests && booking.details.guests.length === 0 && (
                                        <Alert className={classes.noInvitationChangeAlert}>
                                            <AlertText type="warning">
                                                <FormattedMessage
                                                    id="edit-guests.no-invitation-change-after-invite-alert"
                                                    defaultMessage="Dubbelkolla att ditt kort är korrekt innan du börjar bjuda in, det går inte att ändra kortet efter att inbjudan är skickad."
                                                />
                                            </AlertText>
                                        </Alert>
                                    )}

                                    {booking.details.canChangeGuests && (
                                        <AddGuestButton onClick={addGuestClicked}>
                                            <FormattedMessage id="edit-guests.list-add" defaultMessage="Bjud in fler gäster" />
                                        </AddGuestButton>
                                    )}
                                </div>
                            </div>
                        )}

                        {hasGuestAllergies && (
                            <Alert className={classes.allergiesForwardedInfo}>
                                <AlertText>
                                    <FormattedMessage
                                        id="edit-guests.allergies-forwarded-info"
                                        defaultMessage="Dina gästers allergiinformation har vidarebefordrats till leklandet, du behöver inte uppdatera den."
                                    />
                                </AlertText>
                            </Alert>
                        )}

                        <PageButtons alignBottom>
                            {booking.details.canChangeGuests && booking.details.guests.length === 0 && (
                                <Button color="minionYellow" onClick={onEditInvitationClick}>
                                    <FormattedMessage id="edit-guests.button-edit-invitation" defaultMessage="Redigera inbjudningskort" />
                                </Button>
                            )}

                            {booking.details.guests.length > 0 && (
                                <Button color="minionYellow" onClick={() => setRemindDialogOpen(true)}>
                                    <FormattedMessage id="edit-guests.button-remind" defaultMessage="Påminn gäster att svara" />
                                </Button>
                            )}

                            <Button color="transparent" onClick={onBackClick}>
                                <FormattedMessage id="general.to-overview" defaultMessage="Till översikt" />
                            </Button>
                        </PageButtons>
                    </FadeIn>
                )}
            </PageContainer>
        </>
    );
};
