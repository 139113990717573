import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { FunctionComponent, Key } from 'react';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { FadeInImage } from '../../../components/utils/FadeInImage/FadeInImage';
import classes from './ImagePicker.module.scss';

export interface ImagePickerImage {
    key: Key;
    src: string;
}

interface ImagePickerProps {
    images: ImagePickerImage[];
    value: Key | null;
    className?: string;
    onChange(value: Key): void;
}

/**
 * Allows the user to select one of the supplied images via a grid.
 */
export const ImagePicker: FunctionComponent<ImagePickerProps> = ({ images, value, className, onChange }) => {
    const items = images.map((image) => {
        const checkboxClassNames = clsx(classes.checkbox, {
            [classes.checkboxSelected]: image.key === value,
        });

        return (
            <div key={image.key} className={classes.item} onClick={() => onChange(image.key)}>
                <FadeInImage src={image.src} className={classes.image} />

                <div className={checkboxClassNames}>
                    <FontAwesomeIcon icon={faCheck} width={14.5} className={classes.check} />
                </div>
            </div>
        );
    });

    return <div className={clsx(classes.container, className)}>{items}</div>;
};
