import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertText } from '../../../components/common/Alert/Alert';
import { AlertDialog } from '../../../components/common/AlertDialog/AlertDialog';
import { Button } from '../../../components/common/Button/Button';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { Typography } from '../../../components/common/Typography/Typography';
import { FieldError } from '../../../components/form/FieldError/FieldError';
import { SelectField } from '../../../components/form/SelectField/SelectField';
import { TextField } from '../../../components/form/TextField/TextField';
import { useApi } from '../../../contexts/ApiProvider';
import { BookingService } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import useApiError from '../../../hooks/useApiError';
import { useCountries } from '../../../hooks/useCountries';
import { Guest } from '../../../model';
import { BookingDetails } from '../../../model/BookingDetails';
import { ApiError, isEmptyGuest } from '../../../utils';
import ApiClient from '../../../utils/ApiClient';
import classes from './AddGuestForm.module.scss';

interface GuestValues {
    name: string;
    parentName: string;
    email: string;
    phoneCountry?: number;
    phoneNumber: string;
}

interface AddGuestFormProps {
    booking: BookingService;
    guest: Guest;
    onBackClick(): void;
}

/**
 * Form to add/edit guests.
 */
export const AddGuestForm: FunctionComponent<AddGuestFormProps> = ({ booking, guest, onBackClick }) => {
    const { client } = useApi();
    const { formatMessage, locale } = useIntl();
    const { currentCountry } = useCountries();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { toastApiError } = useApiError();
    const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
    const [countryCodes, setCountryCodes] = useState<number[] | undefined>();

    // Setup default values
    const defaultValues: GuestValues = useMemo(() => {
        const isEmpty = isEmptyGuest(guest);

        return {
            name: !isEmpty ? guest.guestname : '',
            parentName: !isEmpty ? guest.parentname : '',
            email: !isEmpty ? guest.email : '',
            phoneCountry: guest.phonecountrycode || currentCountry?.phoneCode || undefined,
            phoneNumber: !isEmpty ? guest.phone || '' : '',
        };
    }, [currentCountry, guest]);

    const { register, handleSubmit, reset, formState, getValues, watch } = useForm<GuestValues>({ defaultValues });

    // Reset form if we change guest
    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const fetchData = () => {
        const { token, cancel } = ApiClient.createCancelToken();
        incrementLoader();

        const guestCountryCode = isEmptyGuest(guest) ? currentCountry.phoneCode : guest.phonecountrycode;
        client
            .getPhoneCountryCodes(guestCountryCode, token)
            .then((codes) => setCountryCodes(codes))
            .catch((error) => {
                const apiError = ApiError.fromError(error);
                if (apiError !== ApiError.Cancellation) {
                    toastApiError(error);
                }
            })
            .finally(decrementLoader);

        return cancel;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchData, []);

    // Configure form errors and fields
    const errorMessages = useMemo(() => {
        return {
            required: formatMessage({ id: 'errors.field-required', defaultMessage: 'Fältet är obligatoriskt' }),
            phone: formatMessage({ id: 'errors.field-phone-invalid', defaultMessage: 'Telefonnummret är inte giltigt' }),
            email: formatMessage({ id: 'errors.field-email-invalid', defaultMessage: 'E-postadressen är inte giltig' }),
            emailOrPhone: formatMessage({
                id: 'errors.field-email-or-phone-required',
                defaultMessage: 'E-post eller telefonnummer är obligatoriskt',
            }),
        };
    }, [formatMessage]);

    // Email is required when phone number is empty and phone number is required if email is empty.
    // We need to watch the values and set the required attribute depending on them.
    const emailValue = watch('email');
    const phoneNumberValue = watch('phoneNumber');
    const phoneValidator = PhoneNumberUtil.getInstance();

    const fields = {
        name: register('name', {
            disabled: !booking.details?.canChangeGuests,
            required: errorMessages.required,
        }),
        parentName: register('parentName', {
            disabled: !booking.details?.canChangeGuests,
            required: errorMessages.required,
        }),
        email: register('email', {
            disabled: !booking.details?.canChangeGuests || !!guest.email,
            required: !phoneNumberValue && !guest.phone,
            deps: ['phoneNumber'],
            pattern: {
                value: /^\S+@\S+$/i,
                message: errorMessages.email,
            },
        }),
        phoneCountry: register('phoneCountry', {
            disabled: !booking.details?.canChangeGuests || !!guest.phone,
            required: true,
            deps: ['phoneNumber'],
        }),
        phoneNumber: register('phoneNumber', {
            disabled: !booking.details?.canChangeGuests || !!guest.phone,
            required: !emailValue && !guest.email ? errorMessages.emailOrPhone : false,
            deps: ['email'],
            validate: (value) => {
                if (value) {
                    const phoneCountry = getValues('phoneCountry');
                    try {
                        const phone = phoneValidator.parse(`+${phoneCountry}${value}`);
                        if (value && !phoneValidator.isValidNumber(phone)) {
                            return errorMessages.phone;
                        }
                    } catch {
                        return errorMessages.phone;
                    }
                }
                return undefined;
            },
        }),
    };

    const onSubmit: SubmitHandler<GuestValues> = async (data) => {
        if (!booking.details) {
            return;
        }

        incrementLoader();

        let updatedGuest: Guest | undefined;
        try {
            let phoneCountryCode = 0;
            let phoneNumber = '';

            if (data.phoneNumber && data.phoneCountry) {
                phoneCountryCode = data.phoneCountry;
                phoneNumber = data.phoneNumber;
                const parsedPhone = phoneValidator.parse(`+${data.phoneCountry}${data.phoneNumber}`);
                if (phoneValidator.isValidNumber(parsedPhone)) {
                    phoneCountryCode = parsedPhone.getCountryCodeOrDefault();
                    phoneNumber = parsedPhone.getNationalNumberOrDefault().toString();
                }
            }

            updatedGuest = await client.saveGuest({
                disablesendinvite: false,
                partybookingid: booking.details.guid,
                inviteid: guest.inviteid,
                guestid: guest.guestid,
                ismainguest: false,
                birthday: false,
                guestname: data.name,
                parentname: data.parentName,
                email: data.email,
                phone: phoneNumber,
                phonecountrycode: phoneCountryCode,
            });
        } catch (error) {
            toastApiError(error);
        }

        if (updatedGuest) {
            booking.setGuest(updatedGuest, locale);
            onBackClick();
        }

        decrementLoader();
    };

    async function onRemindGuestClick() {
        if (!guest || !booking.details) {
            return;
        }

        incrementLoader();

        let success = true;
        try {
            await client.remindGuests(booking.details.guid, [guest.inviteid]);
        } catch (error) {
            success = false;
            toastApiError(error);
        }

        decrementLoader();

        if (success) {
            onBackClick();
        }
    }

    async function setAttendance(isAttending: boolean) {
        if (!guest || !booking.details) {
            return;
        }

        incrementLoader();

        let success = true;
        try {
            await client.confirmGuestAttendance(booking.details.guid, guest.inviteid, isAttending);
        } catch (error) {
            success = false;
            toastApiError(error);
        }

        decrementLoader();

        if (success) {
            guest.status = isAttending ? 'Accepted' : 'Cancelled';
            booking.setGuest(guest, locale);
            onBackClick();
        }
    }

    const onRemoveConfirmClose = async (confirmed: boolean) => {
        setRemoveConfirmOpen(false);

        if (!confirmed) {
            return;
        }

        incrementLoader();

        let details: BookingDetails | undefined;
        try {
            details = await client.removeInvite(guest.inviteid);
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();

        if (details) {
            booking.set(details);
            onBackClick();
        }
    };

    return (
        <>
            <AlertDialog
                type="warning"
                open={removeConfirmOpen}
                className={classes.removeGuestDialog}
                text={formatMessage({
                    id: 'edit-guests.add-form-remove-dialog-title',
                    defaultMessage: 'Vill du verkligen ta bort gästen?',
                })}
            >
                <Button color="raceCarRed" onClick={() => onRemoveConfirmClose(true)}>
                    <FormattedMessage id="general.remove" defaultMessage="Ta bort" />
                </Button>

                <Button color="grandparentGrey900" onClick={() => onRemoveConfirmClose(false)}>
                    <FormattedMessage id="general.cancel" defaultMessage="Avbryt" />
                </Button>
            </AlertDialog>

            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={formState.isSubmitting}>
                    <Typography variant="title5" className={clsx(classes.label, { [classes.labelDisabled]: formState.isSubmitting })}>
                        <FormattedMessage id="edit-guests.add-form-title" defaultMessage="Bjud in via e-post eller sms" />
                    </Typography>

                    <TextField
                        {...fields.name}
                        label={formatMessage({ id: 'edit-guests.add-form-field-name-label', defaultMessage: 'Namn kompis*' })}
                        error={formState.errors.name?.message}
                        className={classes.field}
                    />

                    <TextField
                        {...fields.parentName}
                        label={formatMessage({ id: 'edit-guests.add-form-field-parent-name-label', defaultMessage: 'Namn förälder*' })}
                        error={formState.errors.parentName?.message}
                        className={classes.field}
                    />

                    <TextField
                        {...fields.email}
                        inputMode="email"
                        autoCapitalize="none"
                        label={formatMessage({ id: 'edit-guests.add-form-field-email-label', defaultMessage: 'E-post' })}
                        error={formState.errors.email?.message}
                        className={classes.field}
                    />

                    <div className={classes.phoneNumber}>
                        <SelectField
                            {...fields.phoneCountry}
                            label={formatMessage({
                                id: 'edit-guests.add-form-field-phone-country-label',
                                defaultMessage: 'Landskod',
                            })}
                        >
                            {countryCodes?.map((phoneCode) => (
                                <option key={phoneCode} value={phoneCode}>
                                    +{phoneCode}
                                </option>
                            ))}
                        </SelectField>

                        <TextField
                            {...fields.phoneNumber}
                            inputMode="tel"
                            error={formState.errors.phoneNumber ? '' : null}
                            label={formatMessage({
                                id: 'edit-guests.add-form-field-phone-number-label',
                                defaultMessage: 'Telefonnummer',
                            })}
                        />

                        {formState.errors.phoneNumber && (
                            <FieldError className={classes.phoneNumberError}>{formState.errors.phoneNumber.message}</FieldError>
                        )}
                    </div>

                    {!isEmptyGuest(guest) && (
                        <div className={classes.actions}>
                            <Typography
                                variant="title5"
                                className={clsx(classes.label, { [classes.labelDisabled]: formState.isSubmitting })}
                            >
                                <FormattedMessage id="edit-guests.add-form-actions-label" defaultMessage="Åtgärder" />
                            </Typography>

                            <Alert>
                                <AlertText>
                                    <FormattedMessage
                                        id="edit-guests.add-form-actions-text"
                                        defaultMessage="Här kan du skicka påminnelser till- eller svara åt gästen om den väljer att svara i en annan kanal."
                                    />
                                </AlertText>
                            </Alert>

                            <div className={classes.actionButtons}>
                                <Button
                                    autoWidth
                                    color="grandparentGrey900"
                                    disabled={guest.status === 'Accepted'}
                                    onClick={() => setAttendance(true)}
                                >
                                    <FormattedMessage id="edit-guests.add-form-action-accept" defaultMessage="Svara ja" />
                                </Button>

                                <Button
                                    autoWidth
                                    color="grandparentGrey900"
                                    disabled={guest.status === 'Cancelled'}
                                    onClick={() => setAttendance(false)}
                                >
                                    <FormattedMessage id="edit-guests.add-form-action-decline" defaultMessage="Svara nej" />
                                </Button>

                                {guest.status === 'Invited' && (
                                    <Button color="grandparentGrey900" className={classes.remindButton} onClick={onRemindGuestClick}>
                                        <FormattedMessage id="edit-guests.add-form-action-remind" defaultMessage="Skicka påminnelse" />
                                    </Button>
                                )}
                                {booking.details?.canChangeGuests && (
                                    <Button color="raceCarRed" className={classes.removeButton} onClick={() => setRemoveConfirmOpen(true)}>
                                        <FontAwesomeIcon icon={faTrash} width={21} className={classes.removeButtonIcon} />
                                        <FormattedMessage id="edit-guests.add-form-action-remove" defaultMessage="Ta bort gäst" />
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </fieldset>

                <PageButtons alignBottom>
                    {booking.details?.canChangeGuests ? (
                        <>
                            <Button type="submit" color="minionYellow" disabled={formState.isSubmitting}>
                                <FormattedMessage id="edit-guests.add-form-save" defaultMessage="Skicka inbjudan" />
                            </Button>

                            <Button color="transparent" disabled={formState.isSubmitting} onClick={onBackClick}>
                                <FormattedMessage id="general.cancel" defaultMessage="Avbryt" />
                            </Button>
                        </>
                    ) : (
                        <Button color="minionYellow" disabled={formState.isSubmitting} onClick={onBackClick}>
                            <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                        </Button>
                    )}
                </PageButtons>
            </form>
        </>
    );
};
