import { createContext, FunctionComponent, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useCountries } from '../hooks/useCountries';
import { useAuth } from '../services/auth/AuthProvider';
import ApiClient from '../utils/ApiClient';

interface ApiService {
    client: ApiClient;
}

const ApiContext = createContext<ApiService>({} as ApiService);

export const useApi = () => {
    return useContext(ApiContext);
};

export const ApiProvider: FunctionComponent = ({ children }) => {
    const { currentCountry } = useCountries();
    const { accessToken } = useAuth();
    const [cookies, setCookie] = useCookies(['leo.session_id']);

    // Create or fetch session id
    const sessionId = useMemo(() => {
        let sessionId = cookies['leo.session_id'];

        if (!sessionId) {
            sessionId = Math.random().toString(36).substring(2);
            const expires = new Date();
            expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000); // 2 hours
            setCookie('leo.session_id', sessionId, { path: '/', expires });
        }

        return sessionId;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies]);

    // Create API Client
    const client = useMemo(() => {
        return new ApiClient(currentCountry, { accessToken, sessionId });
    }, [currentCountry, accessToken, sessionId]);

    return <ApiContext.Provider value={{ client }}>{children}</ApiContext.Provider>;
};
