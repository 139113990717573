import clsx from 'clsx';
import { FunctionComponent } from 'react';
import classes from './FieldError.module.scss';

interface FieldErrorProps {
    className?: string;
}

/**
 * Error for the form fields.
 */
export const FieldError: FunctionComponent<FieldErrorProps> = ({ className, children }) => {
    return <p className={clsx(classes.text, className)}>{children}</p>;
};
