/**
 * Available user roles.
 */
export enum Role {
    /** Global administrator for the application. */
    GlobalAdministrator = 'Administrator',

    /** Administrator of one or more locations. */
    LocationAdministrator = 'LocationAdministrator',

    /** Main customer role for regular Leo users. All new members gets added to this role upon signup. */
    Customer = 'Customer',

    /** Developer access to some developer view for cache handling and cleanup functions. */
    Developer = 'Developer',

    /** Access to the real time sales view which contains the sales statistics. */
    RealTimeSale = 'RealTimeSale',

    /** Access to handling cash specific actions such as repayments and the creation of giftcards. */
    CashManager = 'CashManager',
}
