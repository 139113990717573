import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { Config } from '../../../config';
import classes from './EnvironmentBanner.module.scss';

/**
 * Displays the current environment in the top right corner on all environments except production.
 */
export const EnvironmentBanner: FunctionComponent = () => {
    const env = Config.environment.trim().toUpperCase();
    if (env === '' || env === 'PRODUCTION') {
        return null;
    }

    const classNames = clsx(classes.banner, {
        [classes.staging]: env === 'STAGING',
    });

    return <div className={classNames}>{env}</div>;
};
