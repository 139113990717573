import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { assertUnreachable } from './utils';
import { locales } from './utils/locale';

/**
 * Load all required polyfill.
 */
export async function polyfill() {
    // Check if we need to polyfill Intl.Locale
    if (shouldPolyfillLocale()) {
        await import('@formatjs/intl-locale/polyfill');
    }

    // Check if we need to polyfill Intl.getCanonicalLocales
    if (shouldPolyfillGetCanonicalLocales()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }

    for (const locale of locales) {
        // Check if we need to polyfill Intl.ListFormat
        if (shouldPolyfillListFormat()) {
            await import('@formatjs/intl-listformat/polyfill-force');

            switch (locale) {
                case 'sv':
                    await import(`@formatjs/intl-listformat/locale-data/sv`);
                    break;
                case 'fi':
                    await import(`@formatjs/intl-listformat/locale-data/fi`);
                    break;
                case 'da':
                    await import(`@formatjs/intl-listformat/locale-data/da`);
                    break;
                case 'de':
                    await import(`@formatjs/intl-listformat/locale-data/de`);
                    break;
                case 'nb':
                    await import(`@formatjs/intl-listformat/locale-data/nb`);
                    break;
                default:
                    assertUnreachable(locale);
            }
        }

        // Check if we need to polyfill Intl.PluralRules
        if (shouldPolyfillPluralRules(locale)) {
            await import('@formatjs/intl-pluralrules/polyfill-force');

            switch (locale) {
                case 'sv':
                    await import(`@formatjs/intl-pluralrules/locale-data/sv`);
                    break;
                case 'fi':
                    await import(`@formatjs/intl-pluralrules/locale-data/fi`);
                    break;
                case 'da':
                    await import(`@formatjs/intl-pluralrules/locale-data/da`);
                    break;
                case 'de':
                    await import(`@formatjs/intl-pluralrules/locale-data/de`);
                    break;
                case 'nb':
                    await import(`@formatjs/intl-pluralrules/locale-data/nb`);
                    break;
                default:
                    assertUnreachable(locale);
            }
        }
    }
}
