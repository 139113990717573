import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { useCountries } from 'hooks/useCountries';
import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/common/Button/Button';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Typography } from '../../../components/common/Typography/Typography';
import { FadeInImage } from '../../../components/utils/FadeInImage/FadeInImage';
import { InviteInfoRow } from '../InviteInfoRow/InviteInfoRow';
import { InviteMessageInput } from '../InviteMessageInput/InviteMessageInput';
import { InviteTicket } from '../InviteTicket/InviteTicket';
import classes from './InviteCard.module.scss';
import ImageLogoHopLopLeo from './leos-hop-lop-logo.png';
import ImageLogo from './logo.png';

interface InviteCardProps {
    className?: string;
    hostNames: string[];
    guestName: string;
    roomName: string;
    playgroundName: string;
    date: Date;
    startTime: string;
    endTime: string;
    invitationMessage: string;
    guestMessage?: string;
    guestMessageOpen?: boolean;
    guestAllergyMessage?: string;
    guestAllergyMessageOpen?: boolean;
    answer?: boolean;
    preview?: boolean;
    companyName: string;
    streetAdress: string;
    playcentertype: string;
    onMessageChange?(value: string): void;
    onMessageOpenChange?(value: boolean): void;
    onAllergiesChange?(value: string): void;
    onAllergiesOpenChange?(value: boolean): void;
    onBackClick?(): void;
    onAnswerClick?(accepted: boolean): void;
    onChangeAnswerClick?(): void;
}

export const InviteCard: FunctionComponent<InviteCardProps> = ({
    className,
    hostNames,
    guestName,
    roomName,
    playgroundName,
    date,
    startTime,
    endTime,
    invitationMessage,
    guestMessage,
    guestMessageOpen,
    guestAllergyMessage,
    guestAllergyMessageOpen,
    answer,
    preview,
    companyName,
    streetAdress,
    playcentertype,
    onMessageChange,
    onMessageOpenChange,
    onAllergiesChange,
    onAllergiesOpenChange,
    onBackClick,
    onAnswerClick,
    onChangeAnswerClick,
}) => {
    const { formatMessage, formatList, formatDate } = useIntl();
    const { currentCountry } = useCountries();

    const isPlaycenterLeoInFinland = currentCountry.code === 'FI' && playcentertype === 'Leo';
    const locationDefault = isPlaycenterLeoInFinland ? `${companyName}, {room}` : 'Leo´s {playground}, {room}';
    const locationId = isPlaycenterLeoInFinland ? 'invite-card.location-text-leos' : 'invite-card.location-text';

    return (
        <InviteTicket className={className} date={date} time={startTime} withBackground>
            {answer === undefined && (
                <>
                    <Typography variant="title5" component="h1" className={classes.title}>
                        <FormattedMessage
                            id="invite-card.title"
                            defaultMessage="{hostNames} har kalas och {guest} är bjuden!"
                            values={{ hostNames: formatList(hostNames), hosts: hostNames.length, guest: guestName }}
                        />
                    </Typography>

                    {invitationMessage.trim() !== '' && <div className={classes.invitationMessage}>{invitationMessage}</div>}

                    <InviteInfoRow
                        className={classes.infoRow}
                        icon={<FontAwesomeIcon icon={faMapMarkerAlt} height={34} />}
                        title={formatMessage({ id: 'invite-card.location-label', defaultMessage: 'Plats' })}
                        text={formatMessage(
                            { id: locationId, defaultMessage: locationDefault },
                            { room: roomName, playground: playgroundName },
                        )}
                        extraText={streetAdress}
                    />

                    <InviteInfoRow
                        className={classes.infoRowLast}
                        icon={<FontAwesomeIcon icon={faCalendarAlt} width={29} />}
                        title={formatMessage({ id: 'invite-card.date-label', defaultMessage: 'Datum & tid' })}
                        text={formatMessage(
                            { id: 'invite-card.date-text', defaultMessage: '{date}, kl {startTime} - {endTime}' },
                            { date: formatDate(date, { year: 'numeric', month: 'long', day: 'numeric' }), startTime, endTime },
                        )}
                        showArrivalInfo
                    />

                    <InviteMessageInput
                        className={classes.messageInput}
                        title={
                            preview
                                ? formatMessage({
                                      id: 'invite-card.message-label-preview',
                                      defaultMessage: 'Meddelande till födelsedagsbarnet (fylls i av gäst)',
                                  })
                                : formatMessage({ id: 'invite-card.message-label', defaultMessage: 'Meddelande till födelsedagsbarnet' })
                        }
                        value={guestMessage}
                        open={guestMessageOpen}
                        onChange={onMessageChange}
                        onOpenChange={onMessageOpenChange}
                    />

                    <InviteMessageInput
                        title={
                            preview
                                ? formatMessage({
                                      id: 'invite-card.allergies-label-preview',
                                      defaultMessage: 'Allergier (fylls i av gäst)',
                                  })
                                : formatMessage({ id: 'invite-card.allergies-label', defaultMessage: 'Allergier' })
                        }
                        value={guestAllergyMessage}
                        open={guestAllergyMessageOpen}
                        onChange={onAllergiesChange}
                        onOpenChange={onAllergiesOpenChange}
                    />

                    {onBackClick && (
                        <Button color="ballPitBlue" fullwidth className={classes.backButton} onClick={onBackClick}>
                            <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                        </Button>
                    )}

                    {onAnswerClick && (
                        <div className={classes.answerButtons}>
                            <Button color="dinosaurGreen" autoWidth onClick={() => onAnswerClick(true)}>
                                <FormattedMessage id="invite-card.accept" defaultMessage="Tacka ja" />
                            </Button>
                            <Button color="raceCarRed" autoWidth onClick={() => onAnswerClick(false)}>
                                <FormattedMessage id="invite-card.decline" defaultMessage="Tacka nej" />
                            </Button>
                        </div>
                    )}

                    <FadeInImage
                        className={currentCountry.code === 'FI' ? classes.bottomLogoHopLop : classes.bottomLogo}
                        src={currentCountry.code === 'FI' ? ImageLogoHopLopLeo : ImageLogo}
                    />
                </>
            )}

            {answer !== undefined && (
                <div className={classes.answeredContainer}>
                    <Typography variant="title3" component="h1" className={classes.answeredTitle}>
                        {answer && (
                            <FormattedMessage id="invite-card.accepted" defaultMessage="Du har tackat ja till kalaset - varmt välkommen!" />
                        )}
                        {!answer && (
                            <FormattedMessage
                                id="invite-card.declined"
                                defaultMessage="Du har tackat nej till kalaset - varmt välkommen en annan gång!"
                            />
                        )}
                    </Typography>

                    {invitationMessage.trim() !== '' && <div className={classes.invitationMessage}>{invitationMessage}</div>}

                    <InviteInfoRow
                        className={classes.infoRow}
                        icon={<FontAwesomeIcon icon={faMapMarkerAlt} height={34} />}
                        title={formatMessage({ id: 'invite-card.location-label', defaultMessage: 'Plats' })}
                        text={formatMessage(
                            { id: 'invite-card.location-text', defaultMessage: 'Leo´s {playground}, {room}' },
                            { room: roomName, playground: playgroundName },
                        )}
                    />

                    <InviteInfoRow
                        className={classes.infoRowLast}
                        icon={<FontAwesomeIcon icon={faCalendarAlt} width={29} />}
                        title={formatMessage({ id: 'invite-card.date-label', defaultMessage: 'Datum & tid' })}
                        text={formatMessage(
                            { id: 'invite-card.date-text', defaultMessage: '{date}, kl {startTime} - {endTime}' },
                            { date: formatDate(date, { year: 'numeric', month: 'long', day: 'numeric' }), startTime, endTime },
                        )}
                        showArrivalInfo
                    />

                    {answer && (
                        <Button color="transparent" autoWidth autoHeight className={classes.changeAnswer} onClick={onChangeAnswerClick}>
                            <FormattedMessage id="invite-card.change-answer" defaultMessage="Ändra ditt svar »" />
                        </Button>
                    )}
                </div>
            )}
        </InviteTicket>
    );
};
