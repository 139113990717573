import Tippy from '@tippyjs/react';
import { FunctionComponent, ReactElement, ReactNode } from 'react';
import classes from './Tooltip.module.scss';

interface TooltipProps {
    content: ReactNode;
    children: ReactElement;
}

/**
 * Design system tooltip.
 */
export const Tooltip: FunctionComponent<TooltipProps> = ({ content, children }) => {
    if (!content) {
        return children;
    }

    return (
        <Tippy offset={[0, 8]} maxWidth={250} hideOnClick={false} content={content} className={classes.container}>
            {children}
        </Tippy>
    );
};
