declare global {
    interface Window {
        Cookiebot?: {
            /**
             * Shows the cookie consent dialog to the website user to renew or change the user’s consent state.
             */
            renew(): void;
        };
    }
}

/** Flag to indicate if CookieBot has already been injected if called multiple times */
let injected = false;

/**
 * Inject CookieBot script once for a specific locale.
 * Locale cannot be changed later.
 *
 * @param locale The locale to display CookieBot banner and popups in.
 */
export function injectCookieBot(locale: string): void {
    if (injected) {
        return;
    }

    const script = document.createElement('script');
    script.id = 'Cookiebot';
    script.src = 'https://consent.cookiebot.com/uc.js';
    script.dataset.cbid = 'b5116110-2cc6-4d9b-b55e-2842a6313d72';
    script.dataset.culture = locale;
    script.type = 'text/javascript';
    script.async = true;

    document.head.appendChild(script);

    injected = true;
}
