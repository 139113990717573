import { CountrySlug } from './utils/locale';

export const Config = {
    cancelDeadlineInDays: 14,

    environment: process.env.REACT_APP_ENVIRONMENT ?? '',
    version: process.env.REACT_APP_VERSION ?? '',

    baseUrlApp: `${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}`,
    baseUrlApi: process.env.REACT_APP_BASE_URL_API ?? '',
    baseUrlCdn: process.env.REACT_APP_BASE_URL_CDN ?? '',

    authAuthority: {
        sweden: process.env.REACT_APP_AUTH_AUTHORITY_SE,
        norway: process.env.REACT_APP_AUTH_AUTHORITY_NO,
        denmark: process.env.REACT_APP_AUTH_AUTHORITY_DK,
        finland: process.env.REACT_APP_AUTH_AUTHORITY_FI,
        germany: process.env.REACT_APP_AUTH_AUTHORITY_DE,
    } as Record<CountrySlug, string>,
    authClientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
};
