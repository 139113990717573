import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { useApiClient } from 'hooks/useApiClient';
import { FunctionComponent, Key, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DimensionOptions, PartyBookingApiClient, PartyLocationConfigurationDto } from '../../../api/api.generated';
import { Button } from '../../../components/common/Button/Button';
import { Card, CardText, CardTitle } from '../../../components/common/Card/Card';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { Typography } from '../../../components/common/Typography/Typography';
import { useApi } from '../../../contexts/ApiProvider';
import { useBooking } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import { EndTimePicker } from '../../../features/edit-invitation/EndTimePicker/EndTimePicker';
import { ImagePicker, ImagePickerImage } from '../../../features/edit-invitation/ImagePicker/ImagePicker';
import { InviteMessage } from '../../../features/edit-invitation/InviteMessage/InviteMessage';
import { InviteCard } from '../../../features/invite/InviteCard/InviteCard';
import { InviteCardDialog } from '../../../features/invite/InviteCardDialog/InviteCardDialog';
import useApiError from '../../../hooks/useApiError';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { BookingDetails } from '../../../model/BookingDetails';
import { Stage } from '../../../model/stage';
import { imageUrl } from '../../../utils';
import { Error } from '../../Error';
import classes from './EditInvitation.module.scss';

/**
 * Page where the user can edit the guest invitation.
 */
export const EditInvitation: FunctionComponent = () => {
    const booking = useBooking();
    const { formatMessage } = useIntl();
    const { client } = useApi();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { openStage } = useBookingParams();

    const partyApiClient = useApiClient(PartyBookingApiClient);

    const { apiError, setApiError, clearApiError, toastApiError } = useApiError();
    const [showPreview, setShowPreview] = useState(false);
    const [selectedImage, setSelectedImage] = useState<Key | null>(booking.details?.invitation.background?.guid ?? null);
    const [inviteMessageOpen, setInviteMessageOpen] = useState<boolean>(!!booking.details?.invitation.message);
    const [inviteMessage, setInviteMessage] = useState<string>(booking.details?.invitation.message ?? '');
    const [endTime, setEndTime] = useState<string | null>(booking.details?.invitation.endTime ?? null);
    const [locationConfiguration, setLocationConfiguration] = useState<PartyLocationConfigurationDto | null>(null);

    // Refresh page if the current booking is changed
    const loadConfiguration = () => {
        clearApiError();
        setLocationConfiguration(null);
        setSelectedImage(booking.details?.invitation.background?.guid ?? null);
        setInviteMessageOpen(!!booking.details?.invitation.message);
        setInviteMessage(booking.details?.invitation.message ?? '');
        setEndTime(booking.details?.invitation.endTime ?? null);

        const controller = new AbortController();

        const playground = booking.details?.playground;
        if (playground) {
            incrementLoader();

            partyApiClient
                .apiPartyBookingPartyLocationConfigurationGet(playground.guid, controller.signal)
                .then((locationConfiguration) => {
                    setLocationConfiguration(locationConfiguration);
                })
                .catch((error) => {
                    if (!controller.signal.aborted) {
                        setApiError(error);
                    }
                })
                .finally(decrementLoader);
        }

        return () => controller.abort();
    };

    // Load required configuration for the page
    useEffect(loadConfiguration, [booking.details, clearApiError, decrementLoader, incrementLoader, partyApiClient, setApiError]);

    // Build up array of available images to pick from
    const images: ImagePickerImage[] = useMemo(() => {
        if (!booking.details) {
            return [];
        }

        return booking.details.invitationBackgrounds.map((bg) => ({
            key: bg.guid,
            src: imageUrl(bg.image, 340, 340, DimensionOptions.Fill),
        }));
    }, [booking.details]);

    async function createInvitation() {
        if (booking.details && activeBackground) {
            incrementLoader();

            let details: BookingDetails | undefined;
            try {
                details = await client.createInviteTemplate(
                    booking.details.guid,
                    activeBackground.guid,
                    endTime ?? booking.details.invitation.endTime,
                    inviteMessageOpen ? inviteMessage : '',
                );
            } catch (error) {
                toastApiError(error);
            }

            decrementLoader();

            if (details) {
                booking.set(details);
                goBack(details);
            }
        }
    }

    const goBack = (details?: BookingDetails) => {
        if (!details?.hasSelectedInvitationBackground) {
            openStage(Stage.BookingOverview, details);
        } else {
            openStage(Stage.EditGuests, details);
        }
    };

    if (!booking.details) {
        return null;
    }

    if (apiError) {
        return <Error error={apiError} onRetryClick={loadConfiguration} />;
    }

    // Grab currently active background image
    const activeBackground = booking.details.invitationBackgrounds.find((bg) => bg.guid === selectedImage);

    return (
        <>
            <InviteCardDialog backgroundImage={activeBackground?.image} open={showPreview}>
                <InviteCard
                    hostNames={booking.details.birthdayKids.map((g) => g.guestname)}
                    guestName="[———]"
                    roomName={booking.details.room.name}
                    playgroundName={booking.details.playground.name}
                    date={booking.details.slot.date}
                    startTime={booking.details.startTime || booking.details.slot.displayStartTime}
                    endTime={endTime ?? booking.details.invitation.endTime}
                    invitationMessage={inviteMessage}
                    onBackClick={() => setShowPreview(false)}
                    companyName={booking.details.country.companyname}
                    streetAdress={booking.details.playground.streetadress}
                    playcentertype={booking.details.playground.playcentertype}
                    preview
                />
            </InviteCardDialog>

            <PageContainer
                title={formatMessage({ id: 'edit-invitation.title', defaultMessage: 'Inbjudningskort' })}
                onBackClick={booking.details.hasSelectedInvitationBackground ? () => goBack(booking.details) : undefined}
            >
                <Card dark className={classes.sectionMargin}>
                    <CardTitle>
                        <FormattedMessage id="edit-invitation.card-title" defaultMessage="Skapa ett inbjudningskort" />
                    </CardTitle>

                    <CardText>
                        <FormattedMessage
                            id="edit-invitation.card-text"
                            defaultMessage="Vårt bokningssystem har skapat en inbjudan åt dig med all information. Du kan ändra sluttid för kalaset. Det finns även möjlighet att komplettera med ett kort meddelande till de inbjudna."
                        />
                    </CardText>
                </Card>

                <Typography variant="title5" component="h2" className={classes.subTitle}>
                    <FormattedMessage id="edit-invitation.step-1-select-image" defaultMessage="1. Välj omslagsbild" />
                </Typography>

                <ImagePicker images={images} value={selectedImage} onChange={setSelectedImage} className={classes.sectionMargin} />

                <Typography variant="title5" component="h2" className={classes.subTitle}>
                    <FormattedMessage id="edit-invitation.step-2-guest-message" defaultMessage="2. Meddelande till gästerna (frivilligt)" />
                </Typography>

                <InviteMessage
                    open={inviteMessageOpen}
                    value={inviteMessage}
                    onOpenChange={setInviteMessageOpen}
                    onValueChange={setInviteMessage}
                    className={classes.sectionMargin}
                />

                {!locationConfiguration?.partyEnableChoosePlayTime && (
                    <>
                        <Typography variant="title5" component="h2" className={classes.subTitle}>
                            <FormattedMessage id="edit-invitation.step-3-end-time" defaultMessage="3. Kalasets sluttid" />
                        </Typography>

                        <EndTimePicker
                            startTime={booking.details.slot.displayStartTime}
                            maxEndTime={booking.details.invitation.maxEndTime}
                            value={endTime}
                            onChange={setEndTime}
                            className={classes.sectionMargin}
                        />
                    </>
                )}

                <PageButtons>
                    <Button color="grandparentGrey900" className={classes.previewButton} onClick={() => setShowPreview(true)}>
                        <FontAwesomeIcon icon={faEye} width={34} className={classes.previewButtonIcon} />
                        <FormattedMessage id="edit-invitation.preview-button" defaultMessage="Granska inbjudan" />
                    </Button>
                </PageButtons>

                <PageButtons alignBottom>
                    <Button color="minionYellow" onClick={() => createInvitation()}>
                        <FormattedMessage id="edit-invitation.save-button" defaultMessage="Spara inbjudan" />
                    </Button>

                    <Button color="transparent" onClick={() => goBack(booking.details)}>
                        {!booking.details.hasSelectedInvitationBackground ? (
                            <FormattedMessage id="general.to-overview" defaultMessage="Till översikt" />
                        ) : (
                            <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                        )}
                    </Button>
                </PageButtons>
            </PageContainer>
        </>
    );
};
