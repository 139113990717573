import { create, CreateTypes, Options } from 'canvas-confetti';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import classes from './Confetti.module.scss';

/**
 * Handle that the parent will receive for it's forwarded ref.
 */
export interface ConfettiHandle {
    /** Launches the confetti with the specified options */
    launch: (options: Options) => void;

    /** Resets the canvas */
    reset: () => void;
}

/**
 * Shoots out confetti when triggered.
 */
export const Confetti = forwardRef<ConfettiHandle>((_, ref) => {
    const confettiInstance = useRef<CreateTypes | null>(null);

    const canvasEl = (element: HTMLCanvasElement | null) => {
        if (element !== null) {
            confettiInstance.current = create(element, { resize: true });
        } else {
            confettiInstance.current = null;
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            launch: (options: Options) => confettiInstance.current?.(options),
            reset: () => confettiInstance.current?.reset(),
        }),
        [],
    );

    return <canvas ref={canvasEl} className={classes.canvas} />;
});
