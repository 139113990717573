import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonContent,
    ComboButtonTitle,
    ExpandedComboButton,
} from '../../../components/common/ComboButton/ComboButton';
import { Switch } from '../../../components/common/Switch/Switch';
import { TextAreaField } from '../../../components/form/TextAreaField/TextAreaField';
import { Collapse } from '../../../components/utils/Collapse/Collapse';
import classes from './InviteMessage.module.scss';

interface InviteMessageProps {
    open: boolean;
    value: string;
    className?: string;
    onOpenChange(open: boolean): void;
    onValueChange(value: string): void;
}

/**
 * Expandable invite message field.
 */
export const InviteMessage: FunctionComponent<InviteMessageProps> = ({ value, open, className, onOpenChange, onValueChange }) => {
    const { formatMessage } = useIntl();

    return (
        <ExpandedComboButton className={className}>
            <ComboButton component="div">
                <ComboButtonContent>
                    <ComboButtonTitle>
                        <FormattedMessage id="edit-invitation.invite-message-title" defaultMessage="Meddelande" />
                    </ComboButtonTitle>
                </ComboButtonContent>

                <ComboButtonAction>
                    <Switch on={open} onChange={onOpenChange} />
                </ComboButtonAction>
            </ComboButton>

            <Collapse open={open}>
                <div className={classes.content}>
                    <TextAreaField
                        label={formatMessage({
                            id: 'edit-invitation.invite-message-placeholder',
                            defaultMessage: 'Meddelande till gästerna',
                        })}
                        value={value}
                        placeholder={formatMessage({
                            id: 'edit-invitation.invite-message-placeholder',
                            defaultMessage: 'Meddelande till gästerna',
                        })}
                        rows={4}
                        onChange={(event) => onValueChange(event.target.value)}
                        className={classes.textArea}
                    />
                </div>
            </Collapse>
        </ExpandedComboButton>
    );
};
