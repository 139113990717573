import { useCountries } from 'hooks/useCountries';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/common/Button/Button';
import { Typography } from '../../../components/common/Typography/Typography';
import { InviteTicket } from '../InviteTicket/InviteTicket';
import classes from './InviteMemberCard.module.scss';

interface InviteMemberCardProps {
    className?: string;
}

export const InviteMemberCard: FunctionComponent<InviteMemberCardProps> = ({ className }) => {
    const { currentCountry } = useCountries();
    const onButtonClick = () => {
        window.open(currentCountry.urlProfile, '_blank');
    };

    return (
        <InviteTicket className={className}>
            <Typography variant="display6" className={classes.title}>
                <FormattedMessage id="general.leos-family" defaultMessage="Leo´s family" />
            </Typography>

            <Typography className={classes.text}>
                <FormattedMessage
                    id="invite-card.member-text"
                    defaultMessage="Passa på att bli medlem i Leo’s Family för att ta del av fina erbjudanden och rabatter."
                />
            </Typography>

            <Button color="ballPitBlue" fullwidth onClick={onButtonClick}>
                <FormattedMessage id="invite-card.member-register" defaultMessage="Bli medlem idag" />
            </Button>
        </InviteTicket>
    );
};
