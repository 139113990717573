import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { eachYearOfInterval, formatISO, getDate, getDaysInMonth, getMonth, getYear, parseISO, subYears } from 'date-fns';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlertDialog } from '../../components/common/AlertDialog/AlertDialog';
import { Button } from '../../components/common/Button/Button';
import { FontAwesomeIcon } from '../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { PageButtons } from '../../components/common/PageButtons/PageButtons';
import { Typography } from '../../components/common/Typography/Typography';
import { FieldError } from '../../components/form/FieldError/FieldError';
import { SelectField } from '../../components/form/SelectField/SelectField';
import { TextField } from '../../components/form/TextField/TextField';
import { useApi } from '../../contexts/ApiProvider';
import { BookingService } from '../../contexts/BookingProvider';
import { useGlobalLoading } from '../../contexts/GlobalLoadingProvider';
import useApiError from '../../hooks/useApiError';
import { Guest } from '../../model';
import { BookingDetails } from '../../model/BookingDetails';
import { isEmptyGuest } from '../../utils';
import classes from './AddBirthdayKidForm.module.scss';

interface GuestValues {
    name: string;
    year: number;
    month: number;
    date: number;
}

interface AddBirthdayKidFormProps {
    booking: BookingService;
    guest: Guest;
    onBackClick(): void;
}

/**
 * Form to add/edit birthday kids.
 */
export const AddBirthdayKidForm: FunctionComponent<AddBirthdayKidFormProps> = ({ booking, guest, onBackClick }) => {
    const { formatMessage, locale } = useIntl();
    const { client } = useApi();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { toastApiError } = useApiError();
    const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);

    // Setup default values
    const defaultValues = useMemo(() => {
        const isEmpty = isEmptyGuest(guest);
        const birthDate = !isEmpty && guest.birthdaydate ? parseISO(guest.birthdaydate) : null;

        return {
            name: !isEmpty ? guest.guestname : '',
            year: birthDate ? getYear(birthDate) : -1,
            month: birthDate ? getMonth(birthDate) : -1,
            date: birthDate ? getDate(birthDate) : -1,
        };
    }, [guest]);

    const { register, handleSubmit, reset, formState, setValue, watch } = useForm<GuestValues>({ defaultValues });

    // Reset form if we change guest
    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    // Configure form errors and fields
    const errorMessages = useMemo(() => {
        return {
            required: formatMessage({ id: 'errors.field-required', defaultMessage: 'Fältet är obligatoriskt' }),
        };
    }, [formatMessage]);

    const fields = {
        name: register('name', {
            required: errorMessages.required,
        }),
        year: register('year', {
            required: true,
            valueAsNumber: true,
            validate: (value) => value !== -1,
        }),
        month: register('month', {
            required: true,
            valueAsNumber: true,
            validate: (value) => value !== -1,
        }),
        date: register('date', {
            required: true,
            valueAsNumber: true,
            validate: (value) => value !== -1,
        }),
    };

    // Setup options for the birthdate selects
    const year = watch('year');
    const month = watch('month');
    const date = watch('date');

    const years = useMemo(() => {
        const now = new Date();

        return eachYearOfInterval({
            start: subYears(now, 20),
            end: now,
        })
            .map((date) => getYear(date))
            .reverse();
    }, []);

    const months = useMemo(() => {
        const months: number[] = [];
        for (let i = 0; i < 12; i += 1) {
            months.push(i);
        }
        return months;
    }, []);

    const days = useMemo(() => {
        const days: number[] = [];

        if (year !== -1 && month !== -1) {
            const daysInMonth = getDaysInMonth(new Date(year, month));
            for (let i = 1; i <= daysInMonth; i += 1) {
                days.push(i);
            }
        }

        return days;
    }, [year, month]);

    // Clear the date field if we change to a month with fewer days than what we currently have selected
    useEffect(() => {
        const daysInMonth = getDaysInMonth(new Date(year, month));
        if (date > daysInMonth) {
            setValue('date', -1);
        }
    }, [year, month, date, setValue]);

    // We need to know how many birthday kids are currently added so we can determine if the user is allowed to remove
    const birthdayKidCount = booking.details?.birthdayKids.length ?? 0;

    const onSubmit: SubmitHandler<GuestValues> = async (data) => {
        if (!booking.details) {
            return;
        }

        incrementLoader();

        let updatedGuest: Guest | undefined;
        try {
            updatedGuest = await client.saveGuest({
                partybookingid: booking.details.guid,
                inviteid: guest.inviteid,
                guestid: guest.guestid,
                disablesendinvite: true,
                guestname: data.name,
                ismainguest: true,
                birthday: true,
                birthdaydate: formatISO(new Date(data.year, data.month, data.date), {
                    representation: 'date',
                }),
            });
        } catch (error) {
            toastApiError(error);
        }

        if (updatedGuest) {
            booking.setGuest(updatedGuest, locale);
        }

        let details: BookingDetails | undefined;
        try {
            details = await client.getBooking(booking.details.guid);
        } catch (error) {
            // We'll ignore this error as the guest was actually saved. This request is only used to get the updated
            // price when extra birthday kids is added.
        }

        if (details) {
            booking.set(details);
        }

        decrementLoader();
    };

    const onRemoveConfirmClose = async (confirmed: boolean) => {
        setRemoveConfirmOpen(false);

        if (!confirmed) {
            return;
        }

        incrementLoader();

        let details: BookingDetails | undefined;
        try {
            details = await client.removeInvite(guest.inviteid);
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();

        if (details) {
            booking.set(details);
        }
    };

    return (
        <>
            <AlertDialog
                type="warning"
                open={removeConfirmOpen}
                className={classes.removeDialog}
                text={formatMessage({
                    id: 'edit-birthday-kids.delete-confirm-alert',
                    defaultMessage: 'Vill du verkligen ta bort födelsedagsbarnet?',
                })}
            >
                <Button color="raceCarRed" onClick={() => onRemoveConfirmClose(true)}>
                    <FormattedMessage id="edit-birthday-kids.delete-confirm-alert-confirm" defaultMessage="Ta bort" />
                </Button>

                <Button color="grandparentGrey900" onClick={() => onRemoveConfirmClose(false)}>
                    <FormattedMessage id="edit-birthday-kids.delete-confirm-alert-cancel" defaultMessage="Avbryt" />
                </Button>
            </AlertDialog>

            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={formState.isSubmitting}>
                    <Typography variant="title5" className={clsx(classes.label, { [classes.labelDisabled]: formState.isSubmitting })}>
                        <FormattedMessage id="edit-birthday-kids.add-form-title" defaultMessage="Lägg till födelsedagsbarn" />
                    </Typography>

                    <TextField
                        {...fields.name}
                        label={formatMessage({ id: 'edit-birthday-kids.add-form-field-name-label', defaultMessage: 'För- & efternamn' })}
                        error={formState.errors.name?.message}
                        className={classes.field}
                    />

                    <Typography variant="title5" className={clsx(classes.label, { [classes.labelDisabled]: formState.isSubmitting })}>
                        <FormattedMessage id="edit-birthday-kids.add-form-date-label" defaultMessage="Barnets födelsedatum" />
                    </Typography>

                    <div className={classes.date}>
                        <SelectField
                            {...fields.year}
                            label={formatMessage({ id: 'edit-birthday-kids.add-form-field-year-label', defaultMessage: 'År' })}
                            placeholder={formatMessage({
                                id: 'edit-birthday-kids.add-form-field-year-placeholder',
                                defaultMessage: 'ÅÅÅÅ',
                            })}
                            placeholderValue={-1}
                            error={formState.errors.year?.message}
                            textSize="large"
                        >
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </SelectField>

                        <SelectField
                            {...fields.month}
                            label={formatMessage({ id: 'edit-birthday-kids.add-form-field-month-label', defaultMessage: 'Månad' })}
                            placeholder={formatMessage({ id: 'edit-birthday-kids.add-form-field-month-placeholder', defaultMessage: 'MM' })}
                            placeholderValue={-1}
                            error={formState.errors.month?.message}
                            disabled={year === -1}
                            textSize="large"
                        >
                            {months.map((month) => (
                                <option key={month} value={month}>
                                    {month + 1}
                                </option>
                            ))}
                        </SelectField>

                        <SelectField
                            {...fields.date}
                            label={formatMessage({ id: 'edit-birthday-kids.add-form-field-date-label', defaultMessage: 'Dag' })}
                            placeholder={formatMessage({ id: 'edit-birthday-kids.add-form-field-date-placeholder', defaultMessage: 'DD' })}
                            placeholderValue={-1}
                            error={formState.errors.date?.message}
                            disabled={month === -1}
                            textSize="large"
                        >
                            {days.map((day) => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </SelectField>

                        {(formState.errors.year || formState.errors.month || formState.errors.date) && (
                            <FieldError className={classes.dateError}>{errorMessages.required}</FieldError>
                        )}
                    </div>

                    {!isEmptyGuest(guest) && birthdayKidCount > 1 && (
                        <Button color="raceCarRed" onClick={() => setRemoveConfirmOpen(true)} fullwidth className={classes.removeButton}>
                            <FontAwesomeIcon icon={faTrash} width={21} className={classes.removeButtonIcon} />
                            <FormattedMessage id="edit-birthday-kids.add-form-remove" defaultMessage="Ta bort födelsedagsbarn" />
                        </Button>
                    )}
                </fieldset>

                <PageButtons alignBottom>
                    <Button type="submit" color="minionYellow" disabled={formState.isSubmitting}>
                        <FormattedMessage id="edit-birthday-kids.add-form-save" defaultMessage="Spara födelsedagsbarn" />
                    </Button>

                    <Button color="transparent" disabled={formState.isSubmitting} onClick={onBackClick}>
                        <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                    </Button>
                </PageButtons>
            </form>
        </>
    );
};
