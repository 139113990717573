import { FunctionComponent, useRef } from 'react';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { Dialog } from '../../utils/Dialog/Dialog';
import { Alert, AlertContent, AlertText } from '../Alert/Alert';
import classes from './AlertDialog.module.scss';

interface AlertDialogProps {
    open: boolean;
    text: string;
    type?: 'info' | 'warning' | 'error';
    className?: string;
    onClose?(): void;
}

/**
 * Design system Alert Dialog component.
 */
export const AlertDialog: FunctionComponent<AlertDialogProps> = ({ open, type, text, className, children, onClose }) => {
    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside(ref, () => {
        onClose?.();
    });

    return (
        <Dialog open={open}>
            <div className={classes.content}>
                <Alert ref={ref} className={classes.alert}>
                    <AlertText type={type}>{text}</AlertText>
                    <AlertContent className={className}>{children}</AlertContent>
                </Alert>
            </div>
        </Dialog>
    );
};
