import { faBirthdayCake, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonBottomText,
    ComboButtonContent,
    ComboButtonTitle,
} from '../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Typography } from '../../components/common/Typography/Typography';
import { BookingService } from '../../contexts/BookingProvider';
import { Guest } from '../../model';
import { celebrationAge } from '../../utils';
import { AddGuestButton } from '../edit-guests/AddGuestButton/AddGuestButton';
import classes from './BirthdayKidsList.module.scss';

interface BirthdayKidsListProps {
    booking: BookingService;
    birthdayKids: Guest[];
    className?: string;
    onAddClick(): void;
    onEditClick(guest: Guest): void;
}

export const BirthdayKidsList: FunctionComponent<BirthdayKidsListProps> = ({
    booking,
    birthdayKids,
    className,
    onAddClick,
    onEditClick,
}) => {
    return (
        <div className={clsx(classes.list, className)}>
            <Typography variant="title5" className={classes.title}>
                <FormattedMessage id="edit-birthday-kids.list-title" defaultMessage="Födelsedagsbarn" />
            </Typography>

            {birthdayKids.map((guest) => {
                let age: number | null = null;
                if (booking.details && guest.birthdaydate) {
                    const birthdate = parseISO(guest.birthdaydate);
                    age = celebrationAge(birthdate, booking.details.slot.date);
                }

                return (
                    <ComboButton key={guest.guestid} onClick={() => onEditClick(guest)}>
                        <ComboButtonAction>
                            <FontAwesomeIcon icon={faBirthdayCake} width={28} />
                        </ComboButtonAction>

                        <ComboButtonContent>
                            <ComboButtonTitle>{guest.guestname}</ComboButtonTitle>
                            {age !== null && (
                                <ComboButtonBottomText>
                                    <FormattedMessage
                                        id="edit-birthday-kids.list-item-age"
                                        defaultMessage="Fyller {age} år"
                                        values={{ age }}
                                    />
                                </ComboButtonBottomText>
                            )}
                        </ComboButtonContent>

                        <ComboButtonAction>
                            <FontAwesomeIcon icon={faChevronRight} height={23.46} />
                        </ComboButtonAction>
                    </ComboButton>
                );
            })}

            <AddGuestButton onClick={onAddClick}>
                <FormattedMessage id="edit-birthday-kids.list-add" defaultMessage="Lägg till födelsedagsbarn" />
            </AddGuestButton>
        </div>
    );
};
