import clsx from 'clsx';
import { FunctionComponent, ReactNode } from 'react';
import { IMediaItemDto } from '../../../api/api.generated';
import { FadeInImage } from '../../../components/utils/FadeInImage/FadeInImage';
import { imageUrl } from '../../../utils';
import classes from './OpeningInfoCard.module.scss';

interface OpeningInfoCardProps {
    /** The cover image. */
    image: IMediaItemDto;

    /** The content of the opening info card */
    children: ReactNode;

    /** Optional class to append to the container */
    className?: string;
}

/**
 * Card that displays the opening info for a specific playground.
 */
export const OpeningInfoCard: FunctionComponent<OpeningInfoCardProps> = ({ image, children, className }) => {
    const imageSrc = imageUrl(image, 540, 358);

    return (
        <div className={clsx(classes.container, className)}>
            <div className={classes.content}>{children}</div>
            <div className={classes.imageWrapper}>
                <FadeInImage src={imageSrc} className={classes.image} />
            </div>
        </div>
    );
};
