import { MediaType } from '../api/api.generated';

/**
 * Returns the MediaType for the specified file extensions.
 *
 * @param extension The file extension.
 * @param fallback Fallback to use if extension cannot be mapped.
 * @returns The MediaType or fallback if type cannot be mapped.
 */
export function mediaTypeFromExtension(extension?: string | null, fallback = MediaType.Png): MediaType {
    switch (extension) {
        case 'jpeg':
        case 'jpg':
            return MediaType.Jpeg;
        case 'gif':
            return MediaType.Gif;
        case 'png':
            return MediaType.Png;
        case 'bmp':
            return MediaType.Bmp;
        case 'tiff':
        case 'tif':
            return MediaType.Tiff;
        default:
            return fallback;
    }
}
