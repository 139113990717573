import { useCallback, useMemo } from 'react';
import { currentDomainConfig } from '../domain-config';
import { CountrySlug, LocaleCode } from '../utils/locale';

export interface CountryInfo {
    code: string;
    slug: CountrySlug;
    language: LocaleCode;
    phoneCode: number;
    name: string;
    nativeName: string;
    urlParty: string;
    urlHome: string;
    urlMore: string;
    urlPartyFaq: string;
    urlSportKidz: string;
    urlSummerCamp: string;
    urlGroupBooking: string;
    urlOrder: string;
    urlProfile: string;
    urlNext: string;
    urlLeosFamily: string;
    urlFaq: string;
    urlShop: string;
}

/**
 * Hook to return a translated list of all supported countries with code, locale and phone codes.
 */
export function useCountries() {
    const countries: CountryInfo[] = useMemo(() => {
        return [
            {
                code: 'SE',
                language: 'sv',
                slug: 'sweden',
                phoneCode: 46,
                name: 'Sweden',
                nativeName: 'Sverige',
                urlParty: `${process.env.REACT_APP_BASE_URL_PARTY_SE ?? ''}/sv`,
                urlHome: process.env.REACT_APP_URL_HOME_SE ?? '',
                urlMore: `${process.env.REACT_APP_URL_HOME_SE ?? ''}global/`,
                urlPartyFaq: process.env.REACT_APP_URL_PARTY_FAQ_SE ?? '',
                urlSportKidz: process.env.REACT_APP_URL_SPORT_KIDZ_SE ?? '',
                urlSummerCamp: process.env.REACT_APP_URL_SUMMER_CAMP_SE ?? '',
                urlGroupBooking: `${process.env.REACT_APP_BASE_URL_NEXT_SE ?? ''}/grupper-skolor-foretag`,
                urlOrder: `${process.env.REACT_APP_BASE_URL_ORDER_SE ?? ''}/sv`,
                urlProfile: `${process.env.REACT_APP_BASE_URL_PROFILE_SE ?? ''}/sv`,
                urlNext: process.env.REACT_APP_BASE_URL_NEXT_SE ?? '',
                urlLeosFamily: `${process.env.REACT_APP_BASE_URL_NEXT_SE ?? ''}/leos-family`,
                urlFaq: `${process.env.REACT_APP_BASE_URL_NEXT_SE ?? ''}/om-leos-lekland/kundservice/faq`,
                urlShop: `${process.env.REACT_APP_BASE_URL_ORDER_SE ?? ''}/sv/shop`,
            },
            {
                code: 'NO',
                language: 'nb',
                slug: 'norway',
                phoneCode: 47,
                name: 'Norway',
                nativeName: 'Norge',
                urlParty: `${process.env.REACT_APP_BASE_URL_PARTY_NO ?? ''}/nb`,
                urlHome: process.env.REACT_APP_URL_HOME_NO ?? '',
                urlMore: `${process.env.REACT_APP_URL_HOME_NO ?? ''}global/`,
                urlPartyFaq: process.env.REACT_APP_URL_PARTY_FAQ_NO ?? '',
                urlSportKidz: process.env.REACT_APP_URL_SPORT_KIDZ_NO ?? '',
                urlSummerCamp: process.env.REACT_APP_URL_SUMMER_CAMP_NO ?? '',
                urlGroupBooking: `${process.env.REACT_APP_BASE_URL_NEXT_NO ?? ''}/grupper-skoler-foretbedrifter`,
                urlOrder: `${process.env.REACT_APP_BASE_URL_ORDER_NO ?? ''}/nb`,
                urlProfile: `${process.env.REACT_APP_BASE_URL_PROFILE_NO ?? ''}/nb`,
                urlNext: process.env.REACT_APP_BASE_URL_NEXT_NO ?? '',
                urlLeosFamily: `${process.env.REACT_APP_BASE_URL_NEXT_NO ?? ''}/leos-family`,
                urlFaq: `${process.env.REACT_APP_BASE_URL_NEXT_NO ?? ''}/om-leos-lekeland/kundeservice/faq`,
                urlShop: `${process.env.REACT_APP_BASE_URL_ORDER_SE ?? ''}/nb/shop`,
            },
            {
                code: 'DK',
                language: 'da',
                slug: 'denmark',
                phoneCode: 45,
                name: 'Denmark',
                nativeName: 'Danmark',
                urlParty: `${process.env.REACT_APP_BASE_URL_PARTY_DK ?? ''}/da`,
                urlHome: process.env.REACT_APP_URL_HOME_DK ?? '',
                urlMore: `${process.env.REACT_APP_URL_HOME_DK ?? ''}global/`,
                urlPartyFaq: process.env.REACT_APP_URL_PARTY_FAQ_DK ?? '',
                urlSportKidz: process.env.REACT_APP_URL_SPORT_KIDZ_DK ?? '',
                urlSummerCamp: process.env.REACT_APP_URL_SUMMER_CAMP_DK ?? '',
                urlGroupBooking: `${process.env.REACT_APP_BASE_URL_NEXT_DK ?? ''}/grupper-skoler-virksomheder`,
                urlOrder: `${process.env.REACT_APP_BASE_URL_ORDER_DK ?? ''}/da`,
                urlProfile: `${process.env.REACT_APP_BASE_URL_PROFILE_DK ?? ''}/da`,
                urlNext: process.env.REACT_APP_BASE_URL_NEXT_DK ?? '',
                urlLeosFamily: `${process.env.REACT_APP_BASE_URL_NEXT_DK ?? ''}/leos-family`,
                urlFaq: `${process.env.REACT_APP_BASE_URL_NEXT_DK ?? ''}/om-leos-legeland/kundeservice/faq`,
                urlShop: `${process.env.REACT_APP_BASE_URL_ORDER_SE ?? ''}/da/shop`,
            },
            {
                code: 'FI',
                language: 'fi',
                slug: 'finland',
                phoneCode: 358,
                name: 'Finland',
                nativeName: 'Suomi',
                urlParty: `${process.env.REACT_APP_BASE_URL_PARTY_FI ?? ''}/fi`,
                urlHome: process.env.REACT_APP_URL_HOME_FI ?? '',
                urlMore: `${process.env.REACT_APP_URL_HOME_FI ?? ''}global/`,
                urlPartyFaq: process.env.REACT_APP_URL_PARTY_FAQ_FI ?? '',
                urlSportKidz: process.env.REACT_APP_URL_SPORT_KIDZ_FI ?? '',
                urlSummerCamp: process.env.REACT_APP_URL_SUMMER_CAMP_FI ?? '',
                urlGroupBooking: `${process.env.REACT_APP_BASE_URL_NEXT_FI ?? ''}/ryhmat-koulut-ja-yritykset`,
                urlOrder: `${process.env.REACT_APP_BASE_URL_ORDER_FI ?? ''}/fi`,
                urlProfile: `${process.env.REACT_APP_BASE_URL_PROFILE_FI ?? ''}/fi`,
                urlNext: process.env.REACT_APP_BASE_URL_NEXT_FI ?? '',
                urlLeosFamily: `${process.env.REACT_APP_BASE_URL_NEXT_FI ?? ''}/leos-hoplop-family`,
                urlFaq: `${process.env.REACT_APP_BASE_URL_NEXT_FI ?? ''}/tietoa-leos-leikkimaasta/asiakaspalvelu/faq`,
                urlShop: `${process.env.REACT_APP_BASE_URL_ORDER_SE ?? ''}/fi/shop`,
            },
            {
                code: 'DE',
                language: 'de',
                slug: 'germany',
                phoneCode: 49,
                name: 'Germany',
                nativeName: 'Deutschland',
                urlParty: `${process.env.REACT_APP_BASE_URL_PARTY_DE ?? ''}/de`,
                urlHome: process.env.REACT_APP_URL_HOME_DE ?? '',
                urlMore: `${process.env.REACT_APP_URL_HOME_DE ?? ''}global/`,
                urlPartyFaq: process.env.REACT_APP_URL_PARTY_FAQ_DE ?? '',
                urlSportKidz: process.env.REACT_APP_URL_SPORT_KIDZ_DE ?? '',
                urlSummerCamp: process.env.REACT_APP_URL_SUMMER_CAMP_DE ?? '',
                urlGroupBooking: `${process.env.REACT_APP_BASE_URL_NEXT_DE ?? ''}/gruppen-schulen-unternehmen`,
                urlOrder: `${process.env.REACT_APP_BASE_URL_ORDER_DE ?? ''}/de`,
                urlProfile: `${process.env.REACT_APP_BASE_URL_PROFILE_DE ?? ''}/de`,
                urlNext: process.env.REACT_APP_BASE_URL_NEXT_DE ?? '',
                urlBookEntrance: `${process.env.REACT_APP_BASE_URL_NEXT_DE ?? ''}/book-entrance`,
                urlLeosFamily: `${process.env.REACT_APP_BASE_URL_NEXT_DE ?? ''}/leos-family`,
                urlFaq: `${process.env.REACT_APP_BASE_URL_NEXT_DE ?? ''}/uber-leos/kundenservice/faq`,
                urlShop: `${process.env.REACT_APP_BASE_URL_ORDER_SE ?? ''}/de/shop`,
            },
        ];
    }, []);

    const currentCountry: CountryInfo = useMemo(() => {
        return countries.find((country) => country.slug === currentDomainConfig.countrySlug) as CountryInfo;
    }, [countries]);

    const getCountryByCode = useCallback(
        (code: string) => {
            return countries.find((country) => country.code === code) ?? null;
        },
        [countries],
    );

    const getCountryByCulture = useCallback(
        (culture: string) => {
            const [, code] = culture.split('-');
            return countries.find((country) => country.code === code) ?? null;
        },
        [countries],
    );

    return { currentCountry, countries, getCountryByCode, getCountryByCulture };
}
