import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Carousel } from '../../../components/common/Carousel/Carousel';
import { PartyPackage } from '../../../model';
import { imageUrl } from '../../../utils';
import ImagePackageFallback from './package-fallback.jpg';
import { PackageCarouselItem } from './PackageCarouselItem';

interface PackageCarouselProps {
    packages: PartyPackage[];
    className: string;
    onSelect(pkg: PartyPackage): void;
}

/**
 * Carousel to display available packages the select package page.
 */
export const PackageCarousel: FunctionComponent<PackageCarouselProps> = ({ packages, className, onSelect }) => {
    const { formatMessage } = useIntl();

    return (
        <Carousel
            title={formatMessage({ id: 'select-package.choose-package-title', defaultMessage: 'Välj kalaspaket' })}
            className={className}
        >
            {packages.map((pkg) => {
                const articles: string[] = [];
                var information: string = '';

                if (pkg.description) {
                    const removedUlFromString = pkg.description.replace(/\s*<ul[^>]*><\/ul>\s*/, '');
                    const regex = /(<([div>]+)>)/gi;
                    const styleRegex = /\s*style=(["'])(.*?)\1/gim;

                    information = removedUlFromString.replace(regex, '').replace(styleRegex, '');
                }

                let imageSrc = ImagePackageFallback;
                if (pkg.image) {
                    imageSrc = imageUrl(pkg.image, 560, 370);
                }

                return (
                    <PackageCarouselItem
                        key={pkg.guid}
                        imageSrc={imageSrc}
                        name={pkg.name}
                        price={pkg.displayprice.pricevalue}
                        currency={pkg.displayprice.currency}
                        articles={articles}
                        information={information}
                        onClick={() => onSelect(pkg)}
                    />
                );
            })}
        </Carousel>
    );
};
