/**
 * Masks the string using '*'.
 *
 * @param str The string to mask
 * @param keepFirst How many characters to keep unmasked in the beginning of the string.
 * @param keepLast How many characters to keep unmasked in the end of the string.
 * @returns The masked string.
 */
export function maskString(str: string, keepFirst: number = 0, keepLast: number = 0): string {
    const strLength = str.length;
    const maskLength = str.length - keepFirst - keepLast;

    if (maskLength <= 0) {
        return str;
    }

    let result = str.substring(0, keepFirst);
    result += maskLength > 0 ? '*'.repeat(maskLength) : '';
    result += str.substring(strLength - keepLast);

    return result;
}
