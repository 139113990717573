import { RefObject, useEffect, useState } from 'react';

export type TransitionState = 'enter' | 'entering' | 'entered' | 'exit' | 'exiting' | 'exited';

/**
 * Manages the transition lifecycle (enter/exit) by returning the current transition state.
 * As the transition end event cannot be used reliably to determine when the transition is completed, we'll use a
 * timeout instead. https://bugzilla.mozilla.org/show_bug.cgi?id=683696
 *
 * Usage:
 *
 * .entering, .exiting { transition: opacity 1s ease; }
 * .enter, .exiting { opacity: 0; }
 * .entering, .exit { opacity: 1; }
 *
 * const transitionState = useTransition(elementRef, true, 300, true);
 * return <div className={clsx(classes[transitionState])} />
 */
export function useTransition(ref: RefObject<HTMLElement>, enter: boolean, timeout: number, enterOnMount = false): TransitionState {
    // Set our initial state to entered if we want to show it straight away
    const [state, setState] = useState<TransitionState>(enter && !enterOnMount ? 'entered' : 'exited');

    // Update our transition state when we change enter prop.
    useEffect(() => {
        let timeoutId: number | undefined;

        if (enter) {
            switch (state) {
                case 'exit':
                case 'exited':
                    setState('enter');
                    break;

                case 'exiting':
                    setState('entering');
                    break;

                case 'enter':
                    // Trigger a re-flow so that we can transition from the values in "enter" to "entering".
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    ref.current && ref.current.scrollTop;
                    setState('entering');
                    break;

                case 'entering':
                    if (!ref.current) {
                        setState('entered');
                    } else {
                        // Use a timeout as onTransitionEnd is not reliable
                        timeoutId = window.setTimeout(() => {
                            setState('entered');
                        }, timeout + 10);
                    }
                    break;
            }
        } else {
            switch (state) {
                case 'enter':
                case 'entered':
                    setState('exit');
                    break;

                case 'entering':
                    setState('exiting');
                    break;

                case 'exit':
                    // Trigger a re-flow so that we can transition from the values in "exit" to "exiting".
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    ref.current && ref.current.scrollTop;
                    setState('exiting');
                    break;

                case 'exiting':
                    if (!ref.current) {
                        setState('exited');
                    } else {
                        // Use a timeout as onTransitionEnd is not reliable
                        timeoutId = window.setTimeout(() => {
                            setState('exited');
                        }, timeout + 10);
                    }
                    break;
            }
        }

        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [timeout, enter, state, ref]);

    return state;
}
