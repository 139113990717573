import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { CSSProperties, FunctionComponent } from 'react';

interface FontAwesomeIconProps {
    icon: IconDefinition;
    width?: number;
    height?: number;
    className?: string;
}

export const FontAwesomeIcon: FunctionComponent<FontAwesomeIconProps> = ({ icon, width, height, className }) => {
    // Extract icon info from definition
    const [viewBoxWidth, viewBoxHeight, , , path] = icon.icon;

    // We can have one or more paths
    const paths = typeof path === 'string' ? [path] : path;

    // If both width and height are undefined, don't set apply any inline-style to allow for css styling.
    let style: CSSProperties | undefined;
    if (width !== undefined || height !== undefined) {
        style = {
            width: width ? `${width}px` : 'auto',
            height: height ? `${height}px` : 'auto',
        };
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
            aria-hidden="true"
            focusable="false"
            role="img"
            style={style}
            className={className}
        >
            {paths.map((p, index) => (
                <path key={index} fill="currentColor" d={p} />
            ))}
        </svg>
    );
};
