import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { FunctionComponent, ReactNode, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { useTransition } from '../../../hooks/useTransition';
import { FontAwesomeIcon } from '../FontAwesomeIcon/FontAwesomeIcon';
import { IconButton } from '../IconButton/IconButton';
import { SidebarCountry } from '../SidebarCountry/SidebarCountry';
import { Typography } from '../Typography/Typography';
import classes from './Sidebar.module.scss';
import { SidebarUser } from './SidebarUser';

interface SidebarProps {
    open: boolean;
    primaryLinks: ReactNode;
    secondaryLinks: ReactNode;
    user: {
        userName: string | null;
        firstName: string | null;
        lastName: string | null;
    } | null;
    profileEditLink?: string;
    onProfileEditClick?(): void;
    onLoginClick?(): void;
    onLogoutClick?(): void;
    onClose(): void;
}

/**
 * Design system global sidebar.
 */
export const Sidebar: FunctionComponent<SidebarProps> = ({
    open,
    primaryLinks,
    secondaryLinks,
    user,
    profileEditLink,
    onProfileEditClick,
    onLoginClick,
    onLogoutClick,
    onClose,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const backdropRef = useRef<HTMLDivElement>(null);
    const containerTransitionState = useTransition(containerRef, open, 600);
    const backdropTransitionState = useTransition(backdropRef, open, 600);

    const portalContainer = useMemo(() => {
        return document.getElementById('app-sidebar') as HTMLElement;
    }, []);

    const onCloseClick = () => {
        if (open) {
            onClose();
        }
    };

    const containerClassNames = clsx(classes.container, {
        [classes.containerEntering]: containerTransitionState === 'entering',
        [classes.containerExiting]: containerTransitionState === 'exiting',
        [classes.containerEnter]: containerTransitionState === 'enter',
        [classes.containerExit]: containerTransitionState === 'exit',
    });

    const backdropClassNames = clsx(classes.backdrop, {
        [classes.backdropEntering]: backdropTransitionState === 'entering',
        [classes.backdropExiting]: backdropTransitionState === 'exiting',
        [classes.backdropEnter]: backdropTransitionState === 'enter',
        [classes.backdropExit]: backdropTransitionState === 'exit',
    });

    const sidebar =
        containerTransitionState !== 'exited' ? (
            <>
                <div ref={backdropRef} className={backdropClassNames} onClick={onCloseClick} />

                <div ref={containerRef} className={containerClassNames}>
                    <Typography variant="label" component="h4" className={classes.label}>
                        <FormattedMessage id="sidebar.label-book-and-order" defaultMessage="Boka & beställ" />
                    </Typography>

                    <nav className={classes.primaryLinks}>{primaryLinks}</nav>

                    <Typography variant="label" component="h5" className={classes.label}>
                        <FormattedMessage id="sidebar.label-more" defaultMessage="Mer" />
                    </Typography>

                    <nav className={classes.secondaryLinks}>{secondaryLinks}</nav>

                    <div className={classes.footer}>
                        <SidebarUser
                            user={user}
                            profileEditLink={profileEditLink}
                            onProfileEditClick={onProfileEditClick}
                            onLoginClick={onLoginClick}
                            onLogoutClick={onLogoutClick}
                            className={classes.user}
                        />

                        <SidebarCountry className={classes.country} />
                    </div>
                </div>

                <IconButton
                    className={clsx(classes.closeButton, open && classes.closeButtonOpen)}
                    rippleClassName={classes.closeButtonRipple}
                    onClick={onCloseClick}
                >
                    <FontAwesomeIcon icon={faTimes} height={38} />
                </IconButton>
            </>
        ) : null;

    return createPortal(sidebar, portalContainer);
};
