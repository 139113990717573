import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';
import { FieldError } from '../FieldError/FieldError';
import classes from './TextField.module.scss';

interface TextFieldProps
    extends Pick<
        InputHTMLAttributes<HTMLInputElement>,
        'name' | 'value' | 'defaultValue' | 'inputMode' | 'autoCapitalize' | 'disabled' | 'onBlur' | 'onChange'
    > {
    label: string;
    error?: string | null;
    className?: string;
}

/**
 * Design system text field.
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    ({ name, value, defaultValue, inputMode, autoCapitalize, disabled, onBlur, onChange, label, error, className }, ref) => {
        const hasError = typeof error === 'string';

        return (
            <div className={clsx(classes.container, className)}>
                <input
                    ref={ref}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    inputMode={inputMode}
                    autoCapitalize={autoCapitalize}
                    disabled={disabled}
                    onBlur={onBlur}
                    onChange={onChange}
                    aria-label={label}
                    placeholder=" "
                    className={clsx(classes.input, { [classes.inputError]: hasError })}
                />

                <label className={clsx(classes.label, { [classes.labelError]: hasError })}>{label}</label>

                {hasError && error !== '' && <FieldError className={classes.error}>{error}</FieldError>}
            </div>
        );
    },
);
