import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { useCountries } from 'hooks/useCountries';
import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonContent,
    ComboButtonTitle,
    ExpandedComboButton,
} from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Typography } from '../../../components/common/Typography/Typography';
import { PartyPackage } from '../../../model';
import { Bookingarticle } from '../../../model/reservation';
import classes from './BookingSummary.module.scss';

interface BookingSummaryPriceLine {
    name: string;
    price: number;
}

interface BookingSummaryProps {
    playground: string;
    date: Date;
    startTime: string;
    arrivalTime: string;
    enableArrivalTime: boolean;
    room: string;
    guests: number;
    articles: Bookingarticle[];
    partyPackage?: PartyPackage;
    total: number;
    currency: string;
    className?: string;
    artNoExtraBirthdayChild?: string;
    playcentertype: string;
}

export const BookingSummary: FunctionComponent<BookingSummaryProps> = ({
    playground,
    date,
    startTime,
    arrivalTime,
    enableArrivalTime,
    room,
    guests,
    articles,
    partyPackage,
    total,
    currency,
    className,
    artNoExtraBirthdayChild,
    playcentertype,
}) => {
    const { formatMessage, formatDate, formatNumber } = useIntl();
    const { currentCountry } = useCountries();

    const isPlaycenterHopLop = currentCountry.code === 'FI' && playcentertype === 'HopLop';

    // Filter out all articles that are not exchange or addon and place exchange articles before addons
    const addons = articles
        .filter((article) => article.itemtype === 'Addon' || article.itemtype === 'Exchange')
        .sort((a, b) => {
            if (a.itemtype === b.itemtype) {
                return 0;
            }

            return a.itemtype === 'Addon' ? 1 : -1;
        });

    const extraBirthdayChildren = articles.filter((article) => article.artno === artNoExtraBirthdayChild);

    // Create price lines
    const bookingRows: BookingSummaryPriceLine[] = [];

    if (partyPackage) {
        // Calculate total price of all addons and birthday children
        const totalAddonsPrice = [...addons, ...extraBirthdayChildren]
            .map((article) => article.price * article.amount)
            .reduce((prev, curr) => prev + curr, 0);

        // Remove the price of all addons to get the price of the package
        bookingRows.push({
            name: partyPackage.name,
            price: total - totalAddonsPrice,
        });
    }

    bookingRows.push(
        ...addons.map((article) => ({
            name: article.name,
            price: article.price * article.amount,
        })),
    );

    bookingRows.push(
        ...extraBirthdayChildren.map((article) => ({
            name: article.name,
            price: article.price * article.amount,
        })),
    );

    const summaryLines = [
        {
            label: formatMessage({ id: 'confirm-booking.summary-playground', defaultMessage: 'Plats' }),

            value: isPlaycenterHopLop
                ? formatMessage({ id: 'general.playground', defaultMessage: '{playground}' }, { playground })
                : formatMessage({ id: 'general.leos-playground', defaultMessage: 'Leo´s lekland, {playground}' }, { playground }),
            include: true,
        },
        {
            label: formatMessage({ id: 'confirm-booking.summary-date', defaultMessage: 'Datum' }),
            value: formatDate(date, { year: 'numeric', month: 'long', day: 'numeric' }),
            include: true,
        },
        {
            label: formatMessage({ id: 'confirm-booking.summary-time', defaultMessage: 'Tid' }),
            value: startTime,
            include: true,
        },
        {
            label: formatMessage({ id: 'confirm-booking.summary-arrival-time', defaultMessage: 'Ankomsttid' }),
            value: arrivalTime,
            include: enableArrivalTime,
        },
        {
            label: formatMessage({ id: 'confirm-booking.summary-room', defaultMessage: 'Rum' }),
            value: room,
            include: true,
        },
        {
            label: formatMessage({ id: 'confirm-booking.summary-guests', defaultMessage: 'Barn ink. födelsedagsbarn' }),
            value: formatMessage({ id: 'confirm-booking.summary-guests-amount', defaultMessage: '{guests} st.' }, { guests }),
            include: true,
        },
    ];

    return (
        <ExpandedComboButton className={className}>
            <ComboButton component="div">
                <ComboButtonAction>
                    <FontAwesomeIcon icon={faShoppingCart} width={32} />
                </ComboButtonAction>
                <ComboButtonContent>
                    <ComboButtonTitle>
                        <FormattedMessage id="confirm-booking.booking-details-title" defaultMessage="Bokningsdetaljer" />
                    </ComboButtonTitle>
                </ComboButtonContent>
            </ComboButton>

            <div className={classes.content}>
                <Typography variant="label" component="h2" className={classes.label}>
                    <FormattedMessage id="confirm-booking.summary-title" defaultMessage="Summering" />
                </Typography>

                {summaryLines
                    .filter((line) => line.include)
                    .map((line) => (
                        <div key={`${line.label}-${line.value}`} className={classes.priceLine}>
                            <Typography component="span">{line.label}</Typography>

                            <Typography component="span" className={classes.priceValue}>
                                {line.value}
                            </Typography>
                        </div>
                    ))}

                <svg xmlns="http://www.w3.org/2000/svg" className={classes.separator}>
                    <line x1="0%" y1="0" x2="100%" y2="0" />
                </svg>

                <Typography variant="label" component="h2" className={classes.label}>
                    <FormattedMessage id="confirm-booking.prices-title" defaultMessage="Priser" />
                </Typography>

                {bookingRows.map((row) => (
                    <div key={`${row.name}-${row.price}`} className={classes.priceLine}>
                        <Typography component="span">{row.name}</Typography>

                        <Typography component="span" className={classes.priceValue}>
                            {formatNumber(row.price, { style: 'currency', currency, minimumFractionDigits: 0 })}
                        </Typography>
                    </div>
                ))}

                <div className={clsx(classes.priceLine, classes.priceLineTotal)}>
                    <Typography component="span" className={classes.priceTotal}>
                        <FormattedMessage id="confirm-booking.prices-total" defaultMessage="Totalt" />
                    </Typography>

                    <Typography component="span" className={classes.priceTotal}>
                        {formatNumber(total, { style: 'currency', currency, minimumFractionDigits: 0 })}
                    </Typography>
                </div>
            </div>
        </ExpandedComboButton>
    );
};
