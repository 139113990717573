import DOMPurify from 'dompurify';
import { FunctionComponent, useMemo } from 'react';
import classes from './RichText.module.scss';

interface RichTextProps {
    children: string;
}

/**
 * Sanitizes and renders HTML content from a string.
 */
export const RichText: FunctionComponent<RichTextProps> = ({ children }) => {
    const innerHtml = useMemo(() => {
        return { __html: DOMPurify.sanitize(children) };
    }, [children]);

    return <div className={classes.container} dangerouslySetInnerHTML={innerHtml} />;
};
