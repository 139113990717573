import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { IconButton } from '../../components/common/IconButton/IconButton';
import classes from './GuestCounter.module.scss';

interface GuestCounterProps {
    minValue: number;
    maxValue: number;
    value: number;
    className?: string;
    onChange(value: number): void;
}

export const GuestCounter: FunctionComponent<GuestCounterProps> = ({ minValue, maxValue, value, className, onChange }) => {
    return (
        <div className={clsx(classes.container, className)}>
            <IconButton
                disabled={value <= minValue}
                className={classes.button}
                rippleClassName={classes.buttonRipple}
                onClick={() => onChange(value - 1)}
            >
                <FontAwesomeIcon icon={faMinus} width={14} className={classes.buttonIcon} />
            </IconButton>

            <span className={classes.value}>{value}</span>

            <IconButton
                disabled={value >= maxValue}
                className={classes.button}
                rippleClassName={classes.buttonRipple}
                onClick={() => onChange(value + 1)}
            >
                <FontAwesomeIcon icon={faPlus} width={14} className={classes.buttonIcon} />
            </IconButton>
        </div>
    );
};
