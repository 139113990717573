import { format, isAfter, isBefore, parse, parseISO } from 'date-fns';
import { OpeningHoursDto } from '../model/OpeningHours/OpeningHoursDto';

/**
 * Returns the closing time (the max "to" value) of the opening hours as a JS Date object.
 *
 * If there are no available opening hours, null is returned.
 */
export function getClosingTime(openingHours: OpeningHoursDto): string | null {
    let max: Date | null = null;

    for (const hour of openingHours.open) {
        const date = parseISO(openingHours.date + 'T' + hour.to);

        if (!max || isAfter(date, max)) {
            max = date;
        }
    }

    return max && format(max, 'HH:mm');
}

/**
 * Clamp the time to the specified min and max. If max > min the minimum will always take precedence.
 */
export function clampTime(time: string, minTime: string, maxTime: string): string {
    const referenceDate = new Date();
    const dateTime = parse(time, 'HH:mm', referenceDate);
    const minDateTime = parse(minTime, 'HH:mm', referenceDate);
    const maxDateTime = parse(maxTime, 'HH:mm', referenceDate);

    // The time is before the min time OR the max time is before the min time
    if (isBefore(dateTime, minDateTime) || isBefore(maxDateTime, minDateTime)) {
        return format(minDateTime, 'HH:mm');
    }

    // The time is after the max time
    if (isAfter(dateTime, maxDateTime)) {
        return format(maxDateTime, 'HH:mm');
    }

    // The time is within the allowed range
    return format(dateTime, 'HH:mm');
}
