import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { forwardRef, ReactNode, SelectHTMLAttributes } from 'react';
import { FontAwesomeIcon } from '../../common/FontAwesomeIcon/FontAwesomeIcon';
import { FieldError } from '../FieldError/FieldError';
import classes from './SelectField.module.scss';

interface SelectFieldProps
    extends Pick<SelectHTMLAttributes<HTMLSelectElement>, 'name' | 'value' | 'defaultValue' | 'disabled' | 'onBlur' | 'onChange'> {
    label: string;
    placeholder?: string;
    placeholderValue?: string | number;
    error?: string | null;
    textSize?: 'normal' | 'large';
    className?: string;
    children: ReactNode;
}

/**
 * Design system text field.
 */
export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
    (
        {
            name,
            disabled,
            value,
            defaultValue,
            onBlur,
            onChange,
            label,
            placeholder,
            placeholderValue,
            error,
            textSize = 'normal',
            className,
            children,
        },
        ref,
    ) => {
        const hasError = typeof error === 'string';
        const classNames = clsx(classes.select, {
            [classes.selectError]: hasError,
            [classes.textNormal]: textSize === 'normal',
            [classes.textLarge]: textSize === 'large',
        });

        return (
            <div className={clsx(classes.container)}>
                <select
                    ref={ref}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    onBlur={onBlur}
                    onChange={onChange}
                    aria-label={label}
                    className={classNames}
                >
                    {placeholder && (
                        <option value={placeholderValue} disabled>
                            {placeholder}
                        </option>
                    )}

                    {children}
                </select>

                <FontAwesomeIcon icon={faChevronDown} width={18} className={classes.icon} />

                {hasError && error !== '' && <FieldError className={classes.error}>{error}</FieldError>}
            </div>
        );
    },
);
