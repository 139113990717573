import clsx from 'clsx';
import {
    DetailedHTMLProps,
    FunctionComponent,
    ImgHTMLAttributes,
    ReactEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import classes from './FadeInImage.module.css';

interface FadeInImageProps extends Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'alt'> {
    src: string;
    alt?: string;
}

/**
 * Waits for the image to load and then fades in the image using opacity.
 *
 * Usage:
 * <FadeInImage src={ImageLogo} alt="Logo" />
 */
export const FadeInImage: FunctionComponent<FadeInImageProps> = ({ className, onLoad, src, alt, ...props }) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const ref = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        setIsLoaded(!!ref.current?.complete);
    }, [src]);

    const refCallback = useCallback((node: HTMLImageElement | null) => {
        ref.current = node;
        setIsLoaded(!!node?.complete);
    }, []);

    const handleOnLoad: ReactEventHandler<HTMLImageElement> = (event) => {
        setIsLoaded(true);
        onLoad?.(event);
    };

    return (
        <img
            ref={refCallback}
            className={clsx(classes.image, isLoaded && classes.loaded, className)}
            onLoad={handleOnLoad}
            src={src}
            alt={alt ?? ''}
            {...props}
        />
    );
};
