import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { LoadingDialog } from '../components/common/LoadingDialog/LoadingDialog';

interface GlobalLoadingContextValue {
    isLoading: boolean;
    incrementLoader: () => void;
    decrementLoader: () => void;
}

const GlobalLoadingContext = createContext<GlobalLoadingContextValue>({
    isLoading: false,
    incrementLoader: () => {},
    decrementLoader: () => {},
});

export const GlobalLoadingProvider: FunctionComponent = ({ children }) => {
    const [counter, setCounter] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const incrementLoader = useCallback(() => {
        setCounter((value) => value + 1);
    }, []);

    const decrementLoader = useCallback(() => {
        // We'll add a timeout before we disable the loader to prevent flicker when there's very close between something
        // that stopped loading and something else that just started to load.
        setTimeout(() => {
            setCounter((value) => value - 1);
        }, 200);
    }, []);

    useEffect(() => {
        setIsLoading(counter > 0);
    }, [counter]);

    return (
        <GlobalLoadingContext.Provider value={{ isLoading, incrementLoader, decrementLoader }}>
            <LoadingDialog open={isLoading} />
            {children}
        </GlobalLoadingContext.Provider>
    );
};

export function useGlobalLoading() {
    return useContext(GlobalLoadingContext);
}
