import { FunctionComponent } from 'react';
import classes from './CircularProgress.module.scss';

interface CircularProgressProps {
    size: number;
    thickness: number;
}

/**
 * Design system circular progress.
 */
export const CircularProgress: FunctionComponent<CircularProgressProps> = ({ size, thickness }) => {
    const strokeWidth = (44 / size) * thickness;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 44 44"
            aria-hidden="true"
            focusable="false"
            role="img"
            className={classes.svg}
        >
            <circle className={classes.circle} cx={22} cy={22} r={22 - strokeWidth / 2} strokeWidth={strokeWidth} />
        </svg>
    );
};
