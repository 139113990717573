import { saveAs } from 'file-saver';
import { createEvent, DurationObject } from 'ics';

/**
 * The data used to generate a single calendar event.
 */
interface CalendarEvent {
    /** The title of the event that will show up in the calendar. */
    title: string;

    /** A longer description, should include url if this is for an online event. */
    description: string;

    /** Start of the event. */
    start: Date;

    /** End date of the event. */
    end: Date;

    /** When an audio alarm should trigger. */
    alarm?: DurationObject;
}

/**
 * Creates and saves an event to the user's calendar/downloads an ics file.
 *
 * @param event The calendar event.
 */
export function saveToCalendar(event: CalendarEvent): void {
    const { error, value } = createEvent({
        title: event.title,
        description: event.description,
        start: [
            event.start.getUTCFullYear(),
            event.start.getUTCMonth() + 1,
            event.start.getUTCDate(),
            event.start.getUTCHours(),
            event.start.getUTCMinutes(),
        ],
        startInputType: 'utc',
        end: [
            event.end.getUTCFullYear(),
            event.end.getUTCMonth() + 1,
            event.end.getUTCDate(),
            event.end.getUTCHours(),
            event.end.getUTCMinutes(),
        ],
        endInputType: 'utc',
        alarms: [{ action: 'audio', trigger: event.alarm }],
    });

    if (error) {
        throw error;
    }

    if (value === undefined) {
        throw new Error('Failed to generate calendar event');
    }

    const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
    saveAs(blob, 'event.ics');
}
