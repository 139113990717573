import clsx from 'clsx';
import { FunctionComponent, HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from 'react';
import { Chip } from '../Chip/Chip';
import classes from './SidebarLink.module.scss';

interface SidebarLinkProps {
    component: 'a' | 'button';
    type: 'primary' | 'secondary';
    href?: string;
    target?: HTMLAttributeAnchorTarget;
    count?: number;
    selected?: boolean;
    onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
    children: ReactNode;
}

/**
 * Link used in the sidebar.
 */
export const SidebarLink: FunctionComponent<SidebarLinkProps> = ({ component, type, href, target, count, selected, onClick, children }) => {
    const classNames = clsx(classes.link, {
        [classes.primary]: type === 'primary',
        [classes.secondary]: type === 'secondary',
        [classes.selected]: selected,
    });

    const content = (
        <>
            <span>{children}</span>
            {count !== undefined && <Chip>{count}</Chip>}
        </>
    );

    if (component === 'a') {
        return (
            <a href={href} target={target} className={classNames} onClick={onClick}>
                {content}
            </a>
        );
    }

    return (
        <button type="button" className={classNames} onClick={onClick}>
            {content}
        </button>
    );
};
