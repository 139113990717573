import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertText } from '../../../components/common/Alert/Alert';
import { AlertDialog } from '../../../components/common/AlertDialog/AlertDialog';
import { Button } from '../../../components/common/Button/Button';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { useApi } from '../../../contexts/ApiProvider';
import { useBooking } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import { GuestCounter } from '../../../features/guest-count/GuestCounter';
import useApiError from '../../../hooks/useApiError';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { BookingDetails, BookingStatus } from '../../../model/BookingDetails';
import { Stage } from '../../../model/stage';
import classes from './EditGuestCount.module.scss';

export const EditGuestCount: FunctionComponent = () => {
    const booking = useBooking();
    const { client } = useApi();
    const { formatMessage } = useIntl();
    const { toastApiError } = useApiError();
    const { openStage } = useBookingParams();
    const { incrementLoader, decrementLoader } = useGlobalLoading();

    const [guestCount, setGuestCount] = useState<number>(0);
    const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (booking.details) {
            const guestCount = Math.max(booking.details.minGuests, booking.details.guestCount);
            setGuestCount(guestCount);
        }
    }, [booking.details]);

    if (!booking.details) {
        return null;
    }

    // We'll only count the invited (non-empty guests) when we disallow reducing the guest count.
    const invitedGuests = booking.details.guests.length + booking.details.birthdayKids.length;

    let canContinue = false;
    if (booking.details.status !== BookingStatus.Confirmed) {
        // Non-confirmed bookings can always continue
        canContinue = true;
    } else if (booking.details.guestCount !== guestCount) {
        // There is nothing to continue to when the booking is confirmed, but we haven't changed the guest count
        canContinue = true;
    }

    const onGuestCountChange = (value: number) => {
        if (!booking.details) {
            return;
        }

        if (value >= invitedGuests) {
            setGuestCount(value);
        } else {
            setErrorDialogOpen(true);
        }
    };

    const onErrorDialogClose = (editGuests: boolean) => {
        setErrorDialogOpen(false);

        if (editGuests) {
            openStage(Stage.EditGuests, booking.details);
        }
    };

    const onBackClick = () => {
        if (booking.details?.status === BookingStatus.Confirmed) {
            openStage(Stage.BookingOverview, booking.details);
        } else {
            openStage(Stage.SelectAddons, booking.details);
        }
    };

    const onContinueClick = async () => {
        if (!canContinue || !booking.details) {
            return;
        }

        if (booking.details.guestCount !== guestCount) {
            incrementLoader();

            let details: BookingDetails | undefined;
            try {
                await client.changeGuestCount(booking.details.guid, guestCount);
                details = await client.getBooking(booking.details.guid);
            } catch (error) {
                toastApiError(error);
            }

            decrementLoader();

            if (details) {
                booking.set(details);

                // Go directly to confirm stage if we have booking changes (changes on an already confirmed booking)
                const stage = details.hasBookingChanges ? Stage.ConfirmBooking : Stage.EditBirthdayKids;
                openStage(stage, booking.details);
            }
        } else if (booking.details.status !== BookingStatus.Confirmed) {
            openStage(Stage.EditBirthdayKids, booking.details);
        }
    };

    return (
        <>
            <AlertDialog
                open={errorDialogOpen}
                text={formatMessage(
                    {
                        id: 'edit-guest-count.reduce-below-invites-alert-text',
                        defaultMessage:
                            'Du har redan bjudit in {count} gäster eller födelsedagsbarn. Ta bort en gäst eller ett födelsedagsbarn om du vill minska antalet.',
                    },
                    { count: invitedGuests },
                )}
                className={classes.errorDialog}
            >
                <Button color="minionYellow" onClick={() => onErrorDialogClose(true)}>
                    <FormattedMessage id="edit-guest-count.reduce-below-invites-alert-button" defaultMessage="Redigera gäster" />
                </Button>

                <Button color="grandparentGrey900" onClick={() => onErrorDialogClose(false)}>
                    <FormattedMessage id="general.close" defaultMessage="Stäng" />
                </Button>
            </AlertDialog>

            <PageContainer
                title={formatMessage({ id: 'edit-guest-count.title', defaultMessage: 'Antal gäster' })}
                onBackClick={onBackClick}
            >
                <Alert className={classes.note}>
                    <AlertText>
                        <FormattedMessage
                            id="edit-guest-count.note-text"
                            defaultMessage="Minsta antalet gäster (inkl.födelsedagsbarn) för detta kalas är {minGuests} och max är {maxGuests} stycken."
                            values={{ minGuests: booking.details?.minGuests, maxGuests: booking.details?.maxGuests }}
                        />
                    </AlertText>
                </Alert>

                <GuestCounter
                    minValue={booking.details?.minGuests ?? 0}
                    maxValue={booking.details?.maxGuests ?? 0}
                    value={guestCount}
                    className={classes.guestCounter}
                    onChange={onGuestCountChange}
                />

                <PageButtons className={classes.pageButtons}>
                    <Button disabled={!canContinue} color="minionYellow" onClick={onContinueClick}>
                        <FormattedMessage id="general.continue" defaultMessage="Gå vidare" />
                    </Button>

                    <Button color="transparent" onClick={onBackClick}>
                        <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                    </Button>
                </PageButtons>
            </PageContainer>
        </>
    );
};
