import { faBirthdayCake } from '@fortawesome/pro-solid-svg-icons';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonBottomText,
    ComboButtonContent,
    ComboButtonTitle,
} from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Guest } from '../../../model';

interface BirthdayGuestCardProps {
    guest: Guest;
    className?: string;
}

/**
 * Displays the status and any messages for a single invited guest.
 */
export const BirthdayGuestCard: FunctionComponent<BirthdayGuestCardProps> = ({ guest, className }) => {
    return (
        <ComboButton component="div" className={className}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faBirthdayCake} width={28} />
            </ComboButtonAction>

            <ComboButtonContent>
                <ComboButtonTitle>{guest.guestname}</ComboButtonTitle>

                <ComboButtonBottomText>
                    <FormattedMessage id="edit-guests.status-birthday-kid" defaultMessage="Födelsedagsbarn" />
                </ComboButtonBottomText>
            </ComboButtonContent>
        </ComboButton>
    );
};
