import { faChevronRight, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { Playground } from 'model';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ComboButton, ComboButtonAction, ComboButtonContent, ComboButtonTitle } from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { useLocation } from '../../../contexts/LocationProvider';
import { currentDomainConfig } from '../../../domain-config';
import { Stage } from '../../../model/stage';
import classes from './SelectPlayground.module.scss';

export const SelectPlayground: FunctionComponent = () => {
    const { locale, formatMessage } = useIntl();
    const { getPlaygrounds } = useLocation();
    const navigate = useNavigate();

    const playgrounds = getPlaygrounds(currentDomainConfig.countrySlug);

    const onBackClick = () => {
        navigate(`/${locale}`);
    };

    const onPlaygroundClick = (playground: Playground) => {
        navigate(`/${locale}/${playground.slug}/${Stage.SelectDate}`);
    };

    return (
        <PageContainer
            title={formatMessage({ id: 'select-playground.title', defaultMessage: 'Välj ditt lekland' })}
            onBackClick={onBackClick}
        >
            <div className={classes.list}>
                {playgrounds?.map((playground) => (
                    <ComboButton key={playground.guid} onClick={() => onPlaygroundClick(playground)}>
                        <ComboButtonAction>
                            <FontAwesomeIcon icon={faMapMarkerAlt} height={34} />
                        </ComboButtonAction>

                        <ComboButtonContent>
                            <ComboButtonTitle>{playground.name}</ComboButtonTitle>
                        </ComboButtonContent>

                        <ComboButtonAction>
                            <FontAwesomeIcon icon={faChevronRight} height={23.46} />
                        </ComboButtonAction>
                    </ComboButton>
                ))}
            </div>
        </PageContainer>
    );
};
