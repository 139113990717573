import { FunctionComponent } from 'react';
import classes from './Decoration.module.scss';

export const Decoration: FunctionComponent = () => {
    return (
        <>
            <div className={classes.top} />
        </>
    );
};
