import { addHours, format, parse, parseISO } from 'date-fns';
import { isEmptyGuest, sortGuests } from '../utils';
import { clampTime } from '../utils/openingHourUtils';
import { InvitationBackground } from './booking';
import { Country } from './Country';
import { PartyPackage } from './partyPackage';
import { Playground } from './playground';
import { Bookingarticle, Changereservation, Guest, Price, ReservationResponse } from './reservation';
import { Room } from './room';

export enum BookingStatus {
    Reserved = 'Reserved',
    Confirmed = 'Confirmed',
    Cancelled = 'Cancelled',
}

export interface BookingSlot {
    date: Date;
    roomGuid: string;
    timeslotGuid?: string;
    startTime: string;
    displayStartTime: string;
}

export interface BookingInvitation {
    background?: InvitationBackground;
    message: string;
    endTime: string;
    maxEndTime: string;
}

export interface BookingDetails {
    status: BookingStatus;
    guid: string;
    country: Country;
    playground: Playground;
    slot: BookingSlot;
    reservationGuid: string;
    changeReservation?: Changereservation;
    minGuests: number;
    maxGuests: number;
    guestCount: number;
    guests: Guest[];
    birthdayKids: Guest[];
    guestList: Guest[];
    room: Room;
    partyPackage?: PartyPackage;
    articles: Bookingarticle[];
    allergies: string;
    info: string;
    invitation: BookingInvitation;
    invitationBackgrounds: InvitationBackground[];
    price: Price;
    priceExtraBirthdayChild: Price;
    canChangeArticles: boolean;
    canChangeGuests: boolean;
    canChangeDate: boolean;
    hasSelectedInvitationBackground: boolean;
    hasBookingChanges: boolean;
    hasChangedGuestCount: boolean;
    createdDate: Date;
    artNoExtraBirthdayChild?: string;
    startTime: string;
    partyRoomTime: string;
    partyLength?: number;
}

export async function toBookingDetails(
    locale: string,
    booking: ReservationResponse,
    country: Country,
    playground: Playground,
    room: Room,
    invitationBackgrounds: InvitationBackground[],
    maxEndTime: string | null,
    partyPackage?: PartyPackage,
): Promise<BookingDetails> {
    let articles = booking.bookingarticles;
    let price = booking.price;
    let guestCount = booking.guests.length;

    // If we have booking changes, we will use changed values instead
    if (booking.bookingchanges) {
        articles = booking.bookingchanges.articles.bookingarticles;
        price = booking.bookingchanges.price;
        guestCount = booking.bookingchanges.guestcount;
    }

    const invitationBackground = invitationBackgrounds.find((img) => img.guid === booking.invitationbackgroundguid);
    const guests = booking.guests.filter((guest) => !isEmptyGuest(guest) && !guest.ismainguest);
    const birthdayKids = booking.guests.filter((guest) => !isEmptyGuest(guest) && guest.ismainguest);

    // We'll use the same date reference for all time calculations
    const dateReference = new Date();
    const startTimeDate = parse(booking.reservation.displaystarttime, 'HH:mm', dateReference);

    // We'll set a default end time to 3 hours after the party starts.
    let defaultEndTime = format(addHours(startTimeDate, 3), 'HH:mm');

    // The playground might close sooner than 3 hours after the party starts.
    if (maxEndTime) {
        // It's an admin error to have a party that is shorter than 1 hour, so we'll enforce a min length.
        const minEndTime = format(addHours(startTimeDate, 1), 'HH:mm');
        defaultEndTime = clampTime(defaultEndTime, minEndTime, maxEndTime);
    }

    return {
        status: booking.status as BookingStatus,
        guid: booking.guid,
        country: country,
        playground: playground,
        reservationGuid: booking.reservation.guid,
        changeReservation: booking.changereservation,
        slot: {
            roomGuid: booking.reservation.roomid,
            timeslotGuid: booking.reservation.timeslot?.guid,
            date: parseISO(booking.reservation.partydate),
            startTime: booking.reservation.starttime,
            displayStartTime: booking.reservation.displaystarttime,
        },
        minGuests: booking.minguests,
        maxGuests: booking.maxguests,
        guestCount: guestCount,
        guests: sortGuests(guests, locale),
        birthdayKids: sortGuests(birthdayKids, locale),
        guestList: booking.guests,
        room: room,
        partyPackage: partyPackage,
        articles: articles,
        allergies: booking.allergymessage ?? '',
        info: booking.staffmessage ?? '',
        invitation: {
            background: invitationBackground ?? invitationBackgrounds[0],
            message: booking.invitationmessage ?? '',
            endTime: booking.stopdate || defaultEndTime,
            maxEndTime: defaultEndTime,
        },
        invitationBackgrounds: invitationBackgrounds,
        price: price,
        priceExtraBirthdayChild: booking.priceextrabirthdaychild,
        canChangeArticles: booking.canchangearticles,
        canChangeGuests: booking.canchangeguests,
        canChangeDate: booking.canchangedate,
        hasSelectedInvitationBackground: !!invitationBackground,
        hasBookingChanges: !!booking.bookingchanges,
        hasChangedGuestCount: booking.bookingchanges && booking.bookingchanges.guestcount !== booking.guests.length,
        createdDate: parseISO(booking.createddate),
        artNoExtraBirthdayChild: booking.artnoextrabirthdaychild,
        startTime: booking.startdate,
        partyRoomTime: booking.reservation.starttime,
        partyLength: booking.partylength,
    };
}
