import clsx from 'clsx';
import { isSameDay } from 'date-fns';
import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Carousel } from '../../../components/common/Carousel/Carousel';
import { useBooking } from '../../../contexts/BookingProvider';
import { Room } from '../../../model';
import { BookingSlot } from '../../../model/BookingDetails';
import { FreeRoom } from '../../../model/freeDates';
import { imageUrl } from '../../../utils';
import ImageRoomFallback from './room-fallback.jpg';
import classes from './RoomCarousel.module.scss';
import { RoomCarouselItem, RoomCarouselItemSlot } from './RoomCarouselItem';

interface RoomCarouselProps {
    rooms: Room[];
    date: Date | null;
    slots: FreeRoom[] | null;
    isLoadingSlots: boolean;
    value: BookingSlot | null;
    disabled?: boolean;
    className: string;
    onChange(value: BookingSlot): void;
}

/**
 * Carousel to display available room and timeslots for the select date page.
 */
export const RoomCarousel: FunctionComponent<RoomCarouselProps> = ({
    rooms,
    date,
    slots,
    isLoadingSlots,
    value,
    disabled,
    className,
    onChange,
}) => {
    const booking = useBooking();
    const { formatMessage } = useIntl();

    let initialSlideIndex = 0;

    // Set initial slide when the selected value is the same as the slot value
    if (date && value && isSameDay(date, value.date)) {
        const index = rooms.findIndex((room) => room.guid === booking.details?.slot.roomGuid);
        initialSlideIndex = index !== -1 ? index : 0;
    }

    const onItemChange = (slot: RoomCarouselItemSlot) => {
        onChange({
            date: date ?? new Date(),
            roomGuid: slot.room.guid,
            timeslotGuid: slot.timeslot.guid,
            startTime: slot.timeslot.starttime,
            displayStartTime: slot.timeslot.displaystarttime,
        });
    };

    const items = useMemo(() => {
        return rooms.map((room) => {
            let itemSlots: RoomCarouselItemSlot[] | null = null;
            let itemValue: RoomCarouselItemSlot | null = null;

            if (slots) {
                itemSlots = [];

                // Find all the slots for this room
                const roomSlots = slots.find((rs) => rs.guid === room.guid);
                if (roomSlots) {
                    for (const timeslot of roomSlots.timeslots) {
                        // Compare if this is the booked slot
                        const isBookedSlot =
                            !!date &&
                            !!booking.details &&
                            isSameDay(date, booking.details.slot.date) &&
                            booking.details.slot.roomGuid === room.guid &&
                            booking.details.slot.startTime === timeslot.starttime;

                        itemSlots.push({
                            room,
                            timeslot: timeslot,
                            disabled: !isBookedSlot && timeslot.status !== 'open',
                        });
                    }
                }

                // Find the selected slot in this room
                if (date && value) {
                    itemValue =
                        itemSlots.find((is) => {
                            return (
                                isSameDay(date, value.date) && value.roomGuid === is.room.guid && value.startTime === is.timeslot.starttime
                            );
                        }) ?? null;
                }
            }

            return {
                room: room,
                slots: itemSlots,
                value: itemValue,
            };
        });
    }, [rooms, date, value, slots, booking.details]);

    const classNames = clsx(classes.carousel, className, {
        [classes.disabled]: disabled,
    });

    return (
        <Carousel
            title={formatMessage({ id: 'select-date.choose-room-title', defaultMessage: 'Välj kalasrum' })}
            initialIndex={initialSlideIndex}
            className={classNames}
        >
            {items.map((item) => {
                let imageSrc = ImageRoomFallback;
                if (item.room.image) {
                    imageSrc = imageUrl(item.room.image, 560, 370);
                }

                return (
                    <RoomCarouselItem
                        key={item.room.guid}
                        imageSrc={imageSrc}
                        name={item.room.name}
                        description={item.room.description}
                        maxGuests={item.room.maxguests}
                        slots={item.slots}
                        isLoading={isLoadingSlots}
                        value={item.value}
                        onChange={onItemChange}
                    />
                );
            })}
        </Carousel>
    );
};
