import { useMemo } from 'react';
import { BaseApiClient as BaseIdentityApiClient } from '../api/api-identity.generated';
import { BaseApiClient as BaseBackendApiClient } from '../api/api.generated';
import { Config } from '../config';
import { useAuth } from '../services/auth/AuthProvider';
import { useCountries } from './useCountries';

/**
 * Hook to use any generated api client with locale and authorization headers set.
 *
 * @example Create a {@link PartyBookingApiClient}
 * ```ts
 * const partyBookingApiClient = useApiClient(PartyBookingApiClient);
 * ```
 *
 * @param apiClientType The type of api client to return, e.g. PartyBookingApiClient.
 */
export const useApiClient = <T extends BaseBackendApiClient | BaseIdentityApiClient>(apiClientType: { new (baseUrl: string): T }): T => {
    const { currentCountry } = useCountries();
    const { accessToken } = useAuth();

    return useMemo(() => {
        const client = new apiClientType(Config.baseUrlApi);

        client.locale = `${currentCountry.language}-${currentCountry.code}`;
        client.authorization = accessToken ? `${accessToken.type} ${accessToken.token}` : null;

        return client;
    }, [apiClientType, currentCountry, accessToken]);
};
