import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FunctionComponent } from 'react';
import { FadeIn } from '../../utils/FadeIn/FadeIn';
import { FontAwesomeIcon } from '../FontAwesomeIcon/FontAwesomeIcon';
import { IconButton } from '../IconButton/IconButton';
import { Typography } from '../Typography/Typography';
import classes from './PageContainer.module.scss';

interface PageContainerProps {
    title?: string;
    onBackClick?(): void;
}

/**
 * Design system page container that holds the back button and page title and handles the page width on all screen sizes.
 */
export const PageContainer: FunctionComponent<PageContainerProps> = ({ children, title, onBackClick }) => {
    return (
        <FadeIn enter enterOnMount className={classes.container}>
            {(title !== undefined || onBackClick !== undefined) && (
                <div className={classes.header}>
                    <div className={classes.backButtonWrapper}>
                        {onBackClick && (
                            <IconButton
                                className={classes.backButton}
                                rippleClassName={classes.backButtonRipple}
                                onClick={() => onBackClick()}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} height={23.47} />
                            </IconButton>
                        )}
                    </div>

                    {title && (
                        <Typography variant="display3" component="h1" className={classes.title}>
                            {title}
                        </Typography>
                    )}
                </div>
            )}

            {children}
        </FadeIn>
    );
};
