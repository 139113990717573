import { createContext, FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { LoginIntent } from '../../api/api-identity.generated';
import { ApplicationUserDto } from '../../api/api.generated';
import { useGlobalLoading } from '../../contexts/GlobalLoadingProvider';
import { useCountries } from '../../hooks/useCountries';
import { setAnalyticsUser } from '../../utils/googleAnalytics';
import { AccessToken } from './AccessToken';
import { AuthContextType } from './AuthContextType';
import { AuthenticationResult } from './AuthenticationResult';
import { AuthService } from './AuthService';
import { ClaimType } from './ClaimType';
import { Role } from './Role';

/**
 * @implements AuthContextType
 */
const AuthContext = createContext<AuthContextType | null>(null);

/**
 * Use the auth context. Wrapper to throw an error instead of forcing checks everywhere.
 */
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === null) {
        throw new Error('useAuthentication must be used within a AuthProvider');
    }

    return context;
};

/**
 * Wrapper provider for the auth context.
 */
export const AuthProvider: FunctionComponent = ({ children }) => {
    const location = useLocation();
    const { currentCountry } = useCountries();
    const { incrementLoader, decrementLoader } = useGlobalLoading();

    const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
    const service = useRef<AuthService | null>(null);
    const isAuthenticated = useRef<boolean>(false);

    /**
     * @implements AuthContextType.user
     * @implements AuthContextType.setUser
     */
    const [user, setUser] = useState<ApplicationUserDto | null>(null);

    /**
     * @implements AuthContextType.roles
     */
    const [roles, setRoles] = useState<Role[]>([]);

    /**
     * @implements AuthContextType.accessToken
     */
    const [accessToken, setAccessToken] = useState<AccessToken | null>(null);

    /**
     * Listens for changes in the auth service.
     */
    const userChangeListener = useCallback((result: AuthenticationResult | null) => {
        // Update user in analytics
        setAnalyticsUser(result?.applicationUser ?? null);

        if (!result) {
            unstable_batchedUpdates(() => {
                setUser(null);
                setRoles([]);
                setAccessToken(null);
            });
            return;
        }

        // Roles can be null, a string or an array of strings (roles)
        const roles: Role[] = Array.isArray(result.user.profile[ClaimType.Role])
            ? (result.user.profile[ClaimType.Role] as Role[])
            : typeof result.user.profile[ClaimType.Role] === 'string'
            ? [result.user.profile[ClaimType.Role] as Role]
            : [];

        unstable_batchedUpdates(() => {
            setUser(result.applicationUser);
            setRoles(roles);
            setAccessToken({
                token: result.user.access_token,
                type: result.user.token_type,
            });
        });
    }, []);

    /**
     * Setup and configure the auth service. We need to use an effect as we need to clean up the event handlers in the
     * service using the destroy method when the component is unmounted.
     */
    useEffect(() => {
        incrementLoader();

        const authService = new AuthService();
        authService.changeListeners.add(userChangeListener);

        authService
            .initialize()
            .then(userChangeListener)
            .finally(() => {
                setIsLoadingUser(false);
                decrementLoader();
            });

        service.current = authService;

        return () => {
            setIsLoadingUser(true);

            authService.changeListeners.delete(userChangeListener);
            authService.destroy();

            service.current = null;
        };
    }, [incrementLoader, decrementLoader, userChangeListener]);

    /**
     * @implements AuthContextType.signIn
     */
    const signIn = useCallback(
        (intent: LoginIntent, continuePath?: string) => {
            continuePath ??= location.pathname + location.search;
            service.current?.openLogin(currentCountry.urlProfile, intent, continuePath);
        },
        [currentCountry, location],
    );

    /**
     * @implements AuthContextType.signOut
     */
    const signOut = useCallback(async () => {
        await service.current?.logout();
    }, []);

    /**
     * Effect to check if the user was just signed out. If so, redirect to the onboarding login page.
     */
    useEffect(() => {
        if (isAuthenticated.current && !user) {
            signIn(LoginIntent.SignOut);
        }

        isAuthenticated.current = user !== null;
    }, [user, signIn]);

    return (
        <AuthContext.Provider
            value={{
                signIn,
                signOut,
                setUser,
                user,
                roles,
                accessToken,
            }}
        >
            {!isLoadingUser && children}
        </AuthContext.Provider>
    );
};
