import { CountrySlug, LocaleCode } from './utils/locale';

/** Configuration for a single domain */
export interface DomainConfiguration {
    localeCode: LocaleCode;
    countrySlug: CountrySlug;
    url: string;
}

/** Domain specific language. */
export const domainConfig: Record<string, DomainConfiguration> = {
    /* eslint-disable @typescript-eslint/naming-convention */
    'leoslekland.se': { localeCode: 'sv', countrySlug: 'sweden', url: `${process.env.REACT_APP_BASE_URL_PARTY_SE ?? ''}` },
    'leosleikkimaa.fi': { localeCode: 'fi', countrySlug: 'finland', url: `${process.env.REACT_APP_BASE_URL_PARTY_FI ?? ''}` },
    'leoslegeland.dk': { localeCode: 'da', countrySlug: 'denmark', url: `${process.env.REACT_APP_BASE_URL_PARTY_DK ?? ''}` },
    'leosabenteuerpark.de': { localeCode: 'de', countrySlug: 'germany', url: `${process.env.REACT_APP_BASE_URL_PARTY_DE ?? ''}` },
    'leoslekeland.no': { localeCode: 'nb', countrySlug: 'norway', url: `${process.env.REACT_APP_BASE_URL_PARTY_NO ?? ''}` },
    /* eslint-enable @typescript-eslint/naming-convention */
};

/** The fallback language when nothing matches. */
export const fallbackConfig: DomainConfiguration = {
    localeCode: 'sv',
    countrySlug: 'sweden',
    url: `${process.env.REACT_APP_BASE_URL_PARTY_SE ?? ''}`,
};

// Figure out the current config
let currentConfig = fallbackConfig;

for (const [domain, config] of Object.entries(domainConfig)) {
    if (window.location.host.includes(domain)) {
        currentConfig = config;
    }
}

/** The current domain config */
export const currentDomainConfig = currentConfig;
