import clsx from 'clsx';
import { Typography } from 'components/common/Typography/Typography';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './PartyLengthPicker.module.scss';

export interface PartyLengthPickerProps {
    value: number | null;
    maxValue: number;
    onChange(length: number): void;
}

export const PartyLengthPicker: FunctionComponent<PartyLengthPickerProps> = ({ value, maxValue, onChange }) => {
    const maxHours = Math.floor(maxValue / 60);
    const items = [];
    for (var hours = 2; hours <= maxHours; hours++) {
        const minutes = hours * 60;
        items.push({ hours, minutes, selected: minutes === value });
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>
                <Typography variant="title5" component="span">
                    <FormattedMessage id="select-party-length.title" defaultMessage="Välj antal timmar" />
                </Typography>
            </div>
            <div className={classes.container}>
                <Typography variant="title5" component="span">
                    <FormattedMessage id="select-party-length.sub-title" defaultMessage="Hur länge vill du att kalaset varar?" />
                </Typography>
                <div className={classes.buttons}>
                    {items.map((item) => {
                        const buttonClasses = clsx(classes.option, {
                            [classes.selected]: item.selected,
                        });
                        return (
                            <button key={item.hours} className={buttonClasses} onClick={() => onChange(item.minutes)}>
                                <Typography variant="body" component="span">
                                    <FormattedMessage
                                        id="select-party-length.length-option"
                                        defaultMessage="{length} timmar"
                                        values={{ length: item.hours }}
                                    />
                                </Typography>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
