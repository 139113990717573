import { parseISO } from 'date-fns';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApi } from '../contexts/ApiProvider';
import { useGlobalLoading } from '../contexts/GlobalLoadingProvider';
import { InviteCard } from '../features/invite/InviteCard/InviteCard';
import { InviteCardDialog } from '../features/invite/InviteCardDialog/InviteCardDialog';
import { InviteMemberCard } from '../features/invite/InviteMemberCard/InviteMemberCard';
import useApiError from '../hooks/useApiError';
import { Guest } from '../model';
import { Invitation } from '../model/invitation';
import ApiClient from '../utils/ApiClient';
import { HttpStatusCode } from '../utils/HttpStatusCode';
import { Error } from './Error';
import { NotFound } from './NotFound';

interface InviteParams {
    id: string;
    key: string;
}

interface InviteParams {
    id: string;
    key: string;
}

export const Invite: FunctionComponent = () => {
    const [searchParams] = useSearchParams();
    const { client } = useApi();
    const { incrementLoader, decrementLoader } = useGlobalLoading();

    const { apiError, setApiError, clearApiError, toastApiError } = useApiError();
    const [invitation, setInvitation] = useState<Invitation | undefined>();
    const [guestMessage, setGuestMessage] = useState<string>('');
    const [guestMessageOpen, setGuestMessageOpen] = useState<boolean>(false);
    const [guestAllergyMessage, setGuestAllergyMessage] = useState<string>('');
    const [guestAllergyMessageOpen, setGuestAllergyMessageOpen] = useState<boolean>(false);
    const [answer, setAnswer] = useState<boolean | undefined>();

    const params: InviteParams | null = useMemo(() => {
        const id = searchParams.get('invitationid');
        const key = searchParams.get('invitiationkey');

        return id && key ? { id, key } : null;
    }, [searchParams]);

    const loadInvite = () => {
        const { token, cancel } = ApiClient.createCancelToken();
        setInvitation(undefined);
        clearApiError();

        if (params) {
            incrementLoader();
            client.getInvite(params.id, params.key, token).then(setInvitation).catch(setApiError).finally(decrementLoader);
        }

        return cancel;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadInvite, [params]);

    useEffect(() => {
        setGuestMessage(invitation?.guestmessage ?? '');
        setGuestMessageOpen(!!invitation?.guestmessage);
        setGuestAllergyMessage(invitation?.guestallergymessage ?? '');
        setGuestAllergyMessageOpen(!!invitation?.guestallergymessage);

        if (invitation && ['Accepted', 'Cancelled'].includes(invitation.status)) {
            setAnswer(invitation.status === 'Accepted');
        } else {
            setAnswer(undefined);
        }
    }, [invitation]);

    const onAnswerClick = async (accepted: boolean) => {
        if (!params) {
            return;
        }

        incrementLoader();

        let response: Guest | undefined;
        try {
            response = await client.answerInvite({
                invitationid: params.id,
                invitationkey: params.key,
                message: guestMessageOpen && guestMessage ? guestMessage : '',
                allergymessage: accepted && guestAllergyMessageOpen && guestAllergyMessage ? guestAllergyMessage : '',
                status: accepted ? 'Accepted' : 'Cancelled',
            });
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();

        if (invitation && response) {
            setInvitation({
                ...invitation,
                status: response.status,
                guestallergymessage: response.guestallergymessage,
                guestmessage: response.guestmessage,
            });
        }
    };

    if (!params) {
        return <NotFound />;
    }

    if (apiError) {
        const statusCode = apiError.getStatusCode();
        if (statusCode && [HttpStatusCode.NotFound, HttpStatusCode.Gone].includes(statusCode)) {
            // Hide the retry button if the invite isn't found or if the booking is cancelled.
            return <Error error={apiError} />;
        }

        return <Error error={apiError} onRetryClick={loadInvite} />;
    }

    if (!invitation) {
        return null;
    }

    return (
        <>
            <InviteCardDialog open backgroundImage={invitation.backgroundimage}>
                <InviteCard
                    hostNames={invitation.host.children.map((child) => child.name)}
                    guestName={invitation.guestinfo.name}
                    roomName={invitation.room.name}
                    playgroundName={invitation.playgroundname}
                    date={parseISO(invitation.partydate)}
                    startTime={invitation.starttime}
                    endTime={invitation.stopdate}
                    invitationMessage={invitation.invitationmessage}
                    guestMessage={guestMessage}
                    guestMessageOpen={guestMessageOpen}
                    guestAllergyMessage={guestAllergyMessage}
                    guestAllergyMessageOpen={guestAllergyMessageOpen}
                    answer={answer}
                    onMessageChange={invitation.status !== 'Cancelled' ? setGuestMessage : undefined}
                    onMessageOpenChange={setGuestMessageOpen}
                    onAllergiesChange={invitation.status !== 'Cancelled' ? setGuestAllergyMessage : undefined}
                    onAllergiesOpenChange={setGuestAllergyMessageOpen}
                    onAnswerClick={onAnswerClick}
                    onChangeAnswerClick={() => setAnswer(undefined)}
                    streetAdress={invitation.streetadress}
                    companyName={invitation.companyname}
                    playcentertype={invitation.playcentertype}
                />

                {answer !== undefined && <InviteMemberCard />}
            </InviteCardDialog>
        </>
    );
};
