import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { ApiError } from 'utils';
import { ErrorView } from '../components/common/ErrorView/ErrorView';
import { PageContainer } from '../components/common/PageContainer/PageContainer';
import { useAuth } from '../services/auth/AuthProvider';
import { HttpStatusCode } from '../utils/HttpStatusCode';

interface PageErrorProps {
    error: ApiError;
    onRetryClick?(): void;
}

export const Error: FunctionComponent<PageErrorProps> = ({ error, onRetryClick }) => {
    const { formatMessage } = useIntl();
    const { user } = useAuth();

    // We're assuming that a 400 or 401 means that the user needs to authenticate (if we do not have an authenticated user).
    // The backend will send a 400 response when a request requires authentication, and when the old party booking is still live, we cannot
    // change this to a 401.
    const showLoginButton = !user && [HttpStatusCode.BadRequest, HttpStatusCode.Unauthorized].includes(error.getStatusCode() ?? 0);

    let message = error.getMessage(formatMessage({ id: 'general.error-unexpected', defaultMessage: 'Ett oväntat fel har inträffat.' }));
    if (showLoginButton) {
        message = formatMessage({ id: 'general.error-login-required', defaultMessage: 'Du måste logga in för att kunna se denna sida.' });
    }

    return (
        <PageContainer>
            <ErrorView
                title={formatMessage({ id: 'general.error-title', defaultMessage: 'Hoppsan!' })}
                message={message}
                showLoginButton={showLoginButton}
                onRetryClick={onRetryClick}
            />
        </PageContainer>
    );
};
