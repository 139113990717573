import clsx from 'clsx';
import { FunctionComponent, MouseEventHandler } from 'react';
import { Ripple } from '../../utils/Ripple/Ripple';
import { Typography } from '../Typography/Typography';
import classes from './ComboButton.module.scss';

interface ComboButtonProps {
    color?: 'grandparentGrey50' | 'transparent';
    component?: 'button' | 'div';
    disabled?: boolean;
    overlay?: boolean;
    className?: string;
    onClick?: MouseEventHandler;
}

/**
 * Design system Combo Button with support for actions and text.
 * If the component is set to a <div> the disabled property will take no effect.
 *
 * Do not place any interactive elements inside the ComboButton if it's component is set to button.
 */
export const ComboButton: FunctionComponent<ComboButtonProps> = ({
    color = 'grandparentGrey50',
    component = 'button',
    disabled,
    overlay,
    className,
    onClick,
    children,
}) => {
    const classNames = clsx(classes.container, classes[color], className, {
        [classes.overlay]: overlay,
    });

    if (component === 'button') {
        return (
            <Ripple disabled={disabled} className={classes.ripple}>
                {(props, ripples) => (
                    <button type="button" disabled={disabled} className={classNames} onClick={onClick} {...props}>
                        {ripples}
                        {children}
                    </button>
                )}
            </Ripple>
        );
    }

    return (
        <div className={classNames} onClick={onClick}>
            {children}
        </div>
    );
};

/**
 * The content for the ComboButton.
 */
export const ComboButtonContent: FunctionComponent = ({ children }) => {
    return <span className={classes.content}>{children}</span>;
};

/**
 * The title for the ComboButton.
 */
export const ComboButtonTitle: FunctionComponent = ({ children }) => {
    return (
        <Typography component="span" variant="title4" className={classes.title}>
            {children}
        </Typography>
    );
};

/**
 * The top text for the ComboButton.
 */
export const ComboButtonTopText: FunctionComponent = ({ children }) => {
    return <span className={classes.topText}>{children}</span>;
};

/**
 * The bottom text for the ComboButton.
 */
export const ComboButtonBottomText: FunctionComponent = ({ children }) => {
    return <span className={classes.bottomText}>{children}</span>;
};

interface ComboButtonActionProps {
    color?: 'ballPitBlue' | 'iceCubeBlue' | 'dinosaurGreen';
}

/**
 * The wrapper for the combo button action.
 */
export const ComboButtonAction: FunctionComponent<ComboButtonActionProps> = ({ color = 'ballPitBlue', children }) => {
    const className = clsx(classes.action, classes[color]);

    return <span className={className}>{children}</span>;
};

interface ExpandedComboButtonProps {
    className?: string;
}

/**
 * The wrapper for the ExpandedComboButton. Place the ComboButton and the content you want inside this component.
 */
export const ExpandedComboButton: FunctionComponent<ExpandedComboButtonProps> = ({ className, children }) => {
    return <div className={clsx(classes.expanded, className)}>{children}</div>;
};
