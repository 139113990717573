import daMessages from '../assets/lang/da.json';
import deMessages from '../assets/lang/de.json';
import fiMessages from '../assets/lang/fi.json';
import nbMessages from '../assets/lang/nb.json';
import svMessages from '../assets/lang/sv.json';

/** All available locales */
export const locales = ['sv', 'fi', 'da', 'de', 'nb'] as const;

/** Union type of all available locale codes */
export type LocaleCode = typeof locales[number];

/** All available country slugs */
export const countrySlugs = ['sweden', 'finland', 'denmark', 'germany', 'norway'] as const;

/** Union type of all available country slugs */
export type CountrySlug = typeof countrySlugs[number];

/** The default locale of the application. This is the language all defaultMessages are specified in. */
export const defaultLocale: LocaleCode = 'sv';

/** The messages for each locale */
export const localeMessages: Record<LocaleCode, Record<string, string>> = {
    sv: svMessages,
    fi: fiMessages,
    da: daMessages,
    de: deMessages,
    nb: nbMessages,
};
