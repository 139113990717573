import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { ErrorView } from '../components/common/ErrorView/ErrorView';
import { PageContainer } from '../components/common/PageContainer/PageContainer';

export const NotFound: FunctionComponent = () => {
    const { formatMessage } = useIntl();

    return (
        <PageContainer>
            <ErrorView
                title={formatMessage({ id: 'general.error-not-found-title', defaultMessage: '404' })}
                message={formatMessage({ id: 'general.error-not-found-text', defaultMessage: 'Sidan kunde inte hittas.' })}
            />
        </PageContainer>
    );
};
