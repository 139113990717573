import clsx from 'clsx';
import { FunctionComponent } from 'react';
import classes from './Switch.module.scss';

interface SwitchProps {
    on?: boolean;
    disabled?: boolean;
    className?: string;
    onChange?(on: boolean): void;
}

/**
 * Design system Switch that can be turned on or off.
 */
export const Switch: FunctionComponent<SwitchProps> = ({ on, disabled, className, onChange }) => {
    return (
        <button
            type="button"
            disabled={disabled}
            className={clsx(classes.container, className, { [classes.containerOn]: on })}
            onClick={() => onChange?.(!on)}
        >
            <span className={classes.track} />
            <span className={classes.thumb} />
        </button>
    );
};
