import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { parse, parseISO } from 'date-fns';
import { FunctionComponent } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { IconBalloons } from '../../../assets/icons';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonBottomText,
    ComboButtonContent,
    ComboButtonTitle,
    ExpandedComboButton,
} from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { PartyBookingListPartyItemDto } from '../../../model/myBookings';
import { AttendanceList } from '../../common/AttendenceList';
import classes from './PartyListItem.module.scss';

interface PartyListItemProps {
    item: PartyBookingListPartyItemDto;
    onClick(item: PartyBookingListPartyItemDto): void;
}

/**
 * List item for a single booking.
 */
const PartyListItem: FunctionComponent<PartyListItemProps> = ({ item, onClick }) => {
    const { formatTime } = useIntl();
    const partyDate = parseISO(item.partydate);
    const startTime = parse(item.starttime, 'HH:mm', new Date());
    const formattedStartTime = formatTime(startTime);

    return (
        <ExpandedComboButton>
            <ComboButton onClick={() => onClick(item)}>
                <ComboButtonAction>
                    <IconBalloons width="40" />
                </ComboButtonAction>

                <ComboButtonContent>
                    <ComboButtonTitle>
                        <FormattedDate value={partyDate} weekday="long" day="numeric" month="long" />
                    </ComboButtonTitle>
                    <ComboButtonBottomText>
                        <FormattedMessage
                            id="my-bookings.item-time"
                            defaultMessage="Klockan {time} i {playground}"
                            values={{ time: formattedStartTime, playground: item.playgroundname }}
                        />
                    </ComboButtonBottomText>
                </ComboButtonContent>

                <ComboButtonAction>
                    <FontAwesomeIcon icon={faChevronRight} height={23.46} />
                </ComboButtonAction>
            </ComboButton>

            <div className={classes.info}>
                <AttendanceList
                    noAnswer={item.invited.noanswer}
                    accepted={item.invited.accepted}
                    declined={item.invited.cancelled}
                    birthdayKids={item.invited.birthdaykids}
                />
            </div>
        </ExpandedComboButton>
    );
};

export default PartyListItem;
