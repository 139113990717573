import { ChangeEvent, FunctionComponent } from 'react';
import { Switch } from '../../../components/common/Switch/Switch';
import { Collapse } from '../../../components/utils/Collapse/Collapse';
import classes from './InviteMessageInput.module.scss';

interface InviteMessageInputProps {
    className?: string;
    title: string;
    value?: string;
    open?: boolean;
    onChange?(value: string): void;
    onOpenChange?(value: boolean): void;
}

export const InviteMessageInput: FunctionComponent<InviteMessageInputProps> = ({
    className,
    title,
    value,
    open,
    onChange,
    onOpenChange,
}) => {
    const containerClassNames: string[] = [];
    if (className) {
        containerClassNames.push(className);
    }

    const onTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event.target.value);
    };

    return (
        <div className={containerClassNames.join(' ')}>
            <div className={classes.content}>
                <div className={classes.title}>{title}</div>
                <Switch on={open} onChange={onOpenChange} disabled={!onOpenChange} className={classes.switch} />
            </div>

            <Collapse open={open ?? false}>
                <div className={classes.textAreaWrapper}>
                    <textarea aria-label={title} className={classes.textArea} onChange={onTextAreaChange} value={value} rows={2} />
                </div>
            </Collapse>
        </div>
    );
};
