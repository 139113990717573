import { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertText } from '../../../components/common/Alert/Alert';
import { AlertDialog } from '../../../components/common/AlertDialog/AlertDialog';
import { Button } from '../../../components/common/Button/Button';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { ComboTextAreaField } from '../../../components/form/ComboTextAreaField/ComboTextAreaField';
import { useApi } from '../../../contexts/ApiProvider';
import { useBooking } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import useApiError from '../../../hooks/useApiError';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { Stage } from '../../../model/stage';
import classes from './CancelBooking.module.scss';

export const CancelBooking: FunctionComponent = () => {
    const booking = useBooking();
    const navigate = useNavigate();
    const { locale, formatMessage } = useIntl();
    const { client } = useApi();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { openStage } = useBookingParams();
    const { toastApiError } = useApiError();

    const [guestMessage, setGuestMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isCancellationCompleted, setCancellationCompleted] = useState(false);

    const onBackClick = () => {
        if (isCancellationCompleted) {
            navigate(`/${locale}`);
        } else {
            openStage(Stage.BookingOverview, booking.details);
        }
    };

    async function onCancelDialogClose(confirmed: boolean) {
        setDialogOpen(false);

        if (!confirmed || !booking.details) {
            return;
        }

        incrementLoader();

        let success = true;
        try {
            await client.cancelBooking(booking.details.guid, guestMessage);
        } catch (error) {
            success = false;
            toastApiError(error);
        }

        decrementLoader();

        if (success) {
            setCancellationCompleted(true);
            booking.clear();
        }
    }

    return (
        <>
            <AlertDialog
                type="warning"
                open={dialogOpen}
                className={classes.alertDialog}
                text={formatMessage({
                    id: 'cancel-booking.confirm-alert-text',
                    defaultMessage: 'Är du säker på att du vill avboka kalaset?',
                })}
            >
                <Button color="minionYellow" onClick={() => onCancelDialogClose(true)}>
                    <FormattedMessage id="general.yes" defaultMessage="Ja" />
                </Button>

                <Button color="grandparentGrey900" onClick={() => onCancelDialogClose(false)}>
                    <FormattedMessage id="general.cancel" defaultMessage="Avbryt" />
                </Button>
            </AlertDialog>

            <PageContainer title={formatMessage({ id: 'cancel-booking.title', defaultMessage: 'Avboka kalas' })} onBackClick={onBackClick}>
                {isCancellationCompleted ? (
                    <Alert>
                        <AlertText>
                            <FormattedMessage
                                id="cancel-booking.cancelled-text"
                                defaultMessage="Ditt kalas är avbokat. En bekräftelse kommer på e-post!"
                            />
                        </AlertText>
                    </Alert>
                ) : (
                    <ComboTextAreaField
                        title={formatMessage({ id: 'cancel-booking.message-title', defaultMessage: 'Meddelande' })}
                        text={formatMessage({
                            id: 'cancel-booking.message-text',
                            defaultMessage: 'Lägg till ett meddelande som kommer skickas till inbjudna gäster.',
                        })}
                        placeholder={formatMessage({
                            id: 'cancel-booking.message-placeholder',
                            defaultMessage: 'Meddelande till dina gäster',
                        })}
                        value={guestMessage}
                        onChange={(event) => setGuestMessage(event.target.value)}
                        className={classes.comboTextArea}
                    />
                )}

                <PageButtons alignBottom>
                    {isCancellationCompleted ? (
                        <Button color="minionYellow" onClick={onBackClick}>
                            <FormattedMessage id="cancel-booking.new-booking-button" defaultMessage="Ny bokning" />
                        </Button>
                    ) : (
                        <>
                            <Button color="minionYellow" onClick={() => setDialogOpen(true)}>
                                <FormattedMessage id="cancel-booking.cancel-button" defaultMessage="Avboka kalaset" />
                            </Button>

                            <Button color="transparent" onClick={onBackClick}>
                                <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                            </Button>
                        </>
                    )}
                </PageButtons>
            </PageContainer>
        </>
    );
};
