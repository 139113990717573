import { useCountries } from 'hooks/useCountries';
import { FunctionComponent } from 'react';
import { Dialog } from '../../utils/Dialog/Dialog';
import { FadeInImage } from '../../utils/FadeInImage/FadeInImage';
import LeosHopLopImageLogo from './leos-hop-lop-logo-small.png';
import classes from './LoadingDialog.module.scss';
import ImageLogo from './logo.png';

interface LoadingDialogProps {
    open: boolean;
}

/**
 * Used to display a loading indicator above everything else in the application.
 * The dialog is rendered in a separate element than the rest of the app using portals so z-index is not necessary.
 *
 * Usage:
 * <LoadingDialog open={loading} />
 */
export const LoadingDialog: FunctionComponent<LoadingDialogProps> = ({ open }) => {
    const { currentCountry } = useCountries();

    return (
        <Dialog open={open}>
            <div className={classes.content}>
                <FadeInImage className={classes.logo} src={currentCountry.code === 'FI' ? LeosHopLopImageLogo : ImageLogo} />
            </div>
        </Dialog>
    );
};
