import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { CarouselItem } from '../../../components/common/Carousel/CarouselItem';
import { CircularProgress } from '../../../components/common/CircularProgress/CircularProgress';
import { Tooltip } from '../../../components/common/Tooltip/Tooltip';
import { Typography } from '../../../components/common/Typography/Typography';
import { Room, Timeslot } from '../../../model';
import classes from './RoomCarouselItem.module.scss';

export interface RoomCarouselItemSlot {
    room: Room;
    timeslot: Timeslot;
    disabled: boolean;
}

interface RoomCarouselItemProps {
    imageSrc: string;
    name: string;
    description: string | null;
    maxGuests: number;
    slots: RoomCarouselItemSlot[] | null;
    isLoading: boolean;
    value: RoomCarouselItemSlot | null;
    onChange(slot: RoomCarouselItemSlot): void;
}

export const RoomCarouselItem: FunctionComponent<RoomCarouselItemProps> = ({
    imageSrc,
    name,
    description,
    maxGuests,
    slots,
    isLoading,
    value,
    onChange,
}) => {
    const slotsWrapperClassNames = clsx(classes.slotsWrapper, {
        [classes.slotsWrapperLoading]: isLoading,
        [classes.slotsWrapperEmpty]: !isLoading && (slots === null || slots.length === 0),
    });

    return (
        <CarouselItem imageSrc={imageSrc}>
            <div className={classes.content}>
                <div className={classes.titleWrapper}>
                    <Typography variant="title4" component="span" className={classes.title}>
                        {name}
                    </Typography>

                    <span className={classes.titleMaxGuests}>
                        <FormattedMessage id="select-date.room-max-guests" defaultMessage="(Max {maxGuests} barn)" values={{ maxGuests }} />
                    </span>
                </div>

                {description && <Typography className={classes.description}>{description}</Typography>}

                <div className={slotsWrapperClassNames}>
                    {isLoading ? (
                        <CircularProgress thickness={3} size={40} />
                    ) : !slots ? (
                        <Typography>
                            <FormattedMessage id="select-date.select-date-text" defaultMessage="Välj ett datum för att se lediga tider." />
                        </Typography>
                    ) : slots.length === 0 ? (
                        <Typography>
                            <FormattedMessage id="select-date.no-available-slots" defaultMessage="Inga lediga tider." />
                        </Typography>
                    ) : (
                        <div className={classes.slots}>
                            {slots.map((slot) => {
                                const isSelectedRoom = slot.room.guid === value?.room.guid;
                                const isSelectedTimeslot = slot.timeslot.guid === value?.timeslot.guid;

                                const classNames = clsx(classes.slot, {
                                    [classes.slotSelected]: isSelectedRoom && isSelectedTimeslot,
                                });

                                return (
                                    <Tooltip key={slot.timeslot.guid} content={slot.timeslot.comment}>
                                        <button
                                            type="button"
                                            disabled={slot.disabled}
                                            className={classNames}
                                            onClick={() => onChange(slot)}
                                        >
                                            <Typography component="span">
                                                {slot.timeslot.displaystarttime}
                                                {slot.timeslot.comment && <span className={classes.asterisk}>*</span>}
                                            </Typography>
                                        </button>
                                    </Tooltip>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </CarouselItem>
    );
};
