import clsx from 'clsx';
import { FunctionComponent, HTMLAttributeAnchorTarget, MouseEventHandler } from 'react';
import { Ripple } from '../../utils/Ripple/Ripple';
import classes from './IconButton.module.scss';

interface IconButtonProps {
    component?: 'button' | 'div' | 'a';
    target?: HTMLAttributeAnchorTarget;
    href?: string;
    title?: string;
    disabled?: boolean;
    rippleClassName?: string;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

/**
 * Design system IconButton that provides a circular ripple around the icon.
 */
export const IconButton: FunctionComponent<IconButtonProps> = ({
    component = 'button',
    target,
    href,
    title,
    disabled,
    rippleClassName,
    className,
    onClick,
    children,
}) => {
    const classNames = clsx(classes.container, className);

    return (
        <Ripple disabled={disabled} className={clsx(!disabled && rippleClassName)}>
            {(props, ripples) =>
                component === 'a' ? (
                    <a href={href} target={target} title={title} className={classNames} onClick={onClick} {...props}>
                        {ripples}
                        <span className={classes.content}>{children}</span>
                    </a>
                ) : (
                    <button title={title} type="button" disabled={disabled} className={classNames} onClick={onClick} {...props}>
                        {ripples}
                        <span className={classes.content}>{children}</span>
                    </button>
                )
            }
        </Ripple>
    );
};
