import clsx from 'clsx';
import { CSSProperties, FunctionComponent, useRef } from 'react';
import { useTransition } from '../../../hooks/useTransition';
import classes from './Collapse.module.scss';

interface CollapseProps {
    open: boolean;
}

/**
 * Hides the children by collapsing them with a transition effect.
 *
 * Usage:
 *
 * <Collapse open={isOpen}>
 *     Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, aspernatur consectetur error facere fugit, itaque iusto
 *     molestiae odit optio perferendis perspiciatis possimus quae, soluta tempore totam ullam veniam voluptate voluptates.
 * </Collapse>
 */
export const Collapse: FunctionComponent<CollapseProps> = ({ open, children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const transitionState = useTransition(containerRef, open, 300);

    const style: CSSProperties = {};

    switch (transitionState) {
        case 'enter':
        case 'exiting':
            style.height = '0';
            break;
        case 'entering':
        case 'exit':
            const height = containerRef.current?.scrollHeight;
            style.height = height ? `${height}px` : 'auto';
            break;
        case 'entered':
            style.height = 'auto';
            break;
        case 'exited':
            style.height = '0';
            break;
    }

    return (
        <div ref={containerRef} style={style} className={clsx(classes.container, classes[transitionState])}>
            {children}
        </div>
    );
};
