import { domainConfig } from './domain-config';
import { countrySlugs } from './utils/locale';

/**
 * Applies redirects when we change urls.
 */
export function redirects(): boolean {
    if (removeCountryFromSlugs()) {
        return true;
    }

    if (correctDomainForLocale()) {
        return true;
    }

    return false;
}

/**
 * Redirects from an url with a country slug to the correct domain without the country in the slug.
 */
function removeCountryFromSlugs(): boolean {
    const url = new URL(window.location.href);
    const countryRegex = new RegExp(`/party/(?<locale>[a-z]{2})/(?<country>${countrySlugs.join('|')})(?<path>/.*)?`, 'i');

    const match = countryRegex.exec(url.pathname);
    if (!match) {
        return false;
    }

    // Find the matching domain config
    const config = Object.values(domainConfig).find((c) => c.countrySlug === match.groups?.country);
    if (!config) {
        return false;
    }

    const configUrl = new URL(config.url);

    url.host = configUrl.host;
    url.pathname = `/party/${config.localeCode}${match.groups?.path || ''}`;

    document.location.replace(url);
    return true;
}

/**
 * Redirects to the correct domain for the specified locale code.
 */
function correctDomainForLocale(): boolean {
    const url = new URL(window.location.href);
    const localeRegex = new RegExp(`/party/(?<locale>[a-z]{2})`, 'i');

    const match = localeRegex.exec(url.pathname);
    if (!match) {
        return false;
    }

    // Find the matching domain config
    const config = Object.values(domainConfig).find((c) => c.localeCode === match.groups?.locale);
    if (!config) {
        return false;
    }

    // Ensure that we're at the correct url for the locale.
    const configUrl = new URL(config.url);
    if (configUrl.host === url.host) {
        return false;
    }

    // Override host from domain config
    url.host = configUrl.host;

    document.location.replace(url);
    return true;
}
