import { Country, Playground, Playgrounds } from 'model';
import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useApiError from '../hooks/useApiError';
import { Error } from '../routes/Error';
import ApiClient from '../utils/ApiClient';
import { CountrySlug } from '../utils/locale';
import { useApi } from './ApiProvider';
import { useGlobalLoading } from './GlobalLoadingProvider';

export interface Location {
    country: Country;
    playground: Playground;
}

interface LocationService {
    getCountries: () => Country[];
    getPlaygrounds: (countrySlug: CountrySlug) => Playground[];
    getCountry: (slug: string) => Country | undefined;
    getPlayground: (slug: string) => Playground | undefined;
}

const LocationContext = createContext<LocationService>({} as LocationService);

export function useLocation() {
    return useContext(LocationContext);
}

export const LocationProvider: FunctionComponent = ({ children }) => {
    const { locale } = useIntl();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { client } = useApi();

    const [countries, setCountries] = useState<Country[] | undefined>();
    const [playgrounds, setPlaygrounds] = useState<Playgrounds | undefined>();
    const { apiError, setApiError, clearApiError } = useApiError();

    const fetchData = () => {
        const { token, cancel } = ApiClient.createCancelToken();
        setCountries(undefined);
        setPlaygrounds(undefined);
        clearApiError();
        incrementLoader();

        Promise.all([client.getCountries(token), client.getPlaygrounds(token)])
            .then(([countries, playgrounds]) => {
                setCountries(countries);
                setPlaygrounds(playgrounds);
            })
            .catch(setApiError)
            .finally(decrementLoader);

        return cancel;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchData, []);

    const getCountries = (): Country[] => {
        return countries ?? [];
    };

    const getPlaygrounds = (countrySlug: CountrySlug): Playground[] => {
        const playgroundCountry = playgrounds?.items.find((c) => c.slug === countrySlug);
        if (!playgroundCountry) {
            return [];
        }

        const pgs: Playground[] = [];
        for (const region of playgroundCountry.children) {
            pgs.push(...region.children);
        }

        return pgs.sort((a, b) => a.name.localeCompare(b.name, locale));
    };

    const getCountry = (slug: string): Country | undefined => {
        return countries?.find((country) => country.slug === slug);
    };

    const getPlayground = (slug: string): Playground | undefined => {
        if (!playgrounds) {
            return undefined;
        }

        for (const country of playgrounds.items) {
            for (const region of country.children) {
                const playground = region.children.find((pg) => pg.slug === slug);
                if (playground) {
                    return playground;
                }
            }
        }

        return undefined;
    };

    const getLocation = (playgroundGuid: string): Location | undefined => {
        if (!countries || !playgrounds) {
            return undefined;
        }

        for (const playgroundCountry of playgrounds.items) {
            const country = countries.find((c) => c.guid === playgroundCountry.guid);

            for (const region of playgroundCountry.children) {
                const playground = region.children.find((pg) => pg.guid === playgroundGuid);
                if (country && playground) {
                    return { country, playground };
                }
            }
        }

        return undefined;
    };

    const value = {
        getCountries,
        getPlaygrounds,
        getCountry,
        getPlayground,
        getLocation,
    };

    if (apiError) {
        return <Error error={apiError} onRetryClick={fetchData} />;
    }

    if (!countries || !playgrounds) {
        return null;
    }

    return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
};
