import { FunctionComponent, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import classes from './InviteInfoRow.module.scss';

interface InviteInfoRowProps {
    className?: string;
    icon: ReactNode;
    title: string;
    text: string;
    showArrivalInfo?: boolean;
    extraText?: string;
}

export const InviteInfoRow: FunctionComponent<InviteInfoRowProps> = ({ className, icon, title, text, showArrivalInfo, extraText }) => {
    const { formatMessage, messages } = useIntl();
    const containerClassNames = [classes.container];
    if (className) {
        containerClassNames.push(className);
    }

    // Check to see if the translation key has a value
    const arrivalInfo = messages['invite-card.arrival-info']
        ? formatMessage({
              id: 'invite-card.arrival-info',
              defaultMessage: '',
          })
        : undefined;

    showArrivalInfo = arrivalInfo ? showArrivalInfo : false;

    return (
        <div className={containerClassNames.join(' ')}>
            <div className={classes.icon}>{icon}</div>

            <div className={classes.content}>
                <div className={classes.title}>{title}</div>
                <div>{text}</div>
                {extraText && <div>{extraText}</div>}
                {showArrivalInfo && <div>{arrivalInfo}</div>}
            </div>
        </div>
    );
};
