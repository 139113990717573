import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { trackPageView } from '../../utils/googleAnalytics';

/**
 * Tracks a page view when the current route changes.
 */
export function PageViewTracker() {
    const { pathname } = useLocation();

    useEffect(() => {
        const url = new URL(pathname, window.location.origin);
        url.search = window.location.search;

        trackPageView(url.toString(), window.document.title);
    }, [pathname]);

    return null;
}
