import clsx from 'clsx';
import { FunctionComponent, ReactNode } from 'react';
import classes from './Chip.module.scss';

interface ChipProps {
    className?: string;
    children: ReactNode;
}

export const Chip: FunctionComponent<ChipProps> = ({ className, children }) => {
    return (
        <span className={clsx(classes.container, className)}>
            <span className={classes.content}>{children}</span>
        </span>
    );
};
