import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonBottomText,
    ComboButtonContent,
    ComboButtonTitle,
    ExpandedComboButton,
} from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Typography } from '../../../components/common/Typography/Typography';
import { Guest } from '../../../model';
import classes from './GuestCard.module.scss';

interface GuestCardProps {
    guest: Guest;
    className?: string;
    onClick(guest: Guest): void;
}

/**
 * Displays the status and any messages for a single invited guest.
 */
export const GuestCard: FunctionComponent<GuestCardProps> = ({ guest, className, onClick }) => {
    const { formatMessage } = useIntl();

    let statusText: string;
    let statusClassName: string | undefined;

    let status = guest.status.toLowerCase();
    if (status === 'accepted') {
        statusText = formatMessage({ id: 'edit-guests.status-accepted', defaultMessage: 'Tackat ja' });
        statusClassName = classes.statusAccepted;
    } else if (status === 'cancelled') {
        statusText = formatMessage({ id: 'edit-guests.status-declined', defaultMessage: 'Tackat nej' });
        statusClassName = classes.statusDeclined;
    } else {
        statusText = formatMessage({ id: 'edit-guests.status-unanswered', defaultMessage: 'Obesvarad' });
    }

    return (
        <ExpandedComboButton className={className}>
            <ComboButton onClick={() => onClick(guest)}>
                <ComboButtonContent>
                    <ComboButtonTitle>{guest.guestname}</ComboButtonTitle>

                    <ComboButtonBottomText>
                        <span className={statusClassName}>{statusText}</span>
                    </ComboButtonBottomText>
                </ComboButtonContent>

                <ComboButtonAction>
                    <FontAwesomeIcon icon={faChevronRight} height={23.46} />
                </ComboButtonAction>
            </ComboButton>

            {(guest.guestallergymessage || guest.guestmessage) && (
                <Typography component="div" className={classes.messages}>
                    {guest.guestmessage && (
                        <div className={classes.message}>
                            <FormattedMessage
                                id="edit-guests.guest-message"
                                defaultMessage="<strong>Meddelande från gästen:</strong> {message}"
                                values={{
                                    strong: (...chunks: any[]) => <strong>{chunks}</strong>,
                                    message: guest.guestmessage,
                                }}
                            />
                        </div>
                    )}

                    {guest.guestallergymessage && (
                        <div className={classes.message}>
                            <FormattedMessage
                                id="edit-guests.guest-message-allergies"
                                defaultMessage="<strong>Allergier:</strong> {allergies}"
                                values={{
                                    strong: (...chunks: any[]) => <strong>{chunks}</strong>,
                                    allergies: guest.guestallergymessage,
                                }}
                            />
                        </div>
                    )}
                </Typography>
            )}
        </ExpandedComboButton>
    );
};
