import { PartyBookingApiClient, PartyLocationConfigurationDto } from 'api/api.generated';
import { useGlobalLoading } from 'contexts/GlobalLoadingProvider';
import { useApiClient } from 'hooks/useApiClient';
import useApiError from 'hooks/useApiError';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/common/Button/Button';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { useBooking } from '../../../contexts/BookingProvider';
import { BookingSummary } from '../../../features/confirm-booking/BookingSummary/BookingSummary';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { Stage } from '../../../model/stage';
import { ApiError } from '../../../utils';
import classes from './BookingDetails.module.scss';

export const BookingDetails: FunctionComponent = () => {
    const booking = useBooking();
    const { formatMessage } = useIntl();
    const { openStage } = useBookingParams();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { toastApiError } = useApiError();
    const partyApiClient = useApiClient(PartyBookingApiClient);
    const [locationConfiguration, setLocationConfiguration] = useState<PartyLocationConfigurationDto | null>(null);

    const fetchData = () => {
        const controller = new AbortController();

        incrementLoader();

        partyApiClient
            .apiPartyBookingPartyLocationConfigurationGet(booking.details?.playground.guid, controller.signal)
            .then((locationConfiguration) => setLocationConfiguration(locationConfiguration))
            .catch((error) => {
                const apiError = ApiError.fromError(error);
                if (apiError !== ApiError.Cancellation) {
                    toastApiError(error);
                }
            })
            .finally(decrementLoader);

        return () => controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchData, []);

    // We require a booking and the user to login to confirm a booking
    if (!booking.details) {
        return null;
    }

    const onBackClick = async () => {
        if (!booking.details) {
            return;
        }

        openStage(Stage.BookingOverview, booking.details);
    };

    return (
        <>
            <PageContainer
                title={formatMessage({ id: 'booking-details.title', defaultMessage: 'Bokningsbekräftelse' })}
                onBackClick={onBackClick}
            >
                <BookingSummary
                    playground={booking.details.playground.name}
                    date={booking.details.slot.date}
                    startTime={booking.details.slot.displayStartTime}
                    arrivalTime={booking.details.startTime || booking.details.slot.displayStartTime}
                    enableArrivalTime={locationConfiguration?.partyEnableChoosePlayTime || false}
                    room={booking.details.room.name}
                    guests={booking.details.guestCount}
                    articles={booking.details.articles}
                    partyPackage={booking.details.partyPackage}
                    total={booking.details.price.pricevalue}
                    currency={booking.details.price.currency}
                    className={classes.summary}
                    artNoExtraBirthdayChild={booking.details.artNoExtraBirthdayChild}
                    playcentertype={booking.details.playground.playcentertype}
                />

                <PageButtons alignBottom>
                    <Button color="transparent" onClick={onBackClick}>
                        <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                    </Button>
                </PageButtons>
            </PageContainer>
        </>
    );
};
