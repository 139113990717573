import { FunctionComponent, TextareaHTMLAttributes } from 'react';
import { ComboButton, ComboButtonContent, ComboButtonTitle, ExpandedComboButton } from '../../common/ComboButton/ComboButton';
import { Typography } from '../../common/Typography/Typography';
import { TextAreaField } from '../TextAreaField/TextAreaField';
import classes from './ComboTextAreaField.module.scss';

interface ComboTextAreaFieldProps extends Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'onChange'> {
    title: string;
    text: string;
    placeholder: string;
    className?: string;
}

/**
 * Uses an expanded combo button with a text area inside.
 */
export const ComboTextAreaField: FunctionComponent<ComboTextAreaFieldProps> = ({
    title,
    text,
    placeholder,
    className,
    value,
    onChange,
}) => {
    return (
        <ExpandedComboButton className={className}>
            <ComboButton component="div">
                <ComboButtonContent>
                    <ComboButtonTitle>{title}</ComboButtonTitle>
                </ComboButtonContent>
            </ComboButton>

            <div className={classes.content}>
                <Typography className={classes.description}>{text}</Typography>

                <TextAreaField
                    value={value}
                    label={placeholder}
                    placeholder={placeholder}
                    rows={4}
                    onChange={onChange}
                    className={classes.textArea}
                />
            </div>
        </ExpandedComboButton>
    );
};
