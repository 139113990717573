import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { Typography, TypographyProps } from '../Typography/Typography';
import classes from './Card.module.scss';

interface CardProps {
    dark?: boolean;
    className?: string;
}

/**
 * Design system Card with support for additional content below the text. This is usually a button.
 */
export const Card: FunctionComponent<CardProps> = ({ dark, className, children }) => {
    return <div className={clsx(classes.container, dark && classes.dark, className)}>{children}</div>;
};

/**
 * The Card title.
 */
export const CardTitle: FunctionComponent = ({ children }) => {
    return (
        <Typography variant="title4" component="h2" className={classes.title}>
            {children}
        </Typography>
    );
};

interface CardTextProps {
    component?: TypographyProps['component'];
}

/**
 * The Card text.
 */
export const CardText: FunctionComponent<CardTextProps> = ({ component, children }) => {
    return <Typography component={component}>{children}</Typography>;
};

/**
 * The content below the Card text, usually a Button.
 */
export const CardAction: FunctionComponent = ({ children }) => {
    return <div className={classes.action}>{children}</div>;
};
