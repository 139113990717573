import { faRepeat } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DimensionOptions, IImageDto } from '../../../api/api.generated';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonBottomText,
    ComboButtonContent,
    ComboButtonTitle,
} from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { FadeInImage } from '../../../components/utils/FadeInImage/FadeInImage';
import { Article } from '../../../model';
import { imageUrl } from '../../../utils';
import classes from './AddonButton.module.scss';
import ImageArticleFallback from './article-fallback.png';

interface AddonButtonProps {
    article: Article;
    currency: string;
    exchangeable?: boolean;
    selected?: boolean;
    hidePrice?: boolean;
    noRadius?: boolean;
    disabled?: boolean;
    className?: string;
    onClick: () => void;
}

/**
 * Button used to display a single addon article. Can be used both for exchanging articles and for selecting articles.
 *
 * Usage:
 * <AddonButton
 *     article={article}
 *     currency="SEK"
 *     exchangeable
 *     selected
 *     onClick={handleClick}
 * />
 */
export const AddonButton: FunctionComponent<AddonButtonProps> = ({
    article,
    currency,
    exchangeable,
    selected,
    hidePrice,
    disabled,
    className,
    onClick,
}) => {
    const { formatNumber } = useIntl();
    const formattedPrice = formatNumber(article.price, { style: 'currency', currency, minimumFractionDigits: 0 });

    return (
        <ComboButton className={className} disabled={disabled} onClick={onClick}>
            <ComboButtonAction>
                <AddonButtonImage image={article.image} />
            </ComboButtonAction>

            <ComboButtonContent>
                <ComboButtonTitle>{article.name}</ComboButtonTitle>

                {exchangeable && selected && (
                    <ComboButtonBottomText>
                        <FormattedMessage
                            id="select-addons.article-upgraded-price"
                            defaultMessage="Uppgraderad, +{price} / barn"
                            values={{ price: formattedPrice }}
                        />
                    </ComboButtonBottomText>
                )}

                {!exchangeable && !disabled && !hidePrice && (
                    <ComboButtonBottomText>
                        <FormattedMessage
                            id="select-addons.article-price"
                            defaultMessage="+{price} / barn"
                            values={{ price: formattedPrice }}
                        />
                    </ComboButtonBottomText>
                )}
            </ComboButtonContent>

            {!disabled && (
                <ComboButtonAction>
                    <AddonButtonAction exchangeable={exchangeable} selected={selected} />
                </ComboButtonAction>
            )}
        </ComboButton>
    );
};

const AddonButtonImage: FunctionComponent<{ image?: IImageDto }> = ({ image }) => {
    const classNames = clsx(classes.imageWrapper, {
        [classes.imageWrapperFallback]: !image,
    });

    return (
        <div className={classNames}>
            {image && <FadeInImage src={imageUrl(image, 96, 96, DimensionOptions.Resize)} className={classes.image} />}
            {!image && <FadeInImage src={ImageArticleFallback} className={clsx(classes.image, classes.imageFallback)} />}
        </div>
    );
};

const AddonButtonAction: FunctionComponent<{ exchangeable?: boolean; selected?: boolean }> = ({ exchangeable, selected }) => {
    if (exchangeable) {
        return (
            <div className={classes.change}>
                <FormattedMessage id="select-addons.exchange-article" defaultMessage="Byt ut" />
                <FontAwesomeIcon icon={faRepeat} width={30} className={classes.changeIcon} />
            </div>
        );
    }

    if (selected) {
        return <FontAwesomeIcon icon={faCheckCircle} width={48} className={classes.iconSelected} />;
    }

    return <div className={classes.icon} />;
};
