import { FunctionComponent } from 'react';
import { DimensionOptions, IImageDto } from '../../../api/api.generated';
import { Dialog } from '../../../components/utils/Dialog/Dialog';
import { FadeInImage } from '../../../components/utils/FadeInImage/FadeInImage';
import { imageUrl } from '../../../utils';
import classes from './InviteCardDialog.module.scss';

interface InviteContainerProps {
    open: boolean;
    backgroundImage?: IImageDto;
}

export const InviteCardDialog: FunctionComponent<InviteContainerProps> = ({ open, backgroundImage, children }) => {
    return (
        <Dialog open={open} enterOnMount>
            <div className={classes.container}>
                {backgroundImage && (
                    <FadeInImage src={imageUrl(backgroundImage, 1200, 1200, DimensionOptions.Fill)} className={classes.background} />
                )}

                <div className={classes.scrollContainer}>
                    <div className={classes.scrollContent}>
                        <div className={classes.content}>{children}</div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
