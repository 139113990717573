import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'services/auth/AuthProvider';
import { Role } from 'services/auth/Role';
import { IPartyBookingOpeningInfoContentDto } from '../../../api/api.generated';
import { Alert, AlertText } from '../../../components/common/Alert/Alert';
import { Button } from '../../../components/common/Button/Button';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { RichText } from '../../../components/common/RichText/RichText';
import { Typography } from '../../../components/common/Typography/Typography';
import { saveToCalendar } from '../../../utils/saveToCalendar';
import { OpeningInfoCard } from './OpeningInfoCard';
import { OpeningInfoCountdown } from './OpeningInfoCountdown';
import classes from './OpeningInfoPage.module.scss';

interface OpeningInfoProps {
    /** The opening date. */
    date: Date;

    /** The opening content. */
    content: IPartyBookingOpeningInfoContentDto;

    /** Called when the countdown reaches 0. This lets the countdown component handle the interval timer. */
    onCountdownEnded: () => void;

    /** Called when the back button is pressed on the page. */
    onBackClick?: () => void;
}

/**
 * Page replacing the select date stage when the selected playground is about to be opened.
 */
export const OpeningInfoPage: FunctionComponent<OpeningInfoProps> = ({ date, content, onCountdownEnded, onBackClick }) => {
    const { roles } = useAuth();

    const onRemindMeClick = () => {
        saveToCalendar({
            title: content.calendarEventTitle,
            description: content.calendarEventText + '\n\n' + document.location.href.toString(),
            start: date,
            end: date,
            alarm: { minutes: 30, before: true },
        });
    };

    return (
        <PageContainer title={content.pageTitle} onBackClick={onBackClick}>
            <OpeningInfoCountdown date={date} onCountdownEnded={onCountdownEnded} className={classes.countdown} />

            <Typography component="div" variant="preamble" className={classes.subtitle}>
                <RichText>{content.pageSubtitle}</RichText>
            </Typography>

            <OpeningInfoCard image={content.cardImage} className={classes.card}>
                <Typography variant="title4" className={classes.cardTitle}>
                    {content.cardTitle}
                </Typography>

                <Typography component="div" className={classes.cardText}>
                    <RichText>{content.cardText}</RichText>
                </Typography>

                <Button color="minionYellow" onClick={onRemindMeClick}>
                    {content.addToCalenderButtonText}
                </Button>
            </OpeningInfoCard>

            {content.alertText && (
                <Alert className={classes.alert}>
                    <AlertText component="div" className={classes.alertText}>
                        <RichText>{content.alertText}</RichText>
                    </AlertText>
                </Alert>
            )}

            <PageButtons alignBottom>
                <Button color="transparent" onClick={onBackClick}>
                    <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                </Button>
                {(roles.includes(Role.GlobalAdministrator) || roles.includes(Role.LocationAdministrator)) && (
                    <Button color="transparent" onClick={onCountdownEnded}>
                        <FormattedMessage id="general.close" defaultMessage="Stäng" />
                    </Button>
                )}
            </PageButtons>
        </PageContainer>
    );
};
