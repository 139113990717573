import clsx from 'clsx';
import { CSSProperties, FunctionComponent, PointerEventHandler, ReactElement, ReactNode, TransitionEventHandler, useState } from 'react';
import classes from './Ripple.module.scss';

interface RippleOptions {
    key: number;
    size: number;
    left: number;
    top: number;
    complete: boolean;
}

interface RippleTargetProps {
    onPointerDown: PointerEventHandler<HTMLElement>;
    onPointerUp: PointerEventHandler<HTMLElement>;
    onPointerOut: PointerEventHandler<HTMLElement>;
}

interface RippleProps {
    disabled?: boolean;
    className?: string;
    children(props: RippleTargetProps, ripples: ReactNode): ReactElement<any, any>;
}

/**
 * Renders animated ripples when a target element is clicked/touched.
 *
 * The target element and the children need to have position set to something other than static to ensure that the
 * ripples will not be drawn outside the element or over the children.
 *
 * Usage:
 *
 * <Ripple disabled={disabled} className={classes.ripple}>
 *     {(props, ripples) => (
 *         <button style={{ position: 'relative' }} {...props}>
 *             {ripples}
 *             <span style={{ position: 'relative' }}>Click Me</span>
 *         </button>
 *     )}
 * </Ripple>
 */
export const Ripple: FunctionComponent<RippleProps> = ({ disabled, className, children }) => {
    const [ripples, setRipples] = useState<RippleOptions[]>([]);

    // Creates a new ripple when starting a touch or pressing down on a mouse button
    const handlePointerDown: PointerEventHandler<HTMLElement> = (event) => {
        if (disabled) {
            return;
        }

        const { left, top, width, height } = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;

        // Calculate how big we need to make the ripple to ensure it covers the whole parent
        const a = Math.max(y, height - y);
        const b = Math.max(x, width - x);
        const size = Math.ceil(Math.hypot(a, b));

        const newRipple: RippleOptions = {
            key: event.timeStamp,
            size: size * 2,
            left: x - size,
            top: y - size,
            complete: false,
        };

        setRipples((ripples) => [...ripples, newRipple]);
    };

    // Marks all ripples as complete when releasing pointer over moving outside the element
    const handlePointerUpOut: PointerEventHandler = () => {
        setRipples((ripples) => {
            return ripples.map((ripple) => ({ ...ripple, complete: true }));
        });
    };

    const rippleItems = ripples.map((ripple) => {
        const handleAnimationEnd: TransitionEventHandler = () => {
            setRipples((state) => state.filter((r) => r.key !== ripple.key));
        };

        const style: CSSProperties = {
            width: ripple.size,
            height: ripple.size,
            left: ripple.left,
            top: ripple.top,
            opacity: ripple.complete ? 0 : 1,
        };

        return (
            <span key={ripple.key} style={style} className={classes.ripple} onTransitionEnd={handleAnimationEnd}>
                <span className={clsx(classes.fill, className)} />
            </span>
        );
    });

    return children(
        {
            onPointerDown: handlePointerDown,
            onPointerUp: handlePointerUpOut,
            onPointerOut: handlePointerUpOut,
        },
        rippleItems,
    );
};
