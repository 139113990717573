import { AsyncStorage, InMemoryWebStorage, User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AuthenticationIdentityApiClient, LoginIntent, ReturnOrigin } from '../../api/api-identity.generated';
import { UserApiClient } from '../../api/api.generated';
import { Config } from '../../config';
import { currentDomainConfig } from '../../domain-config';
import { AuthenticationChangeListener } from './AuthenticationChangeListener';
import { AuthenticationResult } from './AuthenticationResult';

/**
 * Handles user authentication and user profile data.
 */
export class AuthService {
    /** Storage for oidc state */
    private static readonly _StateStore: Storage | AsyncStorage = window.sessionStorage ?? new InMemoryWebStorage();

    /** Configure the OIDC Client User Manager */
    private readonly userManager = new UserManager({
        response_type: 'code',
        scope: 'profile offline_access openid',
        authority: Config.authAuthority[currentDomainConfig.countrySlug],
        client_id: Config.authClientId,

        // We are not using the redirect uri as we sign in at the onboarding.
        redirect_uri: '',

        // We only use silent sign in and silent sign out
        silent_redirect_uri: `${Config.baseUrlApp}/auth/signin-silent-callback.html`,
        post_logout_redirect_uri: `${Config.baseUrlApp}/auth/signout-silent-callback.html`,

        filterProtocolClaims: true,
        loadUserInfo: false,
        automaticSilentRenew: true,
        monitorSession: true,
        monitorAnonymousSession: true,
        checkSessionIntervalInSeconds: 5,
        stateStore: new WebStorageStateStore({ prefix: 'oidc_', store: AuthService._StateStore }),
        userStore: new WebStorageStateStore({ prefix: 'oidc_user_' }),
    });

    /** The API client for the User api */
    private readonly userApiClient = new UserApiClient(Config.baseUrlApi);

    /** The API client for the User api */
    private readonly identityApiClient = new AuthenticationIdentityApiClient(Config.authAuthority[currentDomainConfig.countrySlug]);

    /** The current authentication result */
    private authenticationResult: AuthenticationResult | null = null;

    /** Event handlers bound to the correct "this" */
    private readonly boundOnUserLoaded = this.onUserLoaded.bind(this);
    private readonly boundOnUserUnloaded = this.onUserUnloaded.bind(this);
    private readonly boundOnUserSignedIn = this.onUserSignedIn.bind(this);
    private readonly boundOnUserSignedOut = this.onUserSignedOut.bind(this);

    /** Event listeners for when the authentication result changes. */
    public readonly changeListeners: Set<AuthenticationChangeListener> = new Set();

    constructor() {
        // Always include credentials with the identity API client as we use it to check if the user is authenticated at
        // the IdP.
        this.identityApiClient.credentials = 'include';
    }

    /**
     * Initializes the user manager.
     */
    public async initialize(): Promise<AuthenticationResult | null> {
        this.log('info', 'Initializing auth service, clearing stale state');
        await this.userManager.clearStaleState();

        const user = await this.getAuthenticatedUser();
        if (user) {
            this.authenticationResult = await this.fetchApplicationUser(user);
        }

        // It's very important that we add the events after we're done with the authentication check.
        // If added before we will have timing issues as the user loaded event is fired before the
        // userManager.signInSilent promise is resolved.
        this.addUserManagerEvents();

        return this.authenticationResult;
    }

    /**
     * Adds all the events to the user manager. We will not do that during manual silent sign in's and initializing as the callbacks will be
     * called when we are already fetching user data.
     */
    private addUserManagerEvents(): void {
        this.log('info', 'Adding event listeners on user manager');
        this.userManager.events.addUserLoaded(this.boundOnUserLoaded);
        this.userManager.events.addUserUnloaded(this.boundOnUserUnloaded);
        this.userManager.events.addUserSignedIn(this.boundOnUserSignedIn);
        this.userManager.events.addUserSignedOut(this.boundOnUserSignedOut);
    }

    /**
     * Removes all the events from the user manager.
     */
    private removeUserManagerEvents(): void {
        this.log('info', 'Removing event listeners on user manager');
        this.userManager.events.removeUserLoaded(this.boundOnUserLoaded);
        this.userManager.events.removeUserUnloaded(this.boundOnUserUnloaded);
        this.userManager.events.removeUserSignedIn(this.boundOnUserSignedIn);
        this.userManager.events.removeUserSignedOut(this.boundOnUserSignedOut);
    }

    /**
     * Check if we already have an authenticated user in the user manager store or at the IdP.
     */
    private async getAuthenticatedUser(): Promise<User | null> {
        try {
            this.log('info', 'Fetching user from user manager store');
            let user = await this.userManager.getUser();
            if (user && !user.expired) {
                this.log('info', 'Found user in user manager store', user);

                this.log('info', 'Checking if user is authenticated in IdP');
                await this.identityApiClient.authentication();

                this.log('info', 'User authenticated at IdP');
                return user;
            }

            if (user) {
                this.log('info', 'User expired, removing user');
                await this.userManager.removeUser();
            } else {
                this.log('info', 'User not found in store');
            }

            this.log('info', 'Checking if user is signed in at IdP via silent sign in');
            user = await this.userManager.signinSilent();
            if (user) {
                this.log('info', 'Found user via silent sign in', user);
                return user;
            }

            this.log('info', 'User not found via silent sign in');
        } catch (error: unknown) {
            this.log('warn', 'Failed to load user from user manager', error);
            await this.userManager.removeUser();
        }

        return null;
    }

    /**
     * Redirect to the onboarding process with the specified intent and continue path.
     *
     * @param profileUrl The url to the profile application
     * @param intent The login intent
     * @param continuePath The path to return to after a successful login
     */
    public openLogin(profileUrl: string, intent: LoginIntent, continuePath: string): void {
        const onboardingUrl = new URL(profileUrl);
        onboardingUrl.pathname += '/onboarding';
        onboardingUrl.searchParams.set('intent', intent);
        onboardingUrl.searchParams.set('origin', ReturnOrigin.PartyBooking);
        onboardingUrl.searchParams.set('continuePath', continuePath);

        document.location.assign(onboardingUrl);
    }

    /**
     * Sign out the user.
     */
    public async logout(): Promise<void> {
        try {
            // We need to remove the user manager events to ensure we are not redirected before the sign-out is completed
            // (via the user unloaded event).
            this.removeUserManagerEvents();

            this.log('info', 'Performing a silent sign-out at the IdP.');
            await this.userManager.signoutSilent();
            this.updateAuthentication(null);
        } finally {
            this.addUserManagerEvents();
        }
    }

    /**
     * Destroys the auth service instance by removing all listeners.
     */
    public destroy(): void {
        this.removeUserManagerEvents();
        this.changeListeners.clear();
    }

    /**
     * Updates the authentication result and calls all the change listener.
     */
    private updateAuthentication(result: AuthenticationResult | null) {
        this.log('info', 'Updating authentication result and calling change listeners');
        this.authenticationResult = result;

        for (const listener of this.changeListeners) {
            listener(this.authenticationResult);
        }
    }

    /**
     * Fetches the application user if the supplied user is authenticated.
     */
    private async fetchApplicationUser(user: User): Promise<AuthenticationResult | null> {
        try {
            // Fetch the user profile
            this.userApiClient.setAuthorizationFromUser(user);

            this.log('info', 'Fetching application user from api');
            const applicationUser = await this.userApiClient.apiUserMeGet();

            this.log('info', 'Successfully fetched application user', applicationUser);
            return new AuthenticationResult(user, applicationUser);
        } catch (error: unknown) {
            this.log('warn', 'Failed to fetch application user, removing from store', error);
            await this.userManager.removeUser();
            return null;
        }
    }

    /**
     * Callback when the user has been loaded.
     */
    private async onUserLoaded(user: User): Promise<void> {
        this.log('info', 'Received user loaded event', user);

        const result = await this.fetchApplicationUser(user);
        this.updateAuthentication(result);
    }

    /**
     * Callback when the user has been unloaded.
     */
    private async onUserUnloaded(): Promise<void> {
        this.log('info', 'Received user unloaded event');

        this.updateAuthentication(null);
    }

    /**
     * Callback when the user has signed in.
     * Triggering a silent sign in will automatically call the userLoaded event.
     * We'll handle fetching and setting the user in that event callback.
     */
    private async onUserSignedIn(): Promise<void> {
        this.log('info', 'Received user signed in event, performing a silent sign in');

        try {
            await this.userManager.signinSilent();
        } catch (error: unknown) {
            this.log('warn', 'Failed to perform a silent sign in', error);
        }
    }

    /**
     * Callback when the user has signed out.
     * Removing the user from the user manager will automatically call the userUnloaded event.
     * We'll handle clearing the user in that event callback.
     */
    private async onUserSignedOut(): Promise<void> {
        this.log('info', 'Received user signed out event');
        await this.userManager.removeUser();
    }

    /**
     * Log to the console.
     */
    private log(severity: 'info' | 'warn', ...params: unknown[]): void {
        if (process.env.NODE_ENV !== 'development') {
            return;
        }

        if (severity === 'info') {
            console.info(`[${AuthService.name}]:`, ...params);
        } else {
            console.warn(`[${AuthService.name}]:`, ...params);
        }
    }
}
