import clsx from 'clsx';
import * as React from 'react';
import { MouseEvent } from 'react';
import { IconFlagDenmark, IconFlagFinland, IconFlagGermany, IconFlagNorway, IconFlagSweden } from '../../../assets/icons';
import { CountryInfo } from '../../../hooks/useCountries';
import { CountrySlug } from '../../../utils/locale';
import { Ripple } from '../../utils/Ripple/Ripple';
import classes from './SidebarCountryLink.module.scss';

const flags: Record<CountrySlug, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
    sweden: IconFlagSweden,
    norway: IconFlagNorway,
    denmark: IconFlagDenmark,
    finland: IconFlagFinland,
    germany: IconFlagGermany,
};

type SidebarCountryLinkProps = {
    country: CountryInfo;
    selected: boolean;
    onClick: () => void;
};

/**
 * Single link to a different country.
 */
export function SidebarCountryLink({ country, selected, onClick }: SidebarCountryLinkProps) {
    const FlagComponent = flags[country.slug];

    function onLinkClick(event: MouseEvent<HTMLAnchorElement>) {
        if (selected) {
            event.preventDefault();
        }

        onClick();
    }

    return (
        <li>
            <Ripple className={classes.ripple}>
                {(props, ripples) => (
                    <a
                        className={clsx(classes.link, selected && classes.selected)}
                        href={`${country.urlParty}`}
                        title={country.name}
                        onClick={onLinkClick}
                        {...props}
                    >
                        {ripples}

                        <span className={classes.linkContent}>
                            <FlagComponent className={classes.flag} />

                            <span className={classes.name}>
                                <span className={classes.nativeName}>{country.nativeName}</span>
                                <span>{country.name}</span>
                            </span>
                        </span>
                    </a>
                )}
            </Ripple>
        </li>
    );
}
