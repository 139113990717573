import { Article, PartyPackage } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Bookingarticle } from '../model/reservation';

export interface ExchangeableArticle {
    default: Article;
    selected: Article;
    options: Article[];
}

export interface DefaultArticle {
    default: Article;
    options: Article[];
}

export const useAddons = (partyPackage?: PartyPackage, bookingArticles?: Bookingarticle[]) => {
    const { locale } = useIntl();

    // Holds all the currently selected articles
    const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);

    // Sort and simplify the default articles list
    const defaultArticles = useMemo<DefaultArticle[]>(() => {
        if (!partyPackage) {
            return [];
        }

        return partyPackage.articles.defaultarticles
            .sort((a, b) => {
                // We want the non-exchangeable articles at the top
                if (a.exchangeable.length && !b.exchangeable.length) {
                    return 1;
                }
                if (!a.exchangeable.length && b.exchangeable.length) {
                    return -1;
                }

                return a.name.localeCompare(b.name, locale);
            })
            .sort((a, b) => a.sortorder - b.sortorder)
            .filter((article) => article.isdefaultvisible || article.exchangeable.length > 0)
            .map<DefaultArticle>((defaultArticle) => {
                const options = defaultArticle.exchangeable.sort((a, b) => a.name.localeCompare(b.name, locale));

                return {
                    default: defaultArticle,
                    options: [defaultArticle, ...options],
                };
            });
    }, [partyPackage, locale]);

    // Sort the addon articles list
    const addonArticles = useMemo<Article[]>(() => {
        if (!partyPackage) {
            return [];
        }

        return partyPackage.articles.addonarticles
            .sort((a, b) => a.name.localeCompare(b.name, locale))
            .sort((a, b) => a.sortorder - b.sortorder);
    }, [partyPackage, locale]);

    // Update the selected articles from the booking articles
    useEffect(() => {
        const selected: Article[] = [];

        if (partyPackage) {
            for (const defaultArticle of partyPackage.articles.defaultarticles) {
                selected.push(defaultArticle);

                const selectedArticle = defaultArticle.exchangeable.find((da) => {
                    return bookingArticles?.find((ba) => ba.packagearticleguid === da.guid) !== undefined;
                });

                if (selectedArticle) {
                    selected.push(selectedArticle);
                }
            }

            for (const addonArticle of partyPackage.articles.addonarticles) {
                const bookingArticle = bookingArticles?.find((ba) => ba.packagearticleguid === addonArticle.guid);
                if (bookingArticle) {
                    selected.push(addonArticle);
                }
            }
        }

        setSelectedArticles(selected);
    }, [bookingArticles, partyPackage]);

    // Maps the default articles and includes a selected attribute
    const selectedDefaultArticles = useMemo<ExchangeableArticle[]>(() => {
        return defaultArticles.map((defaultArticle) => {
            const selectedArticle = defaultArticle.options.find((da) => da !== defaultArticle.default && selectedArticles.includes(da));

            return {
                default: defaultArticle.default,
                selected: selectedArticle ?? defaultArticle.default,
                options: defaultArticle.options,
            };
        });
    }, [defaultArticles, selectedArticles]);

    // Compute all the selected addon articles
    const selectedAddonArticles = useMemo<Article[]>(() => {
        return addonArticles.filter((addonArticle) => selectedArticles.includes(addonArticle));
    }, [addonArticles, selectedArticles]);

    const availableAddonArticles = useMemo<Article[]>(() => {
        return addonArticles.filter((addonArticle) => !selectedArticles.includes(addonArticle));
    }, [addonArticles, selectedArticles]);

    const price = useMemo<number>(() => {
        let price = partyPackage?.price.pricevalue ?? 0;

        // We need all the party package default articles
        const defaultArticles: Article[] = partyPackage?.articles.defaultarticles ?? [];

        // Add any additional packages
        for (const article of selectedArticles) {
            if (!defaultArticles.includes(article)) {
                price += article.price;
            }
        }

        return price;
    }, [partyPackage, selectedArticles]);

    const addArticle = (article: Article) => {
        setSelectedArticles((articles) => {
            if (!articles.includes(article)) {
                return [...articles, article];
            }

            return articles;
        });
    };

    const removeArticle = (article: Article) => {
        setSelectedArticles((articles) => {
            return articles.filter((a) => a !== article);
        });
    };

    return {
        addArticle,
        removeArticle,

        selectedArticles,
        selectedDefaultArticles,
        selectedAddonArticles,
        availableAddonArticles,
        price,
    };
};
