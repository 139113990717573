import axios, { AxiosInstance, AxiosResponse, CancelToken } from 'axios';
import { formatISO, parseISO } from 'date-fns';
import { ApplicationUserDto, CountryMetadataDto } from '../api/api.generated';
import { Config } from '../config';
import { CountryInfo } from '../hooks/useCountries';
import { AddGuestRequest, Country, FreeDates, Guest, PartyPackage, Playground, Playgrounds, ReservationResponse, Room } from '../model';
import { InvitationBackground } from '../model/booking';
import { BookingDetails, toBookingDetails } from '../model/BookingDetails';
import { FreeRoom } from '../model/freeDates';
import { Invitation, InvitationAnswer } from '../model/invitation';
import { PartyBookingListDto } from '../model/myBookings';
import { OpeningHoursDto } from '../model/OpeningHours/OpeningHoursDto';
import { UpdateArticleResponse } from '../model/reservation';
import { AccessToken } from '../services/auth/AccessToken';
import { ApiError } from '../utils';
import { HttpStatusCode } from './HttpStatusCode';
import { getClosingTime } from './openingHourUtils';

interface CacheData {
    countries?: Country[];
    playgrounds?: Playgrounds;
    rooms: Record<string, Room | undefined>;
    partyPackages: Record<string, PartyPackage | undefined>;
    invitationBackgrounds: Record<string, InvitationBackground[] | undefined>;
    openingHours: Record<string, OpeningHoursDto[] | undefined>;
    phoneCountryCodes?: number[];
}

interface ApiClientParams {
    accessToken: AccessToken | null;
    sessionId: string | null;
}

export default class ApiClient {
    // We will use an object to cache some requests in-memory.
    private static readonly Cache: CacheData = {
        rooms: {},
        partyPackages: {},
        invitationBackgrounds: {},
        openingHours: {},
    };

    // The current Axios instance
    private readonly client: AxiosInstance;

    // The default locale
    private readonly locale: string;

    static createCancelToken() {
        return axios.CancelToken.source();
    }

    constructor(country: CountryInfo, { accessToken, sessionId }: ApiClientParams) {
        const defaultHeaders: Record<string, string> = {};

        if (accessToken) {
            defaultHeaders.Authorization = `${accessToken.type} ${accessToken.token}`;
        }

        if (sessionId) {
            defaultHeaders['X-Leo-Sessionid'] = sessionId;
        }

        // Create axios client
        this.client = axios.create({ baseURL: Config.baseUrlApi });
        this.client.defaults.headers.common = defaultHeaders;
        this.client.defaults.params = {
            culture: `${country.language}-${country.code}`,
        };

        this.locale = country.language;
    }

    async getCountries(cancelToken?: CancelToken): Promise<Country[]> {
        if (ApiClient.Cache.countries) {
            return ApiClient.Cache.countries;
        }

        const countriesRes = await this.client.get<Country[]>('/api/country/frontendlist', { cancelToken });
        const countriesSlugRes = await this.client.get<Country[]>('/api/location/fontendgetlist', {
            params: { scope: '1' },
            cancelToken,
        });

        const countries: Country[] = [];

        for (const country of countriesRes.data) {
            const slugCountry = countriesSlugRes.data.find((sc) => sc.guid === country.guid);
            if (slugCountry) {
                countries.push({
                    ...country,
                    slug: slugCountry.slug,
                });
            }
        }

        ApiClient.Cache.countries = countries;
        return countries;
    }

    async getPlaygrounds(cancelToken?: CancelToken): Promise<Playgrounds> {
        if (ApiClient.Cache.playgrounds) {
            return ApiClient.Cache.playgrounds;
        }

        const res = await this.client.get<Playgrounds>('/api/Location/GetTree', {
            params: { scope: 'Playground' },
            cancelToken,
        });

        ApiClient.Cache.playgrounds = res.data;
        return res.data;
    }

    async getMyBookings(cancelToken?: CancelToken): Promise<PartyBookingListDto> {
        const response = await this.client.get<PartyBookingListDto>('/api/party/booking/MyBookings', { cancelToken });
        return response.data;
    }

    async getRoom(roomId: string, cancelToken?: CancelToken): Promise<Room> {
        const room = ApiClient.Cache.rooms[roomId];
        if (room) {
            return room;
        }

        const response = await this.client.get<Room>(`/api/Room/Get/${roomId}`, { cancelToken });

        ApiClient.Cache.rooms[roomId] = response.data;
        return response.data;
    }

    async getFreeDates(playgroundId: string, date: string, cancelToken?: CancelToken): Promise<FreeRoom[]> {
        const response = await this.client.get<FreeDates>('/api/party/booking/FindFreeDates', {
            params: {
                playgroundid: playgroundId,
                daysforward: '1',
                fromdate: date,
            },
            cancelToken,
        });

        const freeDate = response.data.dates.find((fd) => fd.date === date);
        return freeDate?.rooms ?? [];
    }

    async getPartyPackage(
        partyPackageId: string,
        playgroundId: string,
        partyDate: string,
        bookingDate: string,
        cancelToken?: CancelToken,
    ): Promise<PartyPackage> {
        const cacheId = [partyPackageId, playgroundId, partyDate, bookingDate].join('-');
        const partyPackage = ApiClient.Cache.partyPackages[cacheId];
        if (partyPackage) {
            return partyPackage;
        }

        const res = await this.client.get<PartyPackage>(`/api/party/package/Get/${partyPackageId}`, {
            params: {
                locationid: playgroundId,
                date: partyDate,
                bookingDate: bookingDate,
            },
            cancelToken,
        });

        ApiClient.Cache.partyPackages[cacheId] = res.data;
        return res.data;
    }

    async getPartyPackages(playgroundId: string, date: string, bookingDate: string, cancelToken?: CancelToken): Promise<PartyPackage[]> {
        const response = await this.client.get<PartyPackage[]>('/api/party/package/ListPackages', {
            params: {
                locationid: playgroundId,
                date,
                bookingDate,
            },
            cancelToken,
        });

        return response.data;
    }

    async getInvitationBackgrounds(playgroundId: string, cancelToken?: CancelToken): Promise<InvitationBackground[]> {
        const invitationBackgrounds = ApiClient.Cache.invitationBackgrounds[playgroundId];
        if (invitationBackgrounds) {
            return invitationBackgrounds;
        }

        const response = await this.client.get<InvitationBackground[]>(`/api/party/booking/GetInvitationBackgrounds/${playgroundId}`, {
            cancelToken,
        });

        ApiClient.Cache.invitationBackgrounds[playgroundId] = response.data;
        return response.data;
    }

    async getBooking(bookingId: string, cancelToken?: CancelToken): Promise<BookingDetails> {
        const response = await this.client.get<ReservationResponse>(`/api/party/booking/get/${bookingId}`, { cancelToken });
        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async makeReservation(
        roomId: string,
        timeSlotId: string,
        partyDate: string,
        arrivalTime: string | null,
        partyLengthMinutes: number | null,
        cancelToken?: CancelToken,
    ): Promise<BookingDetails> {
        const response = await this.client.post<Object, AxiosResponse<ReservationResponse>>(
            '/api/party/booking/makereservation',
            {
                roomId,
                timeSlotId,
                partyDate,
                arrivalTime,
                partyLengthMinutes,
            },
            { cancelToken },
        );

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async changeReservation(
        reservationId: string,
        roomId: string,
        timeSlotId: string,
        partyDate: string,
        arrivalTime: string | null,
        partyLengthMinutes: number | null,
        cancelToken?: CancelToken,
    ): Promise<BookingDetails> {
        const response = await this.client.put<Object, AxiosResponse<ReservationResponse>>(
            '/api/party/booking/ChangeReservation',
            {
                reservationid: reservationId,
                roomid: roomId,
                timeslotid: timeSlotId,
                partydate: partyDate,
                arrivaltime: arrivalTime,
                partyLengthMinutes: partyLengthMinutes,
            },
            { cancelToken },
        );

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async confirmBooking(bookingId: string, cancelToken?: CancelToken): Promise<BookingDetails> {
        const response = await this.client.post<Object, AxiosResponse<ReservationResponse>>(
            '/api/party/booking/ConfirmBooking',
            {
                bookingid: bookingId,
            },
            { cancelToken },
        );

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async confirmBookingChanges(bookingId: string, cancelToken?: CancelToken): Promise<BookingDetails> {
        const response = await this.client.post<Object, AxiosResponse<ReservationResponse>>(
            '/api/party/booking/ConfirmBookingChanges',
            {
                bookingid: bookingId,
            },
            { cancelToken },
        );

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async confirmDateChange(bookingId: string, reservationId: string, message: string, cancelToken?: CancelToken): Promise<BookingDetails> {
        const response = await this.client.put<Object, AxiosResponse<ReservationResponse>>(
            '/api/party/booking/ConfirmDateChange',
            {
                guid: bookingId,
                reservationid: reservationId,
                changemessage: message,
            },
            { cancelToken },
        );

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async changeBooking(
        bookingId: string,
        staffMessage: string,
        allergyMessage: string,
        cancelToken?: CancelToken,
    ): Promise<BookingDetails> {
        const response = await this.client.put<Object, AxiosResponse<ReservationResponse>>(
            '/api/party/booking/Change',
            {
                guid: bookingId,
                staffmessage: staffMessage,
                allergyMessage: allergyMessage,
            },
            { cancelToken },
        );

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async changeGuestCount(bookingId: string, count: number, cancelToken?: CancelToken): Promise<Guest[]> {
        const response = await this.client.post<Object, AxiosResponse<Guest[]>>(
            '/api/party/booking/ChangeNumberOfGuests',
            { partybookingid: bookingId, numberofguests: count },
            { params: { confirmdirect: 'false' }, cancelToken },
        );

        return response.data;
    }

    async changeArticles(bookingId: string, selectedArticles: string[], cancelToken?: CancelToken): Promise<UpdateArticleResponse> {
        const response = await this.client.post<Object, AxiosResponse<UpdateArticleResponse>>(
            '/api/party/booking/SetBookingArticles',
            { bookingGuid: bookingId, selectedArticles: selectedArticles },
            { params: { confirmdirect: 'false' }, cancelToken },
        );

        return response.data;
    }

    async saveGuest(guest: AddGuestRequest, cancelToken?: CancelToken): Promise<Guest> {
        const response = await this.client.post<Object, AxiosResponse<Guest>>('/api/party/booking/SaveGuest', guest, { cancelToken });
        return response.data;
    }

    async removeInvite(inviteId: string, cancelToken?: CancelToken): Promise<BookingDetails> {
        const response = await this.client.delete<ReservationResponse>('/api/party/booking/RemoveInvite', {
            params: {
                inviteid: inviteId,
            },
            cancelToken,
        });

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async confirmGuestAttendance(bookingId: string, inviteId: string, isAttending: boolean, cancelToken?: CancelToken): Promise<void> {
        await this.client.post(
            '/api/party/booking/ConfirmGuestAttendence',
            {
                bookingId,
                inviteId,
                isAttending,
            },
            { cancelToken },
        );
    }

    async remindGuests(bookingId: string, invitationIds: string[], cancelToken?: CancelToken): Promise<void> {
        await this.client.post(
            '/api/party/booking/RemindGuest',
            {
                partybookingid: bookingId,
                invitationids: invitationIds,
            },
            { cancelToken },
        );
    }

    async remindPendingGuests(bookingId: string, cancelToken?: CancelToken): Promise<void> {
        await this.client.post(`/api/party/booking/RemindPendingGuests/${bookingId}`, { cancelToken });
    }

    async createInviteTemplate(
        bookingId: string,
        imageId: string,
        stopDate: string,
        message: string,
        cancelToken?: CancelToken,
    ): Promise<BookingDetails> {
        const response = await this.client.put<Object, AxiosResponse<ReservationResponse>>(
            '/api/party/booking/ChangeBackground',
            {
                guid: bookingId,
                imageid: imageId,
                stopdate: stopDate,
                invitationmessage: message,
            },
            { cancelToken },
        );

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async cancelBookingChanges(bookingId: string, cancelToken?: CancelToken): Promise<BookingDetails> {
        const response = await this.client.delete<ReservationResponse>(`/api/party/booking/CancelBookingChanges/${bookingId}`, {
            cancelToken,
        });

        return this.reservationToBookingDetails(response.data, cancelToken);
    }

    async cancelBooking(bookingId: string, guestMessage: string, cancelToken?: CancelToken): Promise<void> {
        await this.client.put(
            '/api/party/booking/cancel',
            {
                guid: bookingId,
                guestMessage: guestMessage,
            },
            { cancelToken },
        );
    }

    async getInvite(invitationId: string, invitationKey: string, cancelToken?: CancelToken): Promise<Invitation> {
        const response = await this.client.get<Invitation>('/api/party/booking/GetInvite', {
            params: {
                invitationid: invitationId,
                invitationkey: invitationKey,
            },
            cancelToken,
        });

        return response.data;
    }

    async answerInvite(payload: InvitationAnswer, cancelToken?: CancelToken): Promise<Guest> {
        const response = await this.client.post<Object, AxiosResponse<Guest>>('/api/party/booking/AnswerInvite', payload, { cancelToken });
        return response.data;
    }

    async reservationToBookingDetails(reservation: ReservationResponse, cancelToken?: CancelToken): Promise<BookingDetails> {
        const countries = await this.getCountries(cancelToken);
        const playgrounds = await this.getPlaygrounds(cancelToken);

        let country: Country | undefined;
        let playground: Playground | undefined;

        for (const playgroundCountry of playgrounds.items) {
            country = countries.find((c) => c.guid === playgroundCountry.guid);

            for (const region of playgroundCountry.children) {
                playground = region.children.find((pg) => pg.guid === reservation.playgroundid);
                if (playground) {
                    break;
                }
            }

            if (playground) {
                break;
            }
        }

        if (!country || !playground) {
            throw new ApiError('Location not found', HttpStatusCode.NotFound);
        }

        const room = await this.getRoom(reservation.reservation.roomid, cancelToken);
        const invitationBackgrounds = await this.getInvitationBackgrounds(reservation.playgroundid, cancelToken);

        const partyPackageGuid = reservation.bookingchanges ? reservation.bookingchanges.articles.packageguid : reservation.packageguid;
        let partyPackage: PartyPackage | undefined;
        if (partyPackageGuid) {
            partyPackage = await this.getPartyPackage(
                partyPackageGuid,
                reservation.playgroundid,
                reservation.reservation.partydate,
                reservation.createddate,
                cancelToken,
            );
        }

        // Fetch the opening hours, so we can limit the party end time to the closing time of the playground.
        const startDate = parseISO(reservation.reservation.partydate);
        const openingHours = await this.getOpeningHours(playground, startDate, startDate, cancelToken);

        // We only fetch one day, so we don't care about any other array entries
        const maxEndTime = openingHours.length > 0 ? getClosingTime(openingHours[0]) : null;

        return toBookingDetails(this.locale, reservation, country, playground, room, invitationBackgrounds, maxEndTime, partyPackage);
    }

    async updateUserProfile(
        userId: string,
        phoneNumberCountryCode: number,
        phoneNumber: string,
        cancelToken?: CancelToken,
    ): Promise<ApplicationUserDto> {
        const response = await this.client.put<Object, AxiosResponse<ApplicationUserDto>>(
            '/api/User/UpdateProfile',
            {
                guid: userId,
                countrycode: phoneNumberCountryCode,
                phone: phoneNumber,
            },
            { cancelToken },
        );

        return response.data;
    }

    async getOpeningHours(playground: Playground, from: Date, to: Date, cancelToken?: CancelToken): Promise<OpeningHoursDto[]> {
        const fromString = formatISO(from, { representation: 'date' });
        const toString = formatISO(to, { representation: 'date' });

        const cacheKey = [playground.guid, fromString, toString].join();
        const openingHours = ApiClient.Cache.openingHours[cacheKey];
        if (openingHours) {
            return openingHours;
        }

        const response = await this.client.get<OpeningHoursDto[]>('/api/openinghours/overview', {
            params: { location: playground.guid, from: fromString, to: toString },
            cancelToken,
        });

        ApiClient.Cache.openingHours[cacheKey] = response.data;
        return response.data;
    }

    async getPhoneCountryCodes(currentCountryCode?: number | undefined, cancelToken?: CancelToken): Promise<number[]> {
        const sort = (arr: number[]) => {
            const result = [...arr];
            const countryCodeIndexes: Record<number, number> = {};

            result.forEach((c, i) => (countryCodeIndexes[c] = i));

            // Order country codes by user country/current country and then regular sort order
            result.sort((a, b) => {
                if (currentCountryCode) {
                    if (a === currentCountryCode) {
                        return -1;
                    } else if (b === currentCountryCode) {
                        return 1;
                    }
                }

                const aIndex = countryCodeIndexes[a];
                const bIndex = countryCodeIndexes[b];
                return aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0;
            });

            return result;
        };

        if (ApiClient.Cache.phoneCountryCodes) {
            return sort(ApiClient.Cache.phoneCountryCodes);
        }

        const response = await this.client.get<CountryMetadataDto>('/api/country/metadata', {
            cancelToken,
        });

        ApiClient.Cache.phoneCountryCodes = response.data.countryPhoneNumberCodes;
        return sort(response.data.countryPhoneNumberCodes);
    }
}
