import { Guest } from 'model';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertText } from '../../../components/common/Alert/Alert';
import { AlertDialog } from '../../../components/common/AlertDialog/AlertDialog';
import { Button } from '../../../components/common/Button/Button';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { FadeIn } from '../../../components/utils/FadeIn/FadeIn';
import { useBooking } from '../../../contexts/BookingProvider';
import { AddBirthdayKidForm } from '../../../features/edit-birthday-kids/AddBirthdayKidForm';
import { BirthdayKidsList } from '../../../features/edit-birthday-kids/BirthdayKidsList';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { BookingStatus } from '../../../model/BookingDetails';
import { Stage } from '../../../model/stage';
import { getFirstEmptyGuest } from '../../../utils';
import classes from './EditBirthdayKids.module.scss';

export const EditBirthdayKids: FunctionComponent = () => {
    const booking = useBooking();
    const { formatMessage, formatNumber } = useIntl();
    const { openStage } = useBookingParams();

    const [maxGuestsDialogOpen, setMaxGuestsDialogOpen] = useState<boolean>(false);
    const [guestToEdit, setGuestToEdit] = useState<Guest | null>(null);

    useEffect(() => {
        if (booking.details?.birthdayKids.length === 0) {
            // Grab first empty guest in the guestlist
            const emptyGuest = getFirstEmptyGuest(booking.details.guestList);
            setGuestToEdit(emptyGuest);
        } else {
            setGuestToEdit(null);
        }
    }, [booking.details]);

    if (!booking.details) {
        return null;
    }

    const onMaxGuestsDialogClose = (confirmed: boolean) => {
        if (confirmed) {
            openStage(Stage.EditGuestCount, booking.details);
        } else {
            setMaxGuestsDialogOpen(false);
        }
    };

    const onEditClick = (guest: Guest) => {
        setGuestToEdit(guest);
        window.scrollTo(0, 0);
    };

    const onAddClick = async () => {
        if (!booking.details) {
            return;
        }

        // Grab first empty guest in the guestlist
        const emptyGuest = getFirstEmptyGuest(booking.details.guestList);
        if (emptyGuest) {
            setGuestToEdit(emptyGuest);
            window.scrollTo(0, 0);
        } else {
            setMaxGuestsDialogOpen(true);
        }
    };

    const onBackClick = () => {
        if (guestToEdit && booking.details && booking.details.birthdayKids.length > 0) {
            setGuestToEdit(null);
            window.scrollTo(0, 0);
        } else if (booking.details?.status === BookingStatus.Confirmed) {
            openStage(Stage.BookingOverview, booking.details);
        } else {
            openStage(Stage.EditGuestCount, booking.details);
        }
    };

    const onContinueClick = async () => {
        openStage(Stage.PreConfirmBooking, booking.details);
    };

    return (
        <>
            <AlertDialog
                type="warning"
                open={maxGuestsDialogOpen}
                className={classes.maxGuestsDialog}
                text={formatMessage({
                    id: 'edit-birthday-kids.max-guests-alert-text',
                    defaultMessage: 'Din gästlista är full! Öka antalet gäster för att kunna lägga till fler födelsedagsbarn.',
                })}
            >
                <Button color="minionYellow" onClick={() => onMaxGuestsDialogClose(true)}>
                    <FormattedMessage id="edit-birthday-kids.max-guests-alert-button" defaultMessage="Redigera gästantalet" />
                </Button>

                <Button color="grandparentGrey900" onClick={() => onMaxGuestsDialogClose(false)}>
                    <FormattedMessage id="general.close" defaultMessage="Stäng" />
                </Button>
            </AlertDialog>

            <PageContainer
                title={formatMessage({ id: 'edit-birthday-kids.title', defaultMessage: 'Födelsedagsbarn' })}
                onBackClick={onBackClick}
            >
                {guestToEdit && (
                    <FadeIn enter enterOnMount className={classes.fadeIn}>
                        <AddBirthdayKidForm booking={booking} guest={guestToEdit} onBackClick={onBackClick} />
                    </FadeIn>
                )}

                {!guestToEdit && (
                    <FadeIn enter enterOnMount className={classes.fadeIn}>
                        <Alert className={classes.costNote}>
                            <AlertText>
                                <FormattedMessage
                                    id="edit-birthday-kids.cost-note"
                                    defaultMessage="Hos Leo’s kan du alltid lägga till extra födelsedagsbarn och fira tillsammans. Du betalar då {price} extra per födelsedagsbarn. Det finns två extra speciella platser som födelsedagsbarnen kan turas om att sitta på."
                                    values={{
                                        price: formatNumber(booking.details.priceExtraBirthdayChild.pricevalue, {
                                            style: 'currency',
                                            currency: booking.details.priceExtraBirthdayChild.currency,
                                            minimumFractionDigits: 0,
                                        }),
                                    }}
                                />
                            </AlertText>
                        </Alert>

                        <BirthdayKidsList
                            booking={booking}
                            birthdayKids={booking.details.birthdayKids}
                            className={classes.list}
                            onAddClick={onAddClick}
                            onEditClick={onEditClick}
                        />

                        <PageButtons alignBottom>
                            {booking.details.status !== BookingStatus.Confirmed && (
                                <Button color="minionYellow" onClick={onContinueClick}>
                                    <FormattedMessage id="general.continue" defaultMessage="Gå vidare" />
                                </Button>
                            )}

                            <Button color="transparent" onClick={onBackClick}>
                                <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                            </Button>
                        </PageButtons>
                    </FadeIn>
                )}
            </PageContainer>
        </>
    );
};
