import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/common/Button/Button';
import { CarouselItem } from '../../../components/common/Carousel/CarouselItem';
import { Typography } from '../../../components/common/Typography/Typography';
import classes from './PackageCarouselItem.module.scss';

interface PackageCarouselItemProps {
    imageSrc: string;
    name: string;
    price: number;
    currency: string;
    articles: string[];
    information: string;
    onClick(): void;
}

/**
 * Carousel item for the package carousel on the select package page.
 */
export const PackageCarouselItem: FunctionComponent<PackageCarouselItemProps> = ({
    imageSrc,
    name,
    price,
    currency,
    articles,
    information,
    onClick,
}) => {
    const { formatNumber } = useIntl();
    const formattedPrice = formatNumber(price, { style: 'currency', currency, minimumFractionDigits: 0 });

    return (
        <CarouselItem imageSrc={imageSrc}>
            <div className={classes.content}>
                <Typography variant="title4" component="h3" className={classes.title}>
                    {name}
                </Typography>

                <Typography className={classes.price}>
                    <FormattedMessage
                        id="select-package.price-per-child"
                        defaultMessage="{price} per deltagare"
                        values={{ price: formattedPrice }}
                    />
                </Typography>
                <p className={classes.information} dangerouslySetInnerHTML={{ __html: information }}></p>
                <ul className={classes.articles}>
                    {articles.map((article) => (
                        <li key={article} className={classes.article} dangerouslySetInnerHTML={{ __html: article }} />
                    ))}
                </ul>

                <Button fullwidth color="minionYellow" className={classes.button} onClick={onClick}>
                    <FormattedMessage id="select-package.choose-button" defaultMessage="Välj detta" />
                </Button>
            </div>
        </CarouselItem>
    );
};
