/**
 * All the available booking stages.
 * The value of the enum represents the path name of the stage.
 */
export enum Stage {
    BookingOverview = 'overview',
    SelectPlayground = 'playground',
    SelectDate = 'date',
    SelectPackage = 'package',
    SelectAddons = 'addons',
    EditGuestCount = 'guest-count',
    EditBirthdayKids = 'birthday-kids',
    EditInvitation = 'invitation',
    EditGuests = 'guests',
    PreConfirmBooking = 'pre-confirm',
    ConfirmBooking = 'confirm',
    ConfirmedBooking = 'confirmed',
    BookingDetails = 'details',
    CancelBooking = 'cancel',
}
