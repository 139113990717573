import { useCountries } from 'hooks/useCountries';
import { FunctionComponent, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/common/Button/Button';
import { Confetti, ConfettiHandle } from '../../../components/common/Confetti/Confetti';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { FadeInImage } from '../../../components/utils/FadeInImage/FadeInImage';
import { useBooking } from '../../../contexts/BookingProvider';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { Stage } from '../../../model/stage';
import classes from './ConfirmedBooking.module.scss';
import ImageHopLopLeo from './hop-lop-leo.png';
import ImageLeo from './leo.png';

/**
 * The booking stage shown when the booking has been confirmed for the first time.
 */
export const ConfirmedBooking: FunctionComponent = () => {
    const booking = useBooking();
    const { openStage } = useBookingParams();
    const { currentCountry } = useCountries();

    const confetti = useRef<ConfettiHandle | null>(null);

    const handleImageLoaded = () => {
        setTimeout(() => {
            confetti.current?.launch({ spread: 90, particleCount: 150 });
        }, 300);
    };

    const handleInviteButtonClick = () => {
        openStage(Stage.EditInvitation, booking.details, true);
    };

    const handleOverviewButtonClick = () => {
        openStage(Stage.BookingOverview, booking.details, true);
    };

    return (
        <>
            <Confetti ref={confetti} />

            <PageContainer>
                <div className={classes.container}>
                    <FadeInImage
                        className={classes.image}
                        src={currentCountry.code === 'FI' ? ImageHopLopLeo : ImageLeo}
                        onLoad={handleImageLoaded}
                    />

                    <h1 className={classes.title}>
                        <FormattedMessage id="confirmed.title" defaultMessage="Nu är ditt kalas bokat!" />
                    </h1>

                    <p className={classes.text}>
                        {booking.details?.canChangeGuests ? (
                            <FormattedMessage
                                id="confirmed.text-invite"
                                defaultMessage="Nu kan du designa ditt inbjudningskort och bjuda in gäster!"
                            />
                        ) : (
                            <FormattedMessage
                                id="confirmed.text-no-invite"
                                defaultMessage="På översiktssidan kan du se en summering av ditt kalas!"
                            />
                        )}
                    </p>
                </div>

                <PageButtons>
                    {booking.details?.canChangeGuests && (
                        <Button color="minionYellow" onClick={handleInviteButtonClick}>
                            <FormattedMessage id="confirmed.button-invite" defaultMessage="Bjud in gäster" />
                        </Button>
                    )}

                    {booking.details?.canChangeGuests ? (
                        <Button color="transparent" onClick={handleOverviewButtonClick}>
                            <FormattedMessage id="confirmed.button-skip" defaultMessage="Hoppa över" />
                        </Button>
                    ) : (
                        <Button color="minionYellow" onClick={handleOverviewButtonClick}>
                            <FormattedMessage id="confirmed.button-overview" defaultMessage="Fortsätt till översikt" />
                        </Button>
                    )}
                </PageButtons>
            </PageContainer>
        </>
    );
};
