import { faRepeat } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { Article } from 'model';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertContent, AlertText } from '../../../components/common/Alert/Alert';
import { Button } from '../../../components/common/Button/Button';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { useApi } from '../../../contexts/ApiProvider';
import { useBooking } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import { AddonButton } from '../../../features/select-addons/AddonButton/AddonButton';
import { ChangeProduct } from '../../../features/select-addons/ChangeProduct/ChangeProduct';
import { ExpandableTextArea } from '../../../features/select-addons/ExpandableTextArea/ExpandableTextArea';
import { ExchangeableArticle, useAddons } from '../../../hooks/useAddons';
import useApiError from '../../../hooks/useApiError';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { BookingDetails, BookingStatus } from '../../../model/BookingDetails';
import { Stage } from '../../../model/stage';
import { hasEqualElements, isEmptyGuid } from '../../../utils';
import classes from './SelectAddons.module.scss';

export const SelectAddons: FunctionComponent = () => {
    const booking = useBooking();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { formatMessage, formatNumber } = useIntl();
    const { client } = useApi();
    const { openStage } = useBookingParams();

    const [exchangeableArticle, setExchangeableArticle] = useState<ExchangeableArticle | null>(null);
    const [allergiesOpen, setAllergiesOpen] = useState<boolean>(!!booking.details?.allergies);
    const [allergies, setAllergies] = useState<string>(booking.details?.allergies ?? '');
    const [infoOpen, setInfoOpen] = useState<boolean>(!!booking.details?.info ?? '');
    const [info, setInfo] = useState<string>(booking.details?.info ?? '');
    const addons = useAddons(booking.details?.partyPackage, booking.details?.articles);
    const { toastApiError } = useApiError();

    const currency = booking.details?.partyPackage?.country.currency ?? '';

    const handleAllergiesChange = (open: boolean, value: string) => {
        setAllergiesOpen(open);
        setAllergies(value);
    };

    const handleInfoChange = (open: boolean, value: string) => {
        setInfoOpen(open);
        setInfo(value);
    };

    const onChangeProductClose = (article?: Article) => {
        if (article && exchangeableArticle) {
            addons.removeArticle(exchangeableArticle.selected);
            addons.addArticle(article);
        }

        setExchangeableArticle(null);
    };

    const onContinueClick = async () => {
        if (!booking.details || !booking.details.partyPackage) {
            return;
        }

        // Map out the selected articles and filter out empty package article guids from the previous article guids as extra birthday kids
        // have no packagearticleguid.
        const selectedArticleGuids = addons.selectedArticles.map((a) => a.guid);
        const previousArticleGuids = booking.details.articles.map((ba) => ba.packagearticleguid).filter((guid) => !isEmptyGuid(guid));

        incrementLoader();

        let details: BookingDetails | undefined;
        try {
            // We can skip updating the selected articles if no changes were made
            if (!hasEqualElements(selectedArticleGuids, previousArticleGuids)) {
                await client.changeArticles(booking.details.guid, selectedArticleGuids);
            }

            const allergiesText = allergiesOpen ? allergies : '';
            const infoText = infoOpen ? info : '';
            details = await client.changeBooking(booking.details.guid, infoText, allergiesText);
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();

        if (details) {
            booking.set(details);

            if (details.status === BookingStatus.Confirmed && details.hasBookingChanges) {
                // Move directly to confirm page when changes need to be confirmed
                openStage(Stage.ConfirmBooking, details);
            } else if (details.status === BookingStatus.Confirmed) {
                // Jump to overview
                openStage(Stage.BookingOverview, details);
            } else {
                // Continue to edit guest count on un-confirmed bookings
                openStage(Stage.EditGuestCount, details);
            }
        }
    };

    const onBackClick = () => {
        if (booking.details?.status === BookingStatus.Confirmed) {
            // Jump back to overview or back to select package on confirmed bookings
            const stage = booking.details.hasBookingChanges ? Stage.SelectPackage : Stage.BookingOverview;
            openStage(stage, booking.details);
        } else {
            openStage(Stage.SelectPackage, booking.details);
        }
    };

    return exchangeableArticle ? (
        <ChangeProduct currency={currency} exchangeableArticle={exchangeableArticle} onClose={onChangeProductClose} />
    ) : (
        <PageContainer
            title={formatMessage({ id: 'select-addons.title', defaultMessage: 'Kalaspaket & tillval' })}
            onBackClick={onBackClick}
        >
            {booking.details?.status === BookingStatus.Confirmed && !booking.details.hasBookingChanges && (
                <Alert className={classes.alertBoxChangePackage}>
                    <AlertText type="info">
                        <FormattedMessage
                            id="select-addons.change-package-description"
                            defaultMessage="Byt kalaspaket eller ändra i befintligt paket här nedanför."
                        />
                    </AlertText>
                    <AlertContent>
                        <Button color="minionYellow" fullwidth onClick={() => openStage(Stage.SelectPackage, booking.details)}>
                            <FontAwesomeIcon icon={faRepeat} width={20} className={classes.alertBoxChangePackageIcon} />
                            <FormattedMessage id="select-addons.change-package-button" defaultMessage="Byt kalaspaket" />
                        </Button>
                    </AlertContent>
                </Alert>
            )}

            <h2 className={classes.sectionTitle}>{booking.details?.partyPackage?.name}</h2>

            {addons.selectedDefaultArticles.map((article) => (
                <AddonButton
                    key={article.selected.guid}
                    article={article.selected}
                    currency={currency}
                    exchangeable={article.options.length > 1}
                    selected={article.selected !== article.default}
                    disabled={article.options.length === 1}
                    className={classes.addonButton}
                    onClick={() => {
                        if (article.options.length > 1) {
                            setExchangeableArticle(article);
                        }
                    }}
                />
            ))}

            {addons.selectedAddonArticles.map((article) => (
                <AddonButton
                    key={article.guid}
                    article={article}
                    currency={currency}
                    selected
                    className={classes.addonButton}
                    onClick={() => addons.removeArticle(article)}
                />
            ))}

            <p className={classes.pricePerChild}>
                <span className={classes.pricePerChildAmount}>
                    {formatNumber(addons.price, { style: 'currency', currency, minimumFractionDigits: 0 })}
                </span>

                <span className={classes.pricePerChildText}>
                    <FormattedMessage id="select-addons.per-child" defaultMessage="per barn" />
                </span>
            </p>

            {addons.availableAddonArticles.length > 0 && (
                <h2 className={clsx(classes.sectionTitle, classes.sectionTitleAddons)}>
                    <FormattedMessage id="select-addons.upgrade-party-title" defaultMessage="Uppgradera ert kalas" />
                </h2>
            )}

            {addons.availableAddonArticles.map((article) => (
                <AddonButton
                    key={article.guid}
                    article={article}
                    currency={currency}
                    className={classes.addonButton}
                    onClick={() => addons.addArticle(article)}
                />
            ))}

            <div className={classes.textAreas}>
                <ExpandableTextArea
                    className={classes.allergiesTextArea}
                    title={formatMessage({ id: 'select-addons.allergies-title', defaultMessage: 'Allergier' })}
                    subtitle={formatMessage({
                        id: 'select-addons.allergies-description',
                        defaultMessage:
                            'Vet du redan nu att det finns inbjudna barn med allergier? Meddela oss så ordnar vi lämpligt alternativ!',
                    })}
                    text={formatMessage({
                        id: 'select-addons.allergies-guest-description',
                        defaultMessage: 'När du använder vår inbjudningsfunktion kan även dina gäster ange eventuella allergier.',
                    })}
                    placeholder={formatMessage({
                        id: 'select-addons.info-field-placeholder',
                        defaultMessage: 'Meddelande till leklandet',
                    })}
                    open={allergiesOpen}
                    value={allergies}
                    onChange={handleAllergiesChange}
                />

                <ExpandableTextArea
                    title={formatMessage({ id: 'select-addons.other-info-title', defaultMessage: 'Övrig information' })}
                    subtitle={formatMessage({
                        id: 'select-addons.other-info-description',
                        defaultMessage: 'Information som inte avser allergier',
                    })}
                    placeholder={formatMessage({
                        id: 'select-addons.info-field-placeholder',
                        defaultMessage: 'Meddelande till leklandet',
                    })}
                    open={infoOpen}
                    value={info}
                    onChange={handleInfoChange}
                />
            </div>

            <PageButtons alignBottom>
                <Button color="minionYellow" onClick={onContinueClick}>
                    {booking.details?.status === BookingStatus.Confirmed ? (
                        <FormattedMessage id="general.save" defaultMessage="Spara" />
                    ) : (
                        <FormattedMessage id="general.continue" defaultMessage="Gå vidare" />
                    )}
                </Button>

                <Button color="transparent" onClick={onBackClick}>
                    <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                </Button>
            </PageButtons>
        </PageContainer>
    );
};
