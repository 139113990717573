import { Alert, AlertText } from 'components/common/Alert/Alert';
import { addDays, addMonths, formatISO } from 'date-fns';
import { PartyLengthPicker } from 'features/select-date/PartyLengthPicker/PartyLengthPicker';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
    ApplicationNoticeApiClient,
    ApplicationNoticeDto,
    ApplicationNoticeType,
    IPartyBookingOpeningInfoDto,
    PartyBookingApiClient,
    PartyLocationConfigurationDto,
    RoomApiClient,
    RoomDto,
} from '../../../api/api.generated';
import { AlertDialog } from '../../../components/common/AlertDialog/AlertDialog';
import { Button } from '../../../components/common/Button/Button';
import { Confetti, ConfettiHandle } from '../../../components/common/Confetti/Confetti';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { ComboTextAreaField } from '../../../components/form/ComboTextAreaField/ComboTextAreaField';
import { useApi } from '../../../contexts/ApiProvider';
import { useBooking } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import { DatePicker } from '../../../features/select-date/DatePicker/DatePicker';
import { OpeningInfoPage } from '../../../features/select-date/OpeningInfoPage/OpeningInfoPage';
import { PlayTime, PlaytimePicker } from '../../../features/select-date/PlaytimePicker/PlaytimePicker';
import { RoomCarousel } from '../../../features/select-date/RoomCarousel/RoomCarousel';
import { useApiClient } from '../../../hooks/useApiClient';
import useApiError from '../../../hooks/useApiError';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { Room } from '../../../model';
import { BookingDetails, BookingSlot, BookingStatus } from '../../../model/BookingDetails';
import { FreeRoom } from '../../../model/freeDates';
import { Stage } from '../../../model/stage';
import { useAuth } from '../../../services/auth/AuthProvider';
import { Role } from '../../../services/auth/Role';
import { ApiError } from '../../../utils';
import ApiClient from '../../../utils/ApiClient';
import { Error } from '../../Error';
import classes from './SelectDate.module.scss';

export const SelectDate: FunctionComponent = () => {
    const booking = useBooking();
    const { locale, formatMessage } = useIntl();
    const { bookingParams, openStage } = useBookingParams();
    const { client } = useApi();
    const { roles } = useAuth();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const navigate = useNavigate();

    const roomApiClient = useApiClient(RoomApiClient);
    const partyApiClient = useApiClient(PartyBookingApiClient);
    const applicationNoticeApiClient = useApiClient(ApplicationNoticeApiClient);

    const confetti = useRef<ConfettiHandle | null>(null);
    const { apiError, setApiError, clearApiError, toastApiError } = useApiError();
    const [date, setDate] = useState<Date | null>(booking.details?.slot.date ?? null);
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(!date);
    const [openingInfo, setOpeningInfo] = useState<IPartyBookingOpeningInfoDto | null>(null);
    const [rooms, setRooms] = useState<RoomDto[] | null>(null);
    const [slots, setSlots] = useState<FreeRoom[] | null>(null);
    const [isLoadingSlots, setIsLoadingSlots] = useState<boolean>(false);
    const [selectedSlot, setSelectedSlot] = useState<BookingSlot | null>(booking.details?.slot ?? null);
    const [locationConfiguration, setLocationConfiguration] = useState<PartyLocationConfigurationDto | null>(null);
    const [partyLength, setPartyLength] = useState<number | null>(locationConfiguration?.partyLengthMinutes || null);
    const [playTimes, setPlayTimes] = useState<PlayTime[] | null>(null);
    const [notice, setNotice] = useState<ApplicationNoticeDto[] | null>(null);
    const [isLoadingPlayTimes, setIsLoadingPlayTimes] = useState<boolean>(false);
    const [selectedPlayTime, setSelectedPlayTime] = useState<PlayTime | null>(null);
    const [changeMessage, setChangeMessage] = useState<string>('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    // Allow Global and Location administrators to book whatever day they want.
    const minDate = useMemo(() => {
        if (roles.includes(Role.GlobalAdministrator) || roles.includes(Role.LocationAdministrator)) {
            return new Date();
        }

        return addDays(new Date(), 4);
    }, [roles]);

    // Slots are available for the upcoming 6 months
    const maxDate = useMemo(() => addMonths(new Date(), 6), []);

    // Set the current calendar view date (which month is displayed)
    const [viewDate, setViewDate] = useState<Date>(date ?? minDate);

    // Update date if booking slot is updated
    useEffect(() => {
        if (booking.details) {
            setDate(booking.details.slot.date);
            setViewDate(booking.details.slot.date);
            setSelectedSlot(booking.details.slot);
            setSelectedPlayTime({
                arrivalTime: booking.details.startTime,
                available: true,
                timeIntervals: [],
            });
            setPartyLength(booking.details?.partyLength || null);
        }
    }, [booking.details]);

    const loadPageData = () => {
        clearApiError();
        setOpeningInfo(null);
        setRooms(null);

        const controller = new AbortController();

        const playground = bookingParams.playground;
        if (playground) {
            incrementLoader();

            Promise.all([
                partyApiClient.apiPartyBookingOpeningInfoByPlaygroundIdGet(playground.guid, controller.signal),
                roomApiClient.apiRoomListByPlaygroundByPlaygroundidGet(playground.guid, controller.signal),
                partyApiClient.apiPartyBookingPartyLocationConfigurationGet(playground.guid, controller.signal),
            ])
                .then(([openingInfo, rooms, locationConfiguration]) => {
                    setOpeningInfo(openingInfo);
                    setRooms(rooms);
                    setLocationConfiguration(locationConfiguration);
                    if (!partyLength) {
                        setPartyLength(locationConfiguration.partyLengthMinutes);
                    }
                })
                .catch((error) => {
                    if (!controller.signal.aborted) {
                        setApiError(error);
                    }
                })
                .finally(decrementLoader);
        }

        return () => controller.abort();
    };

    // Load required data for the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadPageData, [
        bookingParams.playground,
        clearApiError,
        client,
        decrementLoader,
        incrementLoader,
        roomApiClient,
        partyApiClient,
        applicationNoticeApiClient,
        setApiError,
    ]);

    // Load free dates when changing playground or date
    useEffect(() => {
        if (!bookingParams.playground || !date) {
            return;
        }

        const { token, cancel } = ApiClient.createCancelToken();
        const formattedDate = formatISO(date, { representation: 'date' });

        setIsLoadingSlots(true);

        client
            .getFreeDates(bookingParams.playground.guid, formattedDate, token)
            .then((slots) => {
                setIsLoadingSlots(false);
                setSlots(slots);
            })
            .catch((error) => {
                const apiError = ApiError.fromError(error);
                if (apiError !== ApiError.Cancellation) {
                    toastApiError(error);
                    setDate(null);
                    setDatePickerOpen(true);
                    setIsLoadingSlots(false);
                }
            });

        return () => {
            cancel();
            setIsLoadingSlots(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingParams.playground, date]);

    useEffect(() => {
        if (!locationConfiguration?.partyEnableChoosePlayTime || !bookingParams.playground || !date || !selectedSlot) {
            return;
        }

        setIsLoadingPlayTimes(true);
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

        partyApiClient
            .apiPartyBookingPlaytimesGet(bookingParams.playground.guid, utcDate, partyLength || undefined, selectedSlot.timeslotGuid)
            .then((playtimes) => {
                setIsLoadingPlayTimes(false);
                if (!selectedPlayTime) {
                    const firstAvailable = playtimes?.filter((item) => item.available)[0];
                    if (firstAvailable) {
                        setSelectedPlayTime(firstAvailable);
                    }
                }
                setPlayTimes(playtimes);
            })
            .catch((error) => {
                const apiError = ApiError.fromError(error);
                if (apiError !== ApiError.Cancellation) {
                    toastApiError(error);
                    setPlayTimes(null);
                    setIsLoadingPlayTimes(false);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingParams.playground, date, selectedSlot, partyLength, locationConfiguration?.partyEnableChoosePlayTime]);

    useEffect(() => {
        if (!bookingParams.playground) {
            return;
        }

        applicationNoticeApiClient
            .apiApplicationNoticeByLocationByLocationIdGet(bookingParams.playground.guid, ApplicationNoticeType.PartyBooking)
            .then((notices) => {
                setNotice(notices);
            })
            .catch((error) => {
                const apiError = ApiError.fromError(error);
                if (apiError !== ApiError.Cancellation) {
                    toastApiError(error);
                }
            });
    }, [applicationNoticeApiClient, bookingParams.playground, toastApiError]);

    const onDatePickerChange = (date: Date) => {
        setDate(date);
        setSelectedSlot(null);
        setDatePickerOpen(false);
    };

    const changeReservationSlot = async () => {
        if (
            !booking.details ||
            !date ||
            !selectedSlot ||
            !selectedSlot.timeslotGuid ||
            (locationConfiguration?.partyEnableChoosePlayTime && !selectedPlayTime)
        ) {
            return;
        }

        incrementLoader();

        let details: BookingDetails | undefined;

        try {
            details = await client.changeReservation(
                booking.details.reservationGuid,
                selectedSlot.roomGuid,
                selectedSlot.timeslotGuid,
                formatISO(selectedSlot.date, {
                    representation: 'date',
                }),
                selectedPlayTime?.arrivalTime || null,
                partyLength,
            );

            if (details.changeReservation) {
                details = await client.confirmDateChange(details.guid, details.changeReservation.reservationid, changeMessage);
            }
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();

        if (details) {
            booking.set(details);

            if (details.status === BookingStatus.Confirmed) {
                openStage(Stage.BookingOverview, details);
            } else {
                openStage(Stage.SelectPackage, details);
            }
        }
    };

    const changePlayTime = (playtime: PlayTime) => {
        setSelectedPlayTime(playtime);
    };

    const onConfirmClick = async () => {
        if (
            !date ||
            !selectedSlot ||
            !selectedSlot.timeslotGuid ||
            (locationConfiguration?.partyEnableChoosePlayTime && !selectedPlayTime)
        ) {
            return;
        }

        if (booking.details) {
            // If our currently reserved slot is the same as the selected slot, skip confirming
            const isBookedSlot = booking.isBookedSlot(selectedSlot);
            const isTimeChanged =
                booking.details.partyLength !== partyLength || booking.details.startTime !== selectedPlayTime?.arrivalTime;
            if (isBookedSlot && !isTimeChanged && booking.details.status === BookingStatus.Reserved) {
                // Continue to select package as the booking is not confirmed-booking yet
                return openStage(Stage.SelectPackage, booking.details);
            } else if (isBookedSlot && !isTimeChanged) {
                // Go back to booking-overview
                return openStage(Stage.BookingOverview, booking.details);
            }

            if (booking.details.status === BookingStatus.Confirmed) {
                setConfirmDialogOpen(true);
            } else {
                await changeReservationSlot();
            }
        } else {
            incrementLoader();

            let details: BookingDetails | undefined;

            try {
                details = await client.makeReservation(
                    selectedSlot.roomGuid,
                    selectedSlot.timeslotGuid,
                    formatISO(selectedSlot.date, {
                        representation: 'date',
                    }),
                    selectedPlayTime?.arrivalTime || null,
                    partyLength,
                );
            } catch (error) {
                toastApiError(error);
            }

            decrementLoader();

            if (details) {
                booking.set(details);

                // Replace current route with a route containing the booking guid
                openStage(Stage.SelectDate, details, true);
                openStage(Stage.SelectPackage, details);
            }
        }
    };

    const onBackClick = () => {
        if (!booking.details || booking.details.status === BookingStatus.Reserved) {
            // When we have no reservation or when we've just reserved a slot, go back to the select playground without
            // any playground in the url.
            navigate(`/${locale}/${Stage.SelectPlayground}`);
        } else {
            openStage(Stage.BookingOverview, booking.details);
        }
    };

    const onConfirmDialogClose = async (confirmed: boolean) => {
        setConfirmDialogOpen(false);

        if (confirmed) {
            await changeReservationSlot();
        }
    };

    /**
     * Called when the opening countdown has ended.
     */
    const onOpeningCountdownEnded = () => {
        setOpeningInfo((info) => info && { ...info, content: null });

        setTimeout(() => confetti.current?.launch({ spread: 120, particleCount: 100, startVelocity: 80, origin: { x: 0.25, y: 1 } }), 200);
        setTimeout(() => confetti.current?.launch({ spread: 120, particleCount: 100, startVelocity: 80, origin: { x: 0.5, y: 1 } }), 400);
        setTimeout(() => confetti.current?.launch({ spread: 120, particleCount: 100, startVelocity: 80, origin: { x: 0.75, y: 1 } }), 600);
    };

    if (apiError) {
        return <Error error={apiError} onRetryClick={loadPageData} />;
    }

    if (!bookingParams.playground || !rooms || !openingInfo) {
        return null;
    }

    if (!booking.details && openingInfo.content !== null) {
        // Only show the countdown if we're not editing a booking
        return (
            <OpeningInfoPage
                date={openingInfo.date}
                content={openingInfo.content}
                onCountdownEnded={onOpeningCountdownEnded}
                onBackClick={onBackClick}
            />
        );
    }

    // If the selected slot is the same as the confirmed slot, we do not allow the user to confirm it.
    // If choose play time is enabled we allow confirming the same slot if the play time has changed.
    const canConfirm =
        !!selectedSlot &&
        (!locationConfiguration?.partyEnableChoosePlayTime || !!selectedPlayTime) &&
        (booking.details?.status !== BookingStatus.Confirmed ||
            !booking.isBookedSlot(selectedSlot) ||
            (selectedPlayTime !== null && (selectedPlayTime as PlayTime).arrivalTime !== booking.details?.startTime) ||
            (partyLength !== null && partyLength !== booking.details?.partyLength));

    // Function to determine the correct type based on the icon
    const getAlertType = (icon: string): 'error' | 'warning' | 'info' | 'paw' | undefined => {
        switch (icon) {
            case 'error':
            case 'warning':
            case 'info':
            case 'paw':
                return icon as 'error' | 'warning' | 'info' | 'paw';
            default:
                return undefined;
        }
    };

    return (
        <>
            <AlertDialog
                type="warning"
                open={confirmDialogOpen}
                text={formatMessage({
                    id: 'select-date.confirm-date-change-title',
                    defaultMessage: 'Är du säker på att du vill ändra tid eller rum för kalaset?',
                })}
                className={classes.dateChangeDialog}
            >
                <Button color="minionYellow" onClick={() => onConfirmDialogClose(true)}>
                    <FormattedMessage id="general.yes" defaultMessage="Ja" />
                </Button>

                <Button color="grandparentGrey900" onClick={() => onConfirmDialogClose(false)}>
                    <FormattedMessage id="general.cancel" defaultMessage="Avbryt" />
                </Button>
            </AlertDialog>

            {notice?.map((item) => (
                <Alert className={classes.applicationNotice} key={item.guid}>
                    <AlertText type={getAlertType(item.icon)}>
                        <FormattedMessage id="application-notice-text" defaultMessage={item.text} />
                    </AlertText>
                </Alert>
            ))}

            <PageContainer title={formatMessage({ id: 'select-date.title', defaultMessage: 'Datum & rum' })} onBackClick={onBackClick}>
                <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    open={datePickerOpen}
                    onToggle={setDatePickerOpen}
                    viewDate={viewDate}
                    onViewDateChange={setViewDate}
                    onChange={onDatePickerChange}
                    value={date}
                />

                <RoomCarousel
                    date={date}
                    rooms={rooms as Room[]}
                    slots={slots}
                    isLoadingSlots={isLoadingSlots}
                    value={selectedSlot}
                    className={classes.carousel}
                    disabled={!date}
                    onChange={(slot) => {
                        setSelectedSlot(slot);
                        setSelectedPlayTime(null);
                    }}
                />

                {locationConfiguration?.partyEnableChoosePlayTime && selectedSlot && (
                    <>
                        <PartyLengthPicker
                            maxValue={locationConfiguration.partyLengthMinutes || 180}
                            value={partyLength}
                            onChange={setPartyLength}
                        />

                        <PlaytimePicker
                            isLoading={isLoadingPlayTimes}
                            playtimes={playTimes}
                            partyLengthHours={Math.floor((partyLength || 0) / 60)}
                            value={selectedPlayTime}
                            onChange={changePlayTime}
                        />
                    </>
                )}

                {booking.details?.status === BookingStatus.Confirmed && (
                    <ComboTextAreaField
                        title={formatMessage({ id: 'select-date.change-date-message-title', defaultMessage: 'Meddelande' })}
                        text={formatMessage({
                            id: 'select-date.change-date-message-description',
                            defaultMessage:
                                'Genom att byta datum för kalaset kommer ett meddelande att skickas ut till alla dina gäster. Var god skriv ett meddelande till dem.',
                        })}
                        placeholder={formatMessage({
                            id: 'select-date.change-date-message-placeholder',
                            defaultMessage: 'Meddelande till gästerna',
                        })}
                        className={classes.dateChangeMessage}
                        value={changeMessage}
                        onChange={(event) => setChangeMessage(event.target.value)}
                    />
                )}

                <PageButtons alignBottom>
                    <Button disabled={!canConfirm} color="minionYellow" onClick={onConfirmClick}>
                        {booking.details?.status === 'Confirmed' ? (
                            <FormattedMessage id="general.save" defaultMessage="Spara" />
                        ) : (
                            <FormattedMessage id="general.continue" defaultMessage="Gå vidare" />
                        )}
                    </Button>

                    <Button color="transparent" onClick={onBackClick}>
                        <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                    </Button>
                </PageButtons>
            </PageContainer>

            <Confetti ref={confetti} />
        </>
    );
};
