import { FunctionComponent, ReactNode } from 'react';
import { FadeInImage } from '../../utils/FadeInImage/FadeInImage';
import classes from './CarouselItem.module.scss';

interface CarouselItemProps {
    imageSrc: string;
    children: ReactNode;
}

/**
 * Single design system carousel item.
 *
 * The content is placed below the image but kept at the top in the DOM to get the correct render order.
 */
export const CarouselItem: FunctionComponent<CarouselItemProps> = ({ imageSrc, children }) => {
    return (
        <div className={classes.container}>
            <div className={classes.content}>{children}</div>
            <div className={classes.imageWrapper}>
                <FadeInImage src={imageSrc} className={classes.image} />
            </div>
        </div>
    );
};
