import { FunctionComponent } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Typography } from '../../../components/common/Typography/Typography';
import classes from './InviteDate.module.scss';

interface InviteDateProps {
    date: Date;
    time: string;
    className?: string;
}

export const InviteDate: FunctionComponent<InviteDateProps> = ({ date, time, className }) => {
    const containerClassNames = [classes.container];
    if (className) {
        containerClassNames.push(className);
    }

    return (
        <div className={containerClassNames.join(' ')}>
            <Typography variant="display5" component="div" className={classes.title}>
                <FormattedDate value={date} month="long" />
            </Typography>
            <div className={classes.content}>
                <Typography variant="display5" component="div" className={classes.date}>
                    <FormattedDate value={date} day="numeric" />
                </Typography>
                <Typography variant="display5" component="div" className={classes.time}>
                    <FormattedMessage id="invite-card.title-time" defaultMessage="kl {time}" values={{ time }} />
                </Typography>
            </div>
        </div>
    );
};
