import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Alert, AlertContent, AlertText } from 'components/common/Alert/Alert';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/common/Button/Button';
import { FontAwesomeIcon } from '../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { PageContainer } from '../../components/common/PageContainer/PageContainer';
import { useApi } from '../../contexts/ApiProvider';
import { useGlobalLoading } from '../../contexts/GlobalLoadingProvider';
import PartyListItem from '../../features/my-bookings/PartyListItem/PartyListItem';
import useApiError from '../../hooks/useApiError';
import { useBookingParams } from '../../hooks/useBookingParams';
import { useCountries } from '../../hooks/useCountries';
import { BookingDetails } from '../../model/BookingDetails';
import { PartyBookingListDto, PartyBookingListPartyItemDto } from '../../model/myBookings';
import { Stage } from '../../model/stage';
import { useAuth } from '../../services/auth/AuthProvider';
import { ApiError } from '../../utils';
import ApiClient from '../../utils/ApiClient';
import { HttpStatusCode } from '../../utils/HttpStatusCode';
import { Error } from '../Error';
import classes from './MyBookings.module.scss';

export const MyBookings: FunctionComponent = () => {
    const { formatMessage, locale } = useIntl();
    const { currentCountry, getCountryByCulture } = useCountries();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { client } = useApi();
    const { clearApiError, apiError, setApiError, toastApiError } = useApiError();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { openStage, stagePath } = useBookingParams();
    const [bookings, setBookings] = useState<PartyBookingListDto | null>(null);

    const newBookingLink = useMemo(() => {
        return `/${locale}`;
    }, [locale]);

    const fetchMyBookings = () => {
        const { token, cancel } = ApiClient.createCancelToken();
        setBookings(null);
        clearApiError();

        if (user) {
            incrementLoader();

            client.getMyBookings(token).then(setBookings).catch(setApiError).finally(decrementLoader);
        } else {
            setApiError(new ApiError(undefined, HttpStatusCode.Unauthorized));
        }

        return cancel;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchMyBookings, []);

    const fetchAndOpenBooking = async (item: PartyBookingListPartyItemDto) => {
        incrementLoader();

        let details: BookingDetails | undefined;
        try {
            details = await client.getBooking(item.bookingid);
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();

        if (details && details.country.slug === currentCountry.slug) {
            openStage(Stage.BookingOverview, details);
        } else if (details) {
            const country = getCountryByCulture(details.country.culture);
            const url = country?.urlParty + stagePath(Stage.BookingOverview, details, true);
            window.location.assign(url);
        }
    };

    const onNewBookingClick = () => {
        navigate(newBookingLink);
    };

    if (apiError) {
        return <Error error={apiError} onRetryClick={fetchMyBookings} />;
    }

    if (!bookings) {
        return null;
    }

    return (
        <PageContainer title={formatMessage({ id: 'my-bookings.title', defaultMessage: 'Kalasbokning' })}>
            {!bookings.parties.length ? (
                <Alert>
                    <AlertText type="info">
                        <FormattedMessage
                            id="my-bookings.empty-text"
                            defaultMessage="Du har tyvärr inga bokade kalas att se fram emot, klicka på knappen för att skapa en ny bokning."
                        />
                    </AlertText>

                    <AlertContent>
                        <Button color="minionYellow" onClick={onNewBookingClick} fullwidth>
                            <FormattedMessage id="my-bookings.new-booking-button" defaultMessage="Boka nytt kalas" />
                        </Button>
                    </AlertContent>
                </Alert>
            ) : (
                <>
                    <Button color={'minionYellow'} onClick={onNewBookingClick} className={classes.newBookingButton}>
                        <FontAwesomeIcon className={classes.newBookingButtonIcon} icon={faPlus} width={14.12} />
                        <FormattedMessage id="my-bookings.new-booking-button" defaultMessage="Boka nytt kalas" />
                    </Button>

                    <h2 className={classes.title}>
                        <FormattedMessage id="my-bookings.list-title" defaultMessage="Dina bokade kalas" />
                    </h2>

                    <div className={classes.items}>
                        {bookings.parties.map((item) => {
                            return <PartyListItem key={item.bookingid} item={item} onClick={fetchAndOpenBooking} />;
                        })}
                    </div>
                </>
            )}
        </PageContainer>
    );
};
