import { FunctionComponent } from 'react';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonContent,
    ComboButtonTitle,
    ExpandedComboButton,
} from '../../../components/common/ComboButton/ComboButton';
import { Switch } from '../../../components/common/Switch/Switch';
import { Typography } from '../../../components/common/Typography/Typography';
import { TextAreaField } from '../../../components/form/TextAreaField/TextAreaField';
import { Collapse } from '../../../components/utils/Collapse/Collapse';
import classes from './ExpandableTextArea.module.scss';

interface ExpandableTextAreaProps {
    title: string;
    subtitle: string;
    text?: string;
    placeholder?: string;
    open: boolean;
    value: string;
    className?: string;
    onChange(open: boolean, value: string): void;
}

/**
 * Uses the ExpandedComboButton to display a text area when the Switch is on.
 *
 * Usage:
 * <ExpandableTextArea
 *     title="Information"
 *     subtitle="All information you want to enter."
 *     text="An additional text that will only be displayed when the Switch is on."
 *     placeholder="Placeholder for the textarea"
 *     open={open}
 *     value={value}
 *     onChange={handleChange}
 * />
 */
export const ExpandableTextArea: FunctionComponent<ExpandableTextAreaProps> = ({
    title,
    subtitle,
    text,
    placeholder,
    open,
    value,
    className,
    onChange,
}) => {
    const handleSwitchChange = (newValue: boolean) => {
        onChange(newValue, value);
    };

    const handleFieldChange = (newValue: string) => {
        onChange(open, newValue);
    };

    return (
        <ExpandedComboButton className={className}>
            <ComboButton component="div">
                <ComboButtonContent>
                    <ComboButtonTitle>{title}</ComboButtonTitle>
                </ComboButtonContent>
                <ComboButtonAction>
                    <Switch on={open} onChange={handleSwitchChange} />
                </ComboButtonAction>
            </ComboButton>

            <div className={classes.content}>
                <Typography variant="display6" className={classes.subtitle}>
                    {subtitle}
                </Typography>

                <Collapse open={open}>
                    {text && <Typography className={classes.text}>{text}</Typography>}

                    <TextAreaField
                        label={title}
                        className={classes.field}
                        rows={4}
                        value={value}
                        placeholder={placeholder}
                        onChange={(event) => handleFieldChange(event.target.value)}
                    />
                </Collapse>
            </div>
        </ExpandedComboButton>
    );
};
