import { createContext, useContext } from 'react';
import { Guest } from '../model';
import { BookingDetails, BookingSlot } from '../model/BookingDetails';

export interface BookingService {
    /**
     * Clears the current booking.
     */
    clear: () => void;

    /**
     * Sets the current booking.
     * A flag to indicate if this is a new booking can be set to force the booking page to check that we're on a allowed stage.
     */
    set: (details: BookingDetails, newBooking?: boolean) => void;

    /**
     * Replaces the guest with the specified.
     */
    setGuest: (guest: Guest, locale: string) => void;

    /**
     * Checks if the slot passed in matches the currently reserved/confirmed slot.
     */
    isBookedSlot: (other?: Pick<BookingSlot, 'date' | 'roomGuid' | 'startTime'>) => boolean;

    /**
     * Contains all the details of the currently loaded booking.
     */
    details?: BookingDetails;
}

/**
 * Holds the state of the current booking.
 */
const BookingContext = createContext<BookingService>({} as BookingService);

export const BookingProvider = BookingContext.Provider;

/**
 * Hook to get the value of the closest BookingContext.
 */
export const useBooking = () => {
    return useContext(BookingContext);
};
