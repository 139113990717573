import { faSignOut, faUser } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { FunctionComponent, MouseEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../Button/Button';
import { FontAwesomeIcon } from '../FontAwesomeIcon/FontAwesomeIcon';
import { IconButton } from '../IconButton/IconButton';
import classes from './SidebarUser.module.scss';

interface SidebarUserProps {
    user: {
        userName: string | null;
        firstName: string | null;
        lastName: string | null;
    } | null;
    profileEditLink?: string;
    className?: string;
    onProfileEditClick?: MouseEventHandler<HTMLAnchorElement>;
    onLoginClick?: MouseEventHandler<HTMLButtonElement>;
    onLogoutClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * The user section in the sidebar.
 */
export const SidebarUser: FunctionComponent<SidebarUserProps> = ({
    user,
    profileEditLink,
    className,
    onProfileEditClick,
    onLoginClick,
    onLogoutClick,
}) => {
    const { formatMessage } = useIntl();

    if (!user) {
        return (
            <Button color="sunnyYellow" className={className} fullwidth onClick={onLoginClick}>
                <FormattedMessage id="sidebar.button-sign-in" defaultMessage="Logga in" />
            </Button>
        );
    }

    // Use first characters from first and lastname
    let chars = user.firstName?.trim().substring(0, 1) ?? '';
    chars += user.lastName?.trim().substring(0, 1) ?? '';

    // Fall back to username
    chars ||= user.userName?.trim().substring(0, 2) ?? '';

    // Fall back to icon
    chars ||= '👤';

    return (
        <div className={clsx(classes.container, className)}>
            {profileEditLink && (
                <IconButton
                    component="a"
                    href={profileEditLink}
                    title={formatMessage({ id: 'sidebar.button-edit-profile', defaultMessage: 'Min profil' })}
                    className={classes.iconProfile}
                    rippleClassName={classes.iconRipple}
                    onClick={onProfileEditClick}
                >
                    <FontAwesomeIcon icon={faUser} height={20} />
                </IconButton>
            )}

            <div className={classes.chars}>{chars.toUpperCase()}</div>

            <IconButton
                title={formatMessage({ id: 'sidebar.button-sign-out', defaultMessage: 'Logga ut' })}
                className={classes.iconSignOut}
                rippleClassName={classes.iconRipple}
                onClick={onLogoutClick}
            >
                <FontAwesomeIcon icon={faSignOut} width={20} />
            </IconButton>
        </div>
    );
};
