import { User } from 'oidc-client-ts';
import { ApplicationUserDto } from '../../api/api.generated';

/**
 * Represents a successful authentication result.
 */
export class AuthenticationResult {
    constructor(
        /** The OIDC Client user */
        public readonly user: User,

        /** The application user received from the backend */
        public readonly applicationUser: ApplicationUserDto,
    ) {}
}
