import clsx from 'clsx';
import { FunctionComponent } from 'react';
import classes from './PageButtons.module.scss';

interface PageButtonsProps {
    alignBottom?: boolean;
    className?: string;
}

/**
 * Used at the bottom of the PageContainer to align the buttons at the bottom of the screen and center with an intrinsic
 * width.
 */
export const PageButtons: FunctionComponent<PageButtonsProps> = ({ alignBottom, className, children }) => {
    const classNames = clsx(classes.container, className, {
        [classes.alignBottom]: alignBottom,
    });

    return <div className={classNames}>{children}</div>;
};
