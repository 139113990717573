import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { LoginIntent } from '../../../api/api-identity.generated';
import { useAuth } from '../../../services/auth/AuthProvider';
import { Button } from '../Button/Button';
import { PageButtons } from '../PageButtons/PageButtons';
import { Typography } from '../Typography/Typography';
import classes from './ErrorView.module.scss';

interface ErrorViewProps {
    title: string;
    message: string;
    showLoginButton?: boolean;
    onRetryClick?(): void;
}

export const ErrorView: FunctionComponent<ErrorViewProps> = ({ title, message, showLoginButton, onRetryClick }) => {
    const { locale } = useIntl();
    const { signIn } = useAuth();
    const navigate = useNavigate();

    const onLoginClick = () => {
        signIn(LoginIntent.Default);
    };

    const onGoToStartClick = () => {
        navigate(`/${locale}/`);
    };

    return (
        <>
            <div className={classes.container}>
                <Typography variant="display1" className={classes.title}>
                    {title}
                </Typography>

                <Typography variant="preamble" className={classes.text}>
                    {message}
                </Typography>
            </div>

            <PageButtons alignBottom>
                {showLoginButton && (
                    <Button color="minionYellow" onClick={onLoginClick}>
                        <FormattedMessage id="general.login" defaultMessage="Logga in" />
                    </Button>
                )}

                {!showLoginButton && onRetryClick && (
                    <Button color="minionYellow" onClick={onRetryClick}>
                        <FormattedMessage id="general.retry" defaultMessage="Försök igen" />
                    </Button>
                )}

                <Button color={showLoginButton || onRetryClick ? 'transparent' : 'minionYellow'} onClick={onGoToStartClick}>
                    <FormattedMessage id="general.to-start" defaultMessage="Till startsidan" />
                </Button>
            </PageButtons>
        </>
    );
};
