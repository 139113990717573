import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { ApiError } from '../utils';

interface ApiErrorService {
    apiError?: ApiError;
    setApiError(error: unknown): void;
    toastApiError(error: unknown): void;
    clearApiError(): void;
}

/**
 * Simple hook to simplify handling of API errors in the app.
 */
const useApiError = (): ApiErrorService => {
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    const [error, setError] = useState<ApiError | undefined>();

    const setApiError = useCallback((err: unknown): void => {
        const apiError = ApiError.fromError(err);
        if (apiError === ApiError.Cancellation) {
            // Skip state updates for cancelled requests
            return;
        }

        setError(apiError);
    }, []);

    const toastApiError = useCallback(
        (err: unknown): void => {
            const apiError = ApiError.fromError(err);
            if (apiError === ApiError.Cancellation) {
                // Skip toasting API errors
                return;
            }

            const fallbackMessage = formatMessage({ id: 'general.error-unexpected', defaultMessage: 'Ett oväntat fel har inträffat.' });
            const message = apiError.getMessage(fallbackMessage);

            enqueueSnackbar(message, {
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'bottom',
                },
            });
        },
        [enqueueSnackbar, formatMessage],
    );

    const clearApiError = useCallback((): void => {
        setError(undefined);
    }, []);

    return { apiError: error, setApiError, toastApiError, clearApiError };
};

export default useApiError;
