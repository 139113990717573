import { Article } from 'model';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/common/Button/Button';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { ExchangeableArticle } from '../../../hooks/useAddons';
import { AddonButton } from '../AddonButton/AddonButton';
import classes from './ChangeProduct.module.scss';

interface ChangeProductProps {
    exchangeableArticle: ExchangeableArticle;
    currency: string;
    onClose: (article?: Article) => void;
}

/**
 * Dialog used to change a product to another exchangeable product.
 *
 * Usage:
 * <ChangeProduct
 *     currency="SEK"
 *     exchangeableArticle={article}
 *     onClose={() => setOpen(false)}
 * />
 */
export const ChangeProduct: FunctionComponent<ChangeProductProps> = ({ exchangeableArticle, currency, onClose }) => {
    const { formatMessage } = useIntl();
    const [selectedArticle, setSelectedArticle] = useState(exchangeableArticle.selected);

    return (
        <PageContainer title={formatMessage({ id: 'select-addons.upgrade-title', defaultMessage: 'Uppgradera' })} onBackClick={onClose}>
            <div className={classes.addons}>
                {exchangeableArticle.options.map((article) => (
                    <AddonButton
                        key={article.guid}
                        className={classes.addon}
                        article={article}
                        currency={currency}
                        hidePrice={exchangeableArticle.default === article}
                        selected={selectedArticle === article}
                        noRadius
                        onClick={() => setSelectedArticle(article)}
                    />
                ))}
            </div>

            <PageButtons alignBottom>
                <Button color="minionYellow" onClick={() => onClose(selectedArticle)}>
                    <FormattedMessage id="general.save" defaultMessage="Spara" />
                </Button>

                <Button color="transparent" onClick={() => onClose()}>
                    <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                </Button>
            </PageButtons>
        </PageContainer>
    );
};
