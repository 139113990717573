import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import Dragdealer from 'dragdealer';
import { Children, FunctionComponent, ReactNode, useCallback, useRef, useState } from 'react';
import { FontAwesomeIcon } from '../FontAwesomeIcon/FontAwesomeIcon';
import { IconButton } from '../IconButton/IconButton';
import { Typography } from '../Typography/Typography';
import classes from './Carousel.module.scss';

interface CarouselProps {
    title: string;
    initialIndex?: number;
    className?: string;
    children: ReactNode;
}

/**
 * Design system Carousel with auto-alignment and spacing between items.
 */
export const Carousel: FunctionComponent<CarouselProps> = ({ title, initialIndex = 0, className, children }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const dealerRef = useRef<Dragdealer | null>(null);
    const [index, setIndex] = useState(initialIndex);

    const childCount = Children.count(children);

    const onControlClick = (direction: number) => {
        dealerRef.current?.setStep(index + 1 + direction, 0);
    };

    const setContainerRef = useCallback(
        (element: HTMLDivElement | null) => {
            // Check if the element changed. If so, we need to re-create the dragdealer.
            if (containerRef.current !== element && dealerRef.current) {
                dealerRef.current.unbindEventListeners();
                dealerRef.current = null;
            }

            // If we just mounted an element, create a dragdealer instance.
            if (!dealerRef.current && element) {
                // Calculate the x position by multiplying x with our initial index
                let initialX = 0;

                if (childCount > 1 && index < childCount) {
                    initialX = (1 / (childCount - 1)) * index;
                }

                dealerRef.current = new Dragdealer(element, {
                    x: initialX,
                    css3: true,
                    speed: 0.3,
                    loose: true,
                    steps: childCount,
                    handleClass: classes.slides,
                    callback() {
                        if (dealerRef.current) {
                            const [x] = dealerRef.current.getStep();
                            setIndex(x - 1);
                        }
                    },
                });
            }

            containerRef.current = element;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [childCount],
    );

    const pagerItems: ReactNode[] = [];
    for (let i = 0; i < childCount; i += 1) {
        const classNames = clsx(classes.pagerItem, {
            [classes.pagerItemSelected]: index === i,
        });

        pagerItems.push(<div key={i} className={classNames} />);
    }

    return (
        <div className={className}>
            <div className={classes.header}>
                <Typography variant="title5" className={classes.title}>
                    {title}
                </Typography>

                <IconButton
                    disabled={index === 0}
                    className={classes.buttonPrevious}
                    rippleClassName={classes.buttonRipple}
                    onClick={() => onControlClick(-1)}
                >
                    <FontAwesomeIcon icon={faChevronLeft} height={14} />
                </IconButton>

                <IconButton
                    disabled={index === childCount - 1}
                    className={classes.buttonNext}
                    rippleClassName={classes.buttonRipple}
                    onClick={() => onControlClick(1)}
                >
                    <FontAwesomeIcon icon={faChevronRight} height={14} />
                </IconButton>
            </div>

            <div ref={setContainerRef}>
                <div className={classes.slides}>{children}</div>
            </div>

            <div className={classes.pager}>{pagerItems}</div>
        </div>
    );
};
