import { differenceInCalendarDays } from 'date-fns';
import { useEffect } from 'react';
import { Config } from '../config';
import { BookingDetails, BookingStatus } from '../model/BookingDetails';
import { Stage } from '../model/stage';
import { useAuth } from '../services/auth/AuthProvider';
import { useBookingParams } from './useBookingParams';

/**
 * Hook to ensure that the stage we're on is a valid stage for the current booking state.
 * If the stage is not valid, a redirect will occur to the correct stage.
 */
export const useBookingNavigationGuard = (details?: BookingDetails) => {
    const { user } = useAuth();
    const { bookingParams, openStage } = useBookingParams();

    useEffect(() => {
        // Don't run the check if we don't have any booking details or if we're not on a specific stage.
        if (!bookingParams.stage || !bookingParams.guid || !details) {
            return;
        }

        // We do not allow the user to view the overview, edit the invitation card or guest list, cancel booking or view
        // booking details when the booking is not confirmed.
        if (
            details.status !== BookingStatus.Confirmed &&
            [
                Stage.BookingOverview,
                Stage.EditInvitation,
                Stage.EditGuests,
                Stage.CancelBooking,
                Stage.BookingDetails,
                Stage.ConfirmedBooking,
            ].includes(bookingParams.stage)
        ) {
            openStage(Stage.SelectDate, details, true);
            return;
        }

        // Check that the stage we're on is allowed depending on how many stages we've completed.
        if (details.status !== BookingStatus.Confirmed) {
            // As we don't have to select any addons, we can also skip straight to edit guest count
            const canSelectAddons = !!details.partyPackage;
            const canEditBirthdayKids = canSelectAddons && details.guestCount > 0;
            const canConfirm = canEditBirthdayKids && details.birthdayKids.length > 0;

            let lastAllowedStage: Stage;
            if (canConfirm) {
                lastAllowedStage = Stage.ConfirmBooking;
            } else if (canEditBirthdayKids) {
                lastAllowedStage = Stage.EditBirthdayKids;
            } else if (canSelectAddons) {
                lastAllowedStage = Stage.EditGuestCount;
            } else {
                lastAllowedStage = Stage.SelectPackage;
            }

            if (
                ([Stage.PreConfirmBooking, Stage.ConfirmBooking].includes(bookingParams.stage) && !canConfirm) ||
                ([Stage.EditBirthdayKids].includes(bookingParams.stage) && !canEditBirthdayKids) ||
                ([Stage.SelectAddons, Stage.EditGuestCount].includes(bookingParams.stage) && !canSelectAddons)
            ) {
                openStage(lastAllowedStage, details, true);
                return;
            }
        }

        // If guest count has been changed we will force the user to the confirmation stage.
        if (details.hasChangedGuestCount && bookingParams.stage !== Stage.ConfirmBooking) {
            openStage(Stage.ConfirmBooking, details, true);
            return;
        }

        // If we have other booking changes than guest count, force the user to the package/addons select or confirm stage.
        if (details.hasBookingChanges && ![Stage.SelectPackage, Stage.SelectAddons, Stage.ConfirmBooking].includes(bookingParams.stage)) {
            openStage(Stage.SelectPackage, details, true);
            return;
        }

        // If we have no booking changes, we will force the user away from the pre-confirm/confirm stage.
        if (
            details.status === BookingStatus.Confirmed &&
            !details.hasBookingChanges &&
            [Stage.PreConfirmBooking, Stage.ConfirmBooking].includes(bookingParams.stage)
        ) {
            openStage(Stage.BookingOverview, details, true);
            return;
        }

        // If we're on the confirm page but a user is not signed in, go back to pre-confirm stage.
        if (bookingParams.stage === Stage.ConfirmBooking && !user) {
            openStage(Stage.PreConfirmBooking, details, true);
            return;
        }

        // We do not allow editing the invitation when we have guests.
        if (details.guests.length && bookingParams.stage === Stage.EditInvitation) {
            openStage(Stage.EditGuests, details, true);
            return;
        }

        // Ensure that we're on an allowed stage depending on what backend tell us we can change.
        if (details.status === BookingStatus.Confirmed) {
            const daysToParty = differenceInCalendarDays(details.slot.date, new Date());

            const disallowedDateStage = !details.canChangeDate && bookingParams.stage === Stage.SelectDate;
            const disallowedArticleStage =
                !details.canChangeArticles && [Stage.SelectPackage, Stage.SelectAddons].includes(bookingParams.stage);
            const disallowedGuestStage =
                !details.canChangeGuests &&
                [Stage.EditGuestCount, Stage.EditBirthdayKids, Stage.EditInvitation].includes(bookingParams.stage);
            const disallowedCancelStage = daysToParty < Config.cancelDeadlineInDays && bookingParams.stage === Stage.CancelBooking;

            if (disallowedDateStage || disallowedArticleStage || disallowedGuestStage || disallowedCancelStage) {
                openStage(Stage.BookingOverview, details, true);
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingParams.stage, details?.guid]);
};
