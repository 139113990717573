import clsx from 'clsx';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useApiClient } from 'hooks/useApiClient';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApplicationUserDto, PartyBookingApiClient, PartyLocationConfigurationDto } from '../../../api/api.generated';
import { Alert, AlertText } from '../../../components/common/Alert/Alert';
import { Button } from '../../../components/common/Button/Button';
import { Card, CardText, CardTitle } from '../../../components/common/Card/Card';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { Typography } from '../../../components/common/Typography/Typography';
import { FieldError } from '../../../components/form/FieldError/FieldError';
import { SelectField } from '../../../components/form/SelectField/SelectField';
import { TextField } from '../../../components/form/TextField/TextField';
import { useApi } from '../../../contexts/ApiProvider';
import { useBooking } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import { BookingSummary } from '../../../features/confirm-booking/BookingSummary/BookingSummary';
import useApiError from '../../../hooks/useApiError';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { useCountries } from '../../../hooks/useCountries';
import { BookingDetails, BookingStatus } from '../../../model/BookingDetails';
import { Stage } from '../../../model/stage';
import { useAuth } from '../../../services/auth/AuthProvider';
import { ApiError } from '../../../utils';
import ApiClient from '../../../utils/ApiClient';
import classes from './ConfirmBooking.module.scss';

interface BookerValues {
    phoneCountry: number;
    phoneNumber: string;
}

export const ConfirmBooking: FunctionComponent = () => {
    const booking = useBooking();
    const { formatMessage } = useIntl();
    const { user, setUser } = useAuth();
    const { client } = useApi();
    const partyApiClient = useApiClient(PartyBookingApiClient);
    const { currentCountry } = useCountries();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { toastApiError } = useApiError();
    const { openStage } = useBookingParams();
    const [countryCodes, setCountryCodes] = useState<number[] | undefined>();
    const [locationConfiguration, setLocationConfiguration] = useState<PartyLocationConfigurationDto | null>(null);

    const fetchData = () => {
        const { token, cancel } = ApiClient.createCancelToken();
        const controller = new AbortController();

        incrementLoader();

        Promise.all([
            client.getPhoneCountryCodes(user?.countrycode || currentCountry.phoneCode, token),
            partyApiClient.apiPartyBookingPartyLocationConfigurationGet(booking.details?.playground.guid, controller.signal),
        ])

            .then(([codes, locationConfiguration]) => {
                setCountryCodes(codes);
                setLocationConfiguration(locationConfiguration);
            })
            .catch((error) => {
                const apiError = ApiError.fromError(error);
                if (apiError !== ApiError.Cancellation) {
                    toastApiError(error);
                }
            })
            .finally(decrementLoader);

        return cancel;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchData, []);

    const { register, handleSubmit, formState, getValues } = useForm<BookerValues>({
        defaultValues: {
            phoneCountry: user?.countrycode || currentCountry.phoneCode,
            phoneNumber: '',
        },
    });

    // Configure form errors and fields
    const errorMessages = useMemo(() => {
        return {
            required: formatMessage({ id: 'errors.field-required', defaultMessage: 'Fältet är obligatoriskt' }),
            phone: formatMessage({ id: 'errors.field-phone-invalid', defaultMessage: 'Telefonnummret är inte giltigt' }),
        };
    }, [formatMessage]);

    const phoneValidator = PhoneNumberUtil.getInstance();
    const fields = {
        phoneCountry: register('phoneCountry', {
            required: true,
            deps: ['phoneNumber'],
        }),
        phoneNumber: register('phoneNumber', {
            required: !user?.phone ? errorMessages.required : false,
            validate: (value) => {
                if (user?.phone) {
                    // Form is only shown if user is missing a phone number, so we don't validate if the user
                    // already has a phone number set.
                    return undefined;
                }

                const phoneCountry = getValues('phoneCountry');
                try {
                    const phone = phoneValidator.parse(`+${phoneCountry}${value}`);
                    if (value && !phoneValidator.isValidNumber(phone)) {
                        return errorMessages.phone;
                    }
                } catch {
                    return errorMessages.phone;
                }

                return undefined;
            },
        }),
    };

    const onSubmit: SubmitHandler<BookerValues> = async (data) => {
        if (!booking.details || !user) {
            return;
        }

        incrementLoader();

        let userRes: ApplicationUserDto | null = null;

        // Update the users phone number if supplied.
        if (user.guid && data.phoneNumber) {
            const parsedPhone = phoneValidator.parse(`+${data.phoneCountry}${data.phoneNumber}`);

            if (phoneValidator.isValidNumber(parsedPhone)) {
                const phoneCountryCode = parsedPhone.getCountryCodeOrDefault();
                const phoneNational = parsedPhone.getNationalNumberOrDefault();
                try {
                    userRes = await client.updateUserProfile(user.guid, phoneCountryCode, `${phoneNational}`);
                } catch (error) {
                    toastApiError(error);
                }

                // If we didn't manage to update the user, abort.
                if (!userRes) {
                    decrementLoader();
                    return;
                }
            }
        }

        let details: BookingDetails | undefined;
        try {
            if (booking.details.status === BookingStatus.Confirmed) {
                details = await client.confirmBookingChanges(booking.details.guid);
            } else {
                details = await client.confirmBooking(booking.details.guid);
            }
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();

        if (details) {
            if (userRes) {
                // We wait until the booking is confirmed to keep the form
                setUser(userRes);
            }

            booking.set(details);

            if (booking.details.status === BookingStatus.Reserved) {
                // If this was a new booking (old status was reserved), go to Confirmed instead of overview
                openStage(Stage.ConfirmedBooking, details);
            } else {
                openStage(Stage.BookingOverview, details);
            }
        }
    };

    // We require a booking and the user to login to confirm a booking
    if (!user || !booking.details) {
        return null;
    }

    const onBackClick = async () => {
        if (!booking.details) {
            return;
        }

        if (booking.details.status === BookingStatus.Confirmed && booking.details.hasBookingChanges) {
            // Cancel booking changes as we don't want to allow the user to end up with changes without confirming them.
            incrementLoader();

            let details: BookingDetails | undefined;
            try {
                details = await client.cancelBookingChanges(booking.details.guid);
            } catch (error) {
                toastApiError(error);
            }

            decrementLoader();

            if (details) {
                booking.set(details);
                openStage(Stage.BookingOverview, booking.details);
            }
        } else if (booking.details.status === BookingStatus.Reserved) {
            // Go back to pre-confirm
            openStage(Stage.PreConfirmBooking, booking.details);
        }
    };

    return (
        <>
            <PageContainer
                title={formatMessage({ id: 'confirm-booking.title', defaultMessage: 'Bekräfta bokning' })}
                onBackClick={onBackClick}
            >
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <fieldset disabled={formState.isSubmitting} className={classes.fieldset}>
                        <Card dark className={classes.card}>
                            <CardTitle>
                                {booking.details.status === 'Confirmed' ? (
                                    <FormattedMessage id="confirm-booking.card-title-change" defaultMessage="Din bokning är snart ändrad" />
                                ) : (
                                    <FormattedMessage id="confirm-booking.card-title-new" defaultMessage="Din bokning är snart klar" />
                                )}
                            </CardTitle>
                            <CardText>
                                {booking.details.status === 'Confirmed' ? (
                                    <FormattedMessage
                                        id="confirm-booking.card-text-change"
                                        defaultMessage="Se över dina val och bekräfta ändringarna."
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="confirm-booking.card-text-new"
                                        defaultMessage="Se över dina val och klicka sedan på slutför bokning. Glöm inte att bjuda in via vår inbjudningsfunktion."
                                    />
                                )}
                            </CardText>
                        </Card>

                        <BookingSummary
                            playground={booking.details.playground.name}
                            date={booking.details.slot.date}
                            startTime={booking.details.partyRoomTime}
                            arrivalTime={booking.details.startTime || booking.details.slot.displayStartTime}
                            enableArrivalTime={locationConfiguration?.partyEnableChoosePlayTime || false}
                            room={booking.details.room.name}
                            guests={booking.details.guestCount}
                            articles={booking.details.articles}
                            partyPackage={booking.details.partyPackage}
                            total={booking.details.price.pricevalue}
                            currency={booking.details.price.currency}
                            className={classes.summary}
                            artNoExtraBirthdayChild={booking.details.artNoExtraBirthdayChild}
                            playcentertype={booking.details.playground.playcentertype}
                        />

                        {!user.phone && (
                            <>
                                <Typography
                                    variant="title5"
                                    className={clsx(classes.label, { [classes.labelDisabled]: formState.isSubmitting })}
                                >
                                    <FormattedMessage id="confirm-booking.form-phone-label" defaultMessage="Ditt telefonnummer" />
                                </Typography>

                                <div className={classes.phoneNumber}>
                                    <SelectField
                                        {...fields.phoneCountry}
                                        label={formatMessage({
                                            id: 'confirm-booking.form-field-phone-country-label',
                                            defaultMessage: 'Landskod',
                                        })}
                                    >
                                        {countryCodes?.map((phoneCode) => (
                                            <option key={phoneCode} value={phoneCode}>
                                                +{phoneCode}
                                            </option>
                                        ))}
                                    </SelectField>

                                    <TextField
                                        {...fields.phoneNumber}
                                        inputMode="tel"
                                        error={formState.errors.phoneNumber ? '' : null}
                                        label={formatMessage({
                                            id: 'confirm-booking.form-field-phone-number-label',
                                            defaultMessage: 'Telefonnummer',
                                        })}
                                    />

                                    {formState.errors.phoneNumber && (
                                        <FieldError className={classes.phoneNumberError}>{formState.errors.phoneNumber.message}</FieldError>
                                    )}
                                </div>
                            </>
                        )}

                        <Typography variant="body" className={clsx(classes.profileLink)}>
                            <FormattedMessage
                                id="confirm-booking.my-account-link-text"
                                defaultMessage="Du har tidigare gjort ett val gällande hur du vill att vi kommunicerar med dig. <a>Klicka här</a> för att ändra."
                                values={{
                                    a: (chunks) => (
                                        <Typography
                                            variant="links"
                                            component="a"
                                            href={`${currentCountry.urlProfile}/edit`}
                                            target="_blank"
                                        >
                                            {chunks}
                                        </Typography>
                                    ),
                                }}
                            />
                        </Typography>

                        <div className={classes.backButton}>
                            <Button type="submit" color="dinosaurGreen">
                                {booking.details.status === 'Confirmed' ? (
                                    <FormattedMessage id="confirm-booking.confirm-button-change" defaultMessage="Bekräfta ändringar" />
                                ) : (
                                    <FormattedMessage id="confirm-booking.confirm-button-new" defaultMessage="Slutför bokning" />
                                )}
                            </Button>

                            <Button color="transparent" onClick={onBackClick}>
                                <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                            </Button>
                        </div>

                        <Alert>
                            <AlertText>
                                <FormattedMessage
                                    id="confirm-booking.payment-note"
                                    defaultMessage="Du betalar inget nu. Betalningen görs med betalkort på plats på leklandet. Du kommer få en bokningsbekräftelse via e-post!"
                                />
                            </AlertText>
                        </Alert>
                    </fieldset>
                </form>
            </PageContainer>
        </>
    );
};
