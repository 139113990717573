import { intervalToDuration } from 'date-fns';

/**
 * The amount of time between to dates in separate parts.
 */
interface Duration {
    years: number | null;
    months: number | null;
    days: number | null;
    hours: number | null;
    minutes: number | null;
    seconds: number;
}

/**
 * Formats a duration into a human-readable format: 20d 14h 30m 25s.
 * Does not translate the date parts at the moment.
 *
 * @param start That start of the duration.
 * @param end The end of the duration.
 * @returns The formatted string.
 */
export function getDuration(start: Date, end: Date): Duration {
    if (start.getTime() > end.getTime()) {
        start = new Date(end);
    }

    const duration = intervalToDuration({ start, end });

    const showYears = !!duration.years;
    const showMonths = !!duration.months || showYears;
    const showDays = !!duration.days || showMonths;
    const showHours = !!duration.hours || showDays;
    const showMinutes = !!duration.minutes || showHours;

    return {
        years: showYears ? duration.years ?? 0 : null,
        months: showMonths ? duration.months ?? 0 : null,
        days: showDays ? duration.days ?? 0 : null,
        hours: showHours ? duration.hours ?? 0 : null,
        minutes: showMinutes ? duration.minutes ?? 0 : null,
        seconds: duration.seconds ?? 0,
    };
}
