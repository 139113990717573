import clsx from 'clsx';
import { FunctionComponent, useRef } from 'react';
import { useTransition } from '../../../hooks/useTransition';
import classes from './FadeIn.module.scss';

interface FadeInProps {
    enter: boolean;
    enterOnMount?: boolean;
    unmount?: boolean;
    className?: string;
}

/**
 * A container that can be faded in or out.
 *
 * Usage:
 * <FadeIn enter={enter} className={classes.fadeIn}>
 *     Content to fade in
 * </FadeIn>
 */
export const FadeIn: FunctionComponent<FadeInProps> = ({ enter, enterOnMount, unmount, className, children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const transitionState = useTransition(containerRef, enter, 300, enterOnMount);

    return !unmount || transitionState !== 'exited' ? (
        <div ref={containerRef} className={clsx(classes[transitionState], className)}>
            {children}
        </div>
    ) : null;
};
