import clsx from 'clsx';
import { FunctionComponent, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTransition } from '../../../hooks/useTransition';
import classes from './Dialog.module.scss';

interface DialogProps {
    open: boolean;
    enterOnMount?: boolean;
}

/**
 * Base dialog component.
 *
 * Handles transition and positioning of the content.
 *
 * Usage:
 * <Dialog open={open} className={classes.backdrop}>
 *     Dialog content
 * </Dialog>
 */
export const Dialog: FunctionComponent<DialogProps> = ({ open, enterOnMount, children }) => {
    const dialogRef = useRef<HTMLDivElement>(null);
    const transitionState = useTransition(dialogRef, open, 300, enterOnMount);

    const container = useMemo(() => {
        return document.getElementById('app-dialogs') as HTMLElement;
    }, []);

    const dialog =
        transitionState !== 'exited' ? (
            <div ref={dialogRef} className={clsx(classes.dialog, classes[transitionState])}>
                {children}
            </div>
        ) : null;

    return createPortal(dialog, container);
};
