import { render } from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './App';
import './assets/theme/app.scss';
import { ScrollToTop } from './components/utils/ScrollToTop';
import { Stage } from './model/stage';
import { polyfill } from './polyfill';
import { redirects } from './redirects';
import { Booking } from './routes/Booking';
import { BookingDetails } from './routes/booking/booking-details/BookingDetails';
import { BookingOverview } from './routes/booking/booking-overview/BookingOverview';
import { CancelBooking } from './routes/booking/cancel-booking/CancelBooking';
import { ConfirmBooking } from './routes/booking/confirm-booking/ConfirmBooking';
import { ConfirmedBooking } from './routes/booking/confirmed-booking/ConfirmedBooking';
import { EditBirthdayKids } from './routes/booking/edit-birthday-kids/EditBirthdayKids';
import { EditGuestCount } from './routes/booking/edit-guest-count/EditGuestCount';
import { EditGuests } from './routes/booking/edit-guests/EditGuests';
import { EditInvitation } from './routes/booking/edit-invitation/EditInvitation';
import { PreConfirmBooking } from './routes/booking/pre-confirm-booking/PreConfirmBooking';
import { SelectAddons } from './routes/booking/select-addons/SelectAddons';
import { SelectDate } from './routes/booking/select-date/SelectDate';
import { SelectPackage } from './routes/booking/select-package/SelectPackage';
import { SelectPlayground } from './routes/booking/select-playground/SelectPlayground';
import { Invite } from './routes/Invite';
import { LanguageRedirect } from './routes/LanguageRedirect';
import { MyBookings } from './routes/my-bookings/MyBookings';
import { NotFound } from './routes/NotFound';

/**
 * Polyfills required features and starts the React application.
 */
async function run() {
    // Check if we are prefixed with the correct path. If not, add the prefix.
    if (window.location.pathname.indexOf(process.env.PUBLIC_URL) === -1) {
        window.location.pathname = `${process.env.PUBLIC_URL}${window.location.pathname}`;
    }

    // Polyfill first
    await polyfill();

    // Grab the container where we should render the application
    const appRoot = document.getElementById('app-root');

    // Check if we should render the design system route instead of the app. We use a static process.env variable to check
    // if we should show the design system. This ensures that the design system chunk is not included at all in production
    // builds.
    if (process.env.REACT_APP_ENVIRONMENT !== 'production' && window.location.pathname.startsWith(`${process.env.PUBLIC_URL}/design`)) {
        const { DesignSystem } = await import('./design/DesignSystem');
        render(<DesignSystem />, appRoot);
    } else {
        render(
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <ScrollToTop />

                <Routes>
                    <Route path="/" element={<App />}>
                        <Route index element={<LanguageRedirect />} />

                        <Route path=":locale">
                            <Route element={<Booking />}>
                                {/* Routes before a playground is selected */}
                                <Route index element={<BookingOverview />} />
                                <Route path={Stage.SelectPlayground} element={<SelectPlayground />} />

                                {/* Routes after playground is selected */}
                                <Route path={`:playground/${Stage.SelectDate}`} element={<SelectDate />} />

                                {/* Routes when a reservation has been made */}
                                <Route path=":playground/:guid">
                                    <Route index element={<BookingOverview />} />
                                    <Route path={Stage.SelectDate} element={<SelectDate />} />
                                    <Route path={Stage.SelectPackage} element={<SelectPackage />} />
                                    <Route path={Stage.SelectAddons} element={<SelectAddons />} />
                                    <Route path={Stage.EditGuestCount} element={<EditGuestCount />} />
                                    <Route path={Stage.EditBirthdayKids} element={<EditBirthdayKids />} />
                                    <Route path={Stage.EditInvitation} element={<EditInvitation />} />
                                    <Route path={Stage.EditGuests} element={<EditGuests />} />
                                    <Route path={Stage.PreConfirmBooking} element={<PreConfirmBooking />} />
                                    <Route path={Stage.ConfirmBooking} element={<ConfirmBooking />} />
                                    <Route path={Stage.ConfirmedBooking} element={<ConfirmedBooking />} />
                                    <Route path={Stage.CancelBooking} element={<CancelBooking />} />
                                    <Route path={Stage.BookingDetails} element={<BookingDetails />} />
                                </Route>
                            </Route>

                            <Route path="invitation" element={<Invite />} />

                            <Route path="my-bookings" element={<MyBookings />} />
                        </Route>

                        {/* Not found page */}
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </BrowserRouter>,
            appRoot,
        );
    }
}

// Perform any redirects before we start the app
if (!redirects()) {
    run().then();
}
