import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import {
    addDays,
    addMonths,
    eachDayOfInterval,
    eachWeekOfInterval,
    endOfMonth,
    endOfWeek,
    getISODay,
    getTime,
    isAfter,
    isBefore,
    isSameDay,
    isSameMonth,
    startOfMonth,
    startOfWeek,
    subMonths,
} from 'date-fns';
import { CSSProperties, FunctionComponent, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonContent,
    ComboButtonTitle,
    ExpandedComboButton,
} from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { IconButton } from '../../../components/common/IconButton/IconButton';
import { Typography } from '../../../components/common/Typography/Typography';
import { Collapse } from '../../../components/utils/Collapse/Collapse';
import classes from './DatePicker.module.scss';

interface Day {
    key: number;
    date: Date;
    weekday: number;
    disabled: boolean;
}

interface DatePickerProps {
    value: Date | null;
    minDate: Date;
    maxDate: Date;
    viewDate: Date;
    open: boolean;
    className?: string;
    onToggle(open: boolean): void;
    onChange(value: Date): void;
    onViewDateChange(date: Date): void;
}

/**
 * Displays a calendar and allows the user to pick a date.
 */
export const DatePicker: FunctionComponent<DatePickerProps> = ({
    value,
    minDate,
    maxDate,
    viewDate,
    open,
    className,
    onToggle,
    onChange,
    onViewDateChange,
}) => {
    const weekDays = useMemo(() => {
        const now = new Date();
        return eachDayOfInterval({
            start: startOfWeek(now, { weekStartsOn: 1 }),
            end: endOfWeek(now, { weekStartsOn: 1 }),
        });
    }, []);

    const calendar = useMemo(() => {
        const weeks = eachWeekOfInterval(
            {
                start: startOfMonth(viewDate),
                end: endOfMonth(viewDate),
            },
            { weekStartsOn: 1 },
        );

        const days: Day[] = [];

        for (const week of weeks) {
            for (let i = 0; i < 7; i += 1) {
                const day = addDays(week, i);
                if (isSameMonth(viewDate, day)) {
                    const beforeMin = !isSameDay(day, minDate) && isBefore(day, minDate);
                    const afterMax = !isSameDay(day, maxDate) && isAfter(day, maxDate);

                    days.push({
                        key: getTime(day),
                        date: day,
                        weekday: getISODay(day),
                        disabled: beforeMin || afterMax,
                    });
                }
            }
        }

        return days;
    }, [minDate, maxDate, viewDate]);

    const onPreviousClick = () => {
        onViewDateChange(subMonths(viewDate, 1));
    };

    const onNextClick = () => {
        onViewDateChange(addMonths(viewDate, 1));
    };

    const onClick = (day: Day) => {
        if (!day.disabled) {
            onChange(day.date);
        }
    };

    return (
        <ExpandedComboButton className={className}>
            <ComboButton disabled={open && !value} onClick={() => onToggle(!open)}>
                <ComboButtonAction>
                    <FontAwesomeIcon icon={faCalendarAlt} width={29} />
                </ComboButtonAction>

                <ComboButtonContent>
                    <ComboButtonTitle>
                        {value ? (
                            <FormattedDate value={value} month="long" day="numeric" weekday="long" />
                        ) : (
                            <FormattedMessage id="select-date.calendar-no-value" defaultMessage="Välj datum" />
                        )}
                    </ComboButtonTitle>
                </ComboButtonContent>

                <ComboButtonAction>
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        height={23.46}
                        className={clsx(classes.iconChevron, { [classes.iconChevronOpen]: open })}
                    />
                </ComboButtonAction>
            </ComboButton>

            <Collapse open={open}>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <IconButton
                            disabled={isSameMonth(viewDate, minDate)}
                            rippleClassName={classes.iconRipple}
                            className={classes.iconButton}
                            onClick={onPreviousClick}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} width={12} className={classes.iconButtonLeft} />
                        </IconButton>

                        <Typography variant="title4" component="span" className={classes.title}>
                            <FormattedDate value={viewDate} month="long" year="numeric" />
                        </Typography>

                        <IconButton
                            disabled={isSameMonth(viewDate, maxDate)}
                            rippleClassName={classes.iconRipple}
                            className={classes.iconButton}
                            onClick={onNextClick}
                        >
                            <FontAwesomeIcon icon={faChevronRight} width={12} className={classes.iconButtonRight} />
                        </IconButton>
                    </div>

                    <div className={classes.days}>
                        {weekDays.map((date) => (
                            <Typography variant="label" component="span" key={getTime(date)} className={clsx(classes.day, classes.label)}>
                                <FormattedDate value={date} weekday="short" />
                            </Typography>
                        ))}

                        {calendar.map((day) => {
                            const classNames = clsx(classes.day, {
                                [classes.selected]: value && isSameDay(day.date, value),
                            });

                            const style: CSSProperties = {
                                gridColumn: day.weekday,
                            };

                            return (
                                <button
                                    type="button"
                                    disabled={day.disabled}
                                    key={day.key}
                                    className={classNames}
                                    style={style}
                                    onClick={() => onClick(day)}
                                >
                                    <Typography component="span">
                                        <FormattedDate value={day.date} day="numeric" />
                                    </Typography>
                                </button>
                            );
                        })}
                    </div>
                </div>
            </Collapse>
        </ExpandedComboButton>
    );
};
