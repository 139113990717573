import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getDuration } from './getDuration';
import classes from './OpeningInfoCountdown.module.scss';

interface OpeningInfoCountdownProps {
    /** The opening date. */
    date: Date;

    /** Called when the countdown reaches 0. This lets the countdown component handle the interval timer. */
    onCountdownEnded: () => void;

    /** Optional class to append to the container */
    className?: string;
}

/**
 * Countdown to the opening date of the party booking. To prevent unnecessary re-renders of the parent components, we'll
 * handle the countdown here and let the parent know when it's ended.
 */
export const OpeningInfoCountdown: FunctionComponent<OpeningInfoCountdownProps> = ({ date, onCountdownEnded, className }) => {
    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        const id = setInterval(() => setNow(new Date()), 1000);

        return () => clearInterval(id);
    }, []);

    useEffect(() => {
        if (now.getTime() >= date.getTime()) {
            onCountdownEnded();
        }
    }, [date, now, onCountdownEnded]);

    const duration = getDuration(now, date);

    return (
        <div className={clsx(classes.container, className)}>
            {duration.years !== null && (
                <span className={classes.part}>
                    <span className={classes.number}>{duration.years}</span>
                    <span className={classes.label}>
                        <FormattedMessage
                            id="opening-info-countdown.years"
                            defaultMessage="{years, plural, one {år} other {år}}"
                            values={{ years: duration.years }}
                        />
                    </span>
                </span>
            )}

            {duration.months !== null && (
                <span className={classes.part}>
                    <span className={classes.number}>{duration.months}</span>
                    <span className={classes.label}>
                        <FormattedMessage
                            id="opening-info-countdown.months"
                            defaultMessage="{months, plural, one {månad} other {månader}}"
                            values={{ months: duration.months }}
                        />
                    </span>
                </span>
            )}

            {duration.days !== null && (
                <span className={classes.part}>
                    <span className={classes.number}>{duration.days}</span>
                    <span className={classes.label}>
                        <FormattedMessage
                            id="opening-info-countdown.days"
                            defaultMessage="{days, plural, one {dag} other {dagar}}"
                            values={{ days: duration.days }}
                        />
                    </span>
                </span>
            )}

            {duration.hours !== null && (
                <span className={classes.part}>
                    <span className={classes.number}>{duration.hours}</span>
                    <span className={classes.label}>
                        <FormattedMessage
                            id="opening-info-countdown.hours"
                            defaultMessage="{hours, plural, one {timme} other {timmar}}"
                            values={{ hours: duration.hours }}
                        />
                    </span>
                </span>
            )}

            {duration.minutes !== null && (
                <span className={classes.part}>
                    <span className={classes.number}>{duration.minutes}</span>
                    <span className={classes.label}>
                        <FormattedMessage
                            id="opening-info-countdown.minutes"
                            defaultMessage="{minutes, plural, one {minut} other {minuter}}"
                            values={{ minutes: duration.minutes }}
                        />
                    </span>
                </span>
            )}

            <span className={classes.part}>
                <span className={classes.number}>{duration.seconds}</span>
                <span className={classes.label}>
                    <FormattedMessage
                        id="opening-info-countdown.seconds"
                        defaultMessage="{seconds, plural, one {sekund} other {sekunder}}"
                        values={{ seconds: duration.seconds }}
                    />
                </span>
            </span>
        </div>
    );
};
