import clsx from 'clsx';
import { differenceInCalendarDays } from 'date-fns';
import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertText } from '../../../components/common/Alert/Alert';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { Typography } from '../../../components/common/Typography/Typography';
import { useBooking } from '../../../contexts/BookingProvider';
import {
    OverviewCardBirthdayKids,
    OverviewCardCancel,
    OverviewCardDateRoom,
    OverviewCardDetails,
    OverviewCardFAQ,
    OverviewCardGuestCount,
    OverviewCardGuests,
    OverviewCardInvite,
    OverviewCardInviteAndGuests,
    OverviewCardPackageAndAddons,
    OverviewCardPlayground,
} from '../../../features/booking-overview/OverviewCard';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { CountryInfo, useCountries } from '../../../hooks/useCountries';
import { Stage } from '../../../model/stage';
import { useAuth } from '../../../services/auth/AuthProvider';
import classes from './BookingOverview.module.scss';

export const BookingOverview: FunctionComponent = () => {
    const { user } = useAuth();
    const booking = useBooking();
    const { formatMessage } = useIntl();
    const { openStage } = useBookingParams();
    const { currentCountry, getCountryByCulture } = useCountries();

    let partyTitle = formatMessage({ id: 'overview.title-plan-party', defaultMessage: 'Planera ditt kalas' });
    if (booking.details) {
        const daysUntil = differenceInCalendarDays(booking.details.slot.date, new Date());
        partyTitle = formatMessage(
            {
                id: 'overview.title-days-left-to-party',
                defaultMessage: '{days, plural, =0 {# dagar} one {# dag} other {# dagar}} kvar till kalaset',
            },
            { days: daysUntil },
        );
    }

    const hasSelectedImage = !!booking.details?.hasSelectedInvitationBackground;
    const hasInvitedGuests = !!booking.details?.guests.length;
    const hasTodo = booking.details?.canChangeGuests && !hasInvitedGuests;

    const onCardClick = (stage: Stage) => {
        openStage(stage, booking.details);
    };

    const onFAQCardClick = () => {
        let countryInfo: CountryInfo = currentCountry;
        if (booking.details) {
            // Use the bookings country to determine FAQ url if available
            countryInfo = getCountryByCulture(booking.details.country.culture) ?? currentCountry;
        }

        window.open(countryInfo.urlPartyFaq, '_blank');
    };

    const onBackClick = () => {
        window.location.assign(user ? currentCountry.urlProfile : currentCountry.urlHome);
    };

    return (
        <PageContainer title={partyTitle} onBackClick={booking.details ? undefined : onBackClick}>
            {booking.details && !booking.details.canChangeGuests && (
                <Alert className={classes.alert}>
                    <AlertText>
                        <FormattedMessage
                            id="alerts.invitation-disabled"
                            defaultMessage="Du kan tyvärr inte bjuda in eller ta bort gäster när det är mindre än 4 dagar kvar till kalaset."
                        />
                    </AlertText>
                </Alert>
            )}

            {booking.details && hasTodo && (
                <div className={clsx(classes.cards, classes.cardsTop)}>
                    <Typography variant="title5" className={classes.subtitle}>
                        <FormattedMessage id="overview.remaining-tasks" defaultMessage="Kvar att göra!" />
                    </Typography>

                    {!hasSelectedImage ? (
                        <OverviewCardInviteAndGuests booking={booking} onClick={() => onCardClick(Stage.EditInvitation)} />
                    ) : (
                        <OverviewCardGuests booking={booking} onClick={() => onCardClick(Stage.EditGuests)} />
                    )}
                </div>
            )}

            <div className={classes.cards}>
                {booking.details && (
                    <Typography variant="title5" className={classes.subtitle}>
                        <FormattedMessage id="overview.booking-details" defaultMessage="Bokningsdetaljer" />
                    </Typography>
                )}

                <OverviewCardPlayground booking={booking} onClick={() => onCardClick(Stage.SelectPlayground)} />
                <OverviewCardDateRoom booking={booking} onClick={() => onCardClick(Stage.SelectDate)} />
                <OverviewCardGuestCount booking={booking} onClick={() => onCardClick(Stage.EditGuestCount)} />
                {booking.details && !hasTodo && <OverviewCardGuests booking={booking} onClick={() => onCardClick(Stage.EditGuests)} />}
                <OverviewCardBirthdayKids booking={booking} onClick={() => onCardClick(Stage.EditBirthdayKids)} />
                <OverviewCardPackageAndAddons booking={booking} onClick={() => onCardClick(Stage.SelectAddons)} />
                {!booking.details && <OverviewCardInvite booking={booking} onClick={() => onCardClick(Stage.EditInvitation)} />}
                {!booking.details && <OverviewCardGuests booking={booking} onClick={() => onCardClick(Stage.EditGuests)} />}
                {booking.details && <OverviewCardDetails booking={booking} onClick={() => onCardClick(Stage.BookingDetails)} />}
                {booking.details && <OverviewCardCancel booking={booking} onClick={() => onCardClick(Stage.CancelBooking)} />}
                <OverviewCardFAQ booking={booking} onClick={onFAQCardClick} />
            </div>
        </PageContainer>
    );
};
