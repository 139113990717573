import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { addMinutes, format, isAfter, isSameMinute, parse } from 'date-fns';
import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonContent,
    ComboButtonTitle,
    ComboButtonTopText,
    ExpandedComboButton,
} from '../../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Typography } from '../../../components/common/Typography/Typography';
import classes from './EndTimePicker.module.scss';

interface EndTimePickerProps {
    startTime: string;
    maxEndTime: string;
    value: string | null;
    className?: string;
    onChange(value: string): void;
}

/**
 * Displays the duration of the party and lets the user select an end time.
 */
export const EndTimePicker: FunctionComponent<EndTimePickerProps> = ({ startTime, maxEndTime, value, className, onChange }) => {
    // We'll use the same date as reference for all time parsing
    const referenceDate = useMemo(() => new Date(), []);

    const startTimeDate = useMemo(() => {
        return parse(startTime, 'HH:mm', referenceDate);
    }, [referenceDate, startTime]);

    const endTimeDate = useMemo(() => {
        if (!value) {
            return null;
        }

        return parse(value, 'HH:mm', referenceDate);
    }, [referenceDate, value]);

    const maxEndTimeDate = useMemo(() => {
        return parse(maxEndTime, 'HH:mm', referenceDate);
    }, [referenceDate, maxEndTime]);

    const values = useMemo(() => {
        const values: Date[] = [];

        for (let minutes = 60; minutes <= 180; minutes += 30) {
            const dateValue = addMinutes(startTimeDate, minutes);

            // Ensure we're not past the max end time
            if (!isAfter(dateValue, maxEndTimeDate)) {
                values.push(dateValue);
            }
        }

        return values;
    }, [startTimeDate, maxEndTimeDate]);

    const onClick = (newDate: Date) => {
        const newValue = format(newDate, 'HH:mm');
        onChange(newValue);
    };

    const items = values.map((val) => {
        const classNames = clsx(classes.item, {
            [classes.itemSelected]: endTimeDate && isSameMinute(endTimeDate, val),
        });

        return (
            <button type="button" key={val.getTime()} className={classNames} onClick={() => onClick(val)}>
                <FormattedTime value={val} />
            </button>
        );
    });

    return (
        <ExpandedComboButton className={className}>
            <ComboButton component="div">
                <ComboButtonAction>
                    <FontAwesomeIcon icon={faClock} width={34} />
                </ComboButtonAction>

                <ComboButtonContent>
                    <ComboButtonTopText>
                        <FormattedMessage id="edit-invitation.end-time-title" defaultMessage="Kalastid" />
                    </ComboButtonTopText>

                    <ComboButtonTitle>
                        <FormattedTime value={startTimeDate} />
                        {endTimeDate && (
                            <>
                                <FontAwesomeIcon icon={faLongArrowRight} width={16.75} className={classes.arrowIcon} />
                                <FormattedTime value={endTimeDate} />
                            </>
                        )}
                    </ComboButtonTitle>
                </ComboButtonContent>
            </ComboButton>

            <div className={classes.content}>
                <Typography variant="label" component="div">
                    <FormattedMessage id="edit-invitation.end-time-select" defaultMessage="Välj sluttid" />
                </Typography>

                <div className={classes.items}>{items}</div>
            </div>
        </ExpandedComboButton>
    );
};
