import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

export const iconPaw: IconDefinition = {
    prefix: 'far',
    iconName: 'paw',
    icon: [
        100,
        100,
        [],
        '',
        // eslint-disable-next-line max-len
        'M83.6 70.4c-1.6-4.3-4.7-6.7-7.8-9.2-1.4-1.2-2.8-2.4-4.1-3.9-3.4-3.8-4.1-8.4-8-11.9-3.8-3.3-8.6-5.2-13.6-5.2-7.3 0-16 4.6-18.1 12-2.1 7.2-10.7 9.6-14.1 16-3.1 5.7-2.8 12.7 1.6 17.6 4.4 5 11.5 6.7 17.9 5.7 3.8-.6 6.5-2.2 9.6-4.2 2.7-1.8 6.4-1.2 8.8.7 2.5 2 4.6 3 7.9 3.4 6.3.9 13.3-.9 17.6-5.9 3.4-4.1 4.2-10 2.3-15.1zm-24.7-2.2l-1.3 1.3-7.2 7.3-7.3-7.2-1.3-1.3c-2-2-2-5.2 0-7.3 2-2 5.2-2 7.3 0l1.3 1.3 1.3-1.3c2-2 5.2-2 7.3 0 1.9 2 1.9 5.2-.1 7.2zm-4.5-48.4C57.2 11.6 64.2 6.6 70 8.6c5.8 2 8.3 10.3 5.5 18.4-2.8 8.2-9.8 13.2-15.7 11.1-5.8-1.9-8.3-10.2-5.4-18.3zM26.9 9.3c5.8-2.1 12.9 2.9 15.8 11 2.9 8.1.5 16.4-5.3 18.5-5.8 2.1-12.9-2.9-15.8-11-2.9-8.1-.5-16.4 5.3-18.5zm-16.3 43c-3.6-4.6-3.4-10.9.6-14 4-3.1 10.1-1.9 13.7 2.7 3.6 4.6 3.4 10.9-.6 14-3.9 3.1-10.1 1.9-13.7-2.7zm78.7-.5c-3.9 5-10.6 6.4-14.9 3-4.3-3.4-4.7-10.2-.8-15.2 3.9-5 10.6-6.4 14.9-3 4.3 3.3 4.7 10.1.8 15.2z',
    ],
};
