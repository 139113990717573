import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { useState } from 'react';
import { useCountries } from '../../../hooks/useCountries';
import { Ripple } from '../../utils/Ripple/Ripple';
import { FontAwesomeIcon } from '../FontAwesomeIcon/FontAwesomeIcon';
import classes from './SidebarCountry.module.scss';
import { SidebarCountrySelector } from './SidebarCountrySelector';

type SidebarCountryProps = {
    className?: string;
};

/**
 * Button to open the country selector.
 */
export function SidebarCountry({ className }: SidebarCountryProps) {
    const { currentCountry } = useCountries();
    const [selectorOpen, setSelectorOpen] = useState(false);

    return (
        <>
            <Ripple className={classes.ripple}>
                {(props, ripples) => (
                    <button type="button" className={clsx(classes.container, className)} onClick={() => setSelectorOpen(true)} {...props}>
                        {ripples}
                        <span className={classes.content}>
                            <FontAwesomeIcon icon={faGlobe} width={10} />
                            {currentCountry.name}
                            <FontAwesomeIcon icon={faChevronUp} width={7} />
                        </span>
                    </button>
                )}
            </Ripple>

            <SidebarCountrySelector open={selectorOpen} onClose={() => setSelectorOpen(false)} />
        </>
    );
}
