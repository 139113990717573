import { isSameDay } from 'date-fns';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { useApi } from '../contexts/ApiProvider';
import { BookingProvider } from '../contexts/BookingProvider';
import { useGlobalLoading } from '../contexts/GlobalLoadingProvider';
import useApiError from '../hooks/useApiError';
import { useBookingNavigationGuard } from '../hooks/useBookingNavigationGuard';
import { useBookingParams } from '../hooks/useBookingParams';
import { Guest } from '../model';
import { BookingDetails, BookingSlot, BookingStatus } from '../model/BookingDetails';
import { Stage } from '../model/stage';
import { ApiError, isEmptyGuest, sortGuests } from '../utils';
import ApiClient from '../utils/ApiClient';
import { HttpStatusCode } from '../utils/HttpStatusCode';
import { Error } from './Error';
import { NotFound } from './NotFound';

export const Booking: FunctionComponent = () => {
    const { client } = useApi();
    const { formatMessage } = useIntl();
    const { incrementLoader, decrementLoader } = useGlobalLoading();

    const { bookingParams } = useBookingParams();
    const { apiError, setApiError, clearApiError } = useApiError();
    const [details, setDetails] = useState<BookingDetails | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useBookingNavigationGuard(details);

    const loadBooking = () => {
        const { token, cancel } = ApiClient.createCancelToken();

        if (bookingParams.guid && bookingParams.guid !== details?.guid) {
            clearApiError();
            incrementLoader();
            setIsLoading(true);

            client
                .getBooking(bookingParams.guid, token)
                .then((details) => {
                    if (details.status === BookingStatus.Cancelled) {
                        const cancelledError = formatMessage({
                            id: 'general.error-cancelled',
                            defaultMessage: 'Kalaset är avbokat. Kontakta ditt lekland för mer information.',
                        });
                        throw new ApiError(cancelledError);
                    }

                    if (details.hasChangedGuestCount && bookingParams.stage !== Stage.ConfirmBooking) {
                        // If we just loaded the booking, and we have changed the guest count and are trying to load another page, cancel the
                        // booking changes.
                        return client.cancelBookingChanges(details.guid);
                    }

                    if (
                        details.hasBookingChanges &&
                        ![Stage.SelectPackage, Stage.SelectAddons, Stage.ConfirmBooking].includes(bookingParams.stage)
                    ) {
                        // If we have other booking changes than guest count, but are not on any of the allowed stages, cancel the booking
                        // changes.
                        return client.cancelBookingChanges(details.guid);
                    }

                    return details;
                })
                .then((details) => {
                    setDetails(() => details);
                    setIsLoading(false);
                })
                .catch(setApiError)
                .finally(decrementLoader);
        } else if (bookingParams.guid) {
            setIsLoading(false);
        } else {
            setDetails(() => undefined);
            setIsLoading(false);
        }

        return cancel;
    };

    // Load booking when path parameters update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadBooking, [bookingParams.guid]);

    const clear = () => {
        setDetails(() => undefined);
    };

    const set = (details: BookingDetails) => {
        setDetails(() => details);
    };

    const updateGuests = (guestList: Guest[], locale: string) => {
        setDetails((details) => {
            if (details) {
                const guests = guestList.filter((g) => !isEmptyGuest(g) && !g.ismainguest);
                const birthdayKids = guestList.filter((g) => !isEmptyGuest(g) && g.ismainguest);

                return {
                    ...details,
                    guestList,
                    guests: sortGuests(guests, locale),
                    birthdayKids: sortGuests(birthdayKids, locale),
                };
            }

            return details;
        });
    };

    const setGuest = (guest: Guest, locale: string) => {
        if (details) {
            const guestList = details.guestList.slice();
            const guestIndex = guestList.findIndex((g) => g.inviteid === guest.inviteid);
            if (guestIndex !== -1) {
                guestList.splice(guestIndex, 1, guest);
                updateGuests(guestList, locale);
            }
        }
    };

    const isBookedSlot = (other?: Pick<BookingSlot, 'date' | 'roomGuid' | 'startTime'>): boolean => {
        if (!other || !other.date || !details) {
            return false;
        }

        return (
            isSameDay(other.date, details.slot.date) &&
            other.roomGuid === details.slot.roomGuid &&
            other.startTime === details.slot.startTime
        );
    };

    if (apiError?.getStatusCode() === HttpStatusCode.NotFound) {
        return <NotFound />;
    }

    // Always show API errors
    if (apiError) {
        return <Error error={apiError} onRetryClick={loadBooking} />;
    }

    // Wait until we're done loading
    if (isLoading) {
        return null;
    }

    // Check for invalid parameters
    if (bookingParams.playground === undefined) {
        return <NotFound />;
    }

    // Make sure path parameters match loaded booking
    if (details && details.playground !== bookingParams.playground) {
        return <NotFound />;
    }

    // If we have a guid in the url but the booking wasn't loaded. Allow cancel stage to clear the booking without
    // showing page not found.
    if (bookingParams.guid && !details && bookingParams.stage !== Stage.CancelBooking) {
        return <NotFound />;
    }

    return (
        <BookingProvider
            value={{
                clear,
                set,
                setGuest,
                isBookedSlot,
                details,
            }}
        >
            <Outlet />
        </BookingProvider>
    );
};
