import { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router';
import { LoginIntent } from '../../api/api-identity.generated';
import { Header } from '../../components/common/Header/Header';
import { Sidebar } from '../../components/common/Sidebar/Sidebar';
import { SidebarLink } from '../../components/common/Sidebar/SidebarLink';
import { useCountries } from '../../hooks/useCountries';
import { useAuth } from '../../services/auth/AuthProvider';

/**
 * Party header and sidebar.
 */
export const PartyHeader: FunctionComponent = () => {
    const { currentCountry } = useCountries();
    const { signIn, signOut, user } = useAuth();
    const { locale } = useIntl();

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    const links = {
        entrance: `${currentCountry.urlNext}/book-entrance`,
        group: currentCountry.urlGroupBooking,
        urlProfile: currentCountry.urlProfile,
        urlLeosFamily: currentCountry.urlLeosFamily,
        urlFaq: currentCountry.urlFaq,
        party: generatePath(`:base/:locale`, {
            base: process.env.PUBLIC_URL,
            locale,
        }),
    };

    const sidebarUser = useMemo(() => {
        return (
            user && {
                userName: user.username,
                firstName: user.firstname,
                lastName: user.lastname,
            }
        );
    }, [user]);

    const closeSidebar = () => setSidebarOpen(false);

    const handleMenuClick = () => setSidebarOpen(true);

    const handleLoginClick = () => {
        closeSidebar();
        signIn(LoginIntent.Default);
    };

    const handleLogoutClick = () => {
        closeSidebar();
        signOut().then(
            () => {},
            () => {},
        );
    };

    const onCookiesClick = () => {
        closeSidebar();
        window.Cookiebot?.renew();
    };

    return (
        <>
            <Header onMenuClick={handleMenuClick} />

            <Sidebar
                open={sidebarOpen}
                user={sidebarUser}
                profileEditLink={currentCountry.urlProfile}
                onProfileEditClick={closeSidebar}
                onLoginClick={handleLoginClick}
                onLogoutClick={handleLogoutClick}
                onClose={closeSidebar}
                primaryLinks={
                    <>
                        <SidebarLink component="a" type="primary" href={links.entrance} onClick={closeSidebar}>
                            <FormattedMessage id="sidebar.link-primary-tickets" defaultMessage="Biljetter" />
                        </SidebarLink>

                        <SidebarLink component="a" type="primary" href={links.party} selected onClick={closeSidebar}>
                            <FormattedMessage id="sidebar.link-primary-party" defaultMessage="Kalas" />
                        </SidebarLink>

                        <SidebarLink component="a" type="primary" href={currentCountry.urlShop} target="_blank" onClick={closeSidebar}>
                            <FormattedMessage id="sidebar.link-primary-shop" defaultMessage="Shop" />
                        </SidebarLink>

                        <SidebarLink component="a" type="primary" href={links.group} target="_blank" onClick={closeSidebar}>
                            <FormattedMessage id="sidebar.link-primary-groups" defaultMessage="Grupper" />
                        </SidebarLink>

                        <SidebarLink component="a" type="primary" href={currentCountry.urlSportKidz} onClick={closeSidebar}>
                            Sports Club
                        </SidebarLink>
                    </>
                }
                secondaryLinks={
                    <>
                        <SidebarLink component="a" href={links.urlProfile} type="secondary">
                            <FormattedMessage id="sidebar.link-secondary-profile" defaultMessage="Mitt konto" />
                        </SidebarLink>

                        <SidebarLink component="a" href={currentCountry.urlLeosFamily} type="secondary">
                            <FormattedMessage id="sidebar.link-secondary-leos-family" defaultMessage="Leo’s Family" />
                        </SidebarLink>

                        <SidebarLink component="a" href={currentCountry.urlFaq} type="secondary">
                            <FormattedMessage id="sidebar.link-secondary-faq" defaultMessage="FAQ" />
                        </SidebarLink>

                        <SidebarLink component="button" type="secondary" onClick={onCookiesClick}>
                            <FormattedMessage id="sidebar.link-secondary-cookies" defaultMessage="Om cookies" />
                        </SidebarLink>
                    </>
                }
            />
        </>
    );
};
