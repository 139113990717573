import { faExclamationCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import React, { forwardRef, FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '../FontAwesomeIcon/FontAwesomeIcon';
import { Typography, TypographyProps } from '../Typography/Typography';
import classes from './Alert.module.scss';
import { iconPaw } from './Icon/IconPaw';

interface AlertProps {
    className?: string;
    children: ReactNode;
}

/**
 * Design system Alert with support for additional content below the text. This is usually a button.
 */
export const Alert = forwardRef<HTMLDivElement, AlertProps>(({ className, children }, ref) => {
    return (
        <div ref={ref} className={clsx(classes.container, className)}>
            {children}
        </div>
    );
});

interface AlertTextProps {
    type?: 'info' | 'warning' | 'paw' | 'error';
    component?: TypographyProps['component'];
    className?: string;
    content?: string;
}

/**
 * The text for the Alert with icon depending on type.
 */
export const AlertText: FunctionComponent<AlertTextProps> = ({ type = 'info', component, className, children }) => {
    let icon = faInfoCircle;
    let iconWidth = 20;
    let iconClass = classes.iconInfo;

    if (type === 'warning') {
        icon = faExclamationTriangle;
        iconWidth = 21;
        iconClass = classes.iconWarning;
    } else if (type === 'error') {
        icon = faExclamationCircle;
        iconClass = classes.iconError;
    } else if (type === 'paw') {
        icon = iconPaw;
        iconClass = classes.iconPaw;
    }

    return (
        <Typography component={component} className={clsx(classes.text, className)}>
            <FontAwesomeIcon icon={icon} width={iconWidth} className={clsx(classes.icon, iconClass)} />
            {renderChildren(children)}
        </Typography>
    );
};

const renderChildren = (children: ReactNode) => {
    return React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === FormattedMessage) {
            return (
                <FormattedMessage {...(child.props as any)}>
                    {(formattedMessage: string) => <span dangerouslySetInnerHTML={{ __html: formattedMessage }} />}
                </FormattedMessage>
            );
        }
        return child;
    });
};

interface AlertContentProps {
    className?: string;
}

/**
 * The content below the Alert text, usually a Button.
 */
export const AlertContent: FunctionComponent<AlertContentProps> = ({ className, children }) => {
    return <div className={clsx(classes.content, className)}>{children}</div>;
};
