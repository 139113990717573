import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCountries } from '../../../hooks/useCountries';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { Dialog } from '../../utils/Dialog/Dialog';
import { FontAwesomeIcon } from '../FontAwesomeIcon/FontAwesomeIcon';
import { IconButton } from '../IconButton/IconButton';
import { SidebarCountryLink } from './SidebarCountryLink';
import classes from './SidebarCountrySelector.module.scss';

type SidebarCountrySelectorProps = {
    open: boolean;
    onClose: () => void;
};

/**
 * Dialog to switch between different countries.
 */
export function SidebarCountrySelector({ open, onClose }: SidebarCountrySelectorProps) {
    const { currentCountry, countries } = useCountries();

    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside(ref, onClose);

    return (
        <Dialog open={open}>
            <div className={classes.content}>
                <nav className={classes.menu} ref={ref}>
                    <div className={classes.header}>
                        <span className={classes.title}>
                            <FormattedMessage id="sidebar-country.title" defaultMessage="Välj land" />
                        </span>

                        <IconButton className={classes.closeButton} rippleClassName={classes.closeButtonRipple} onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} height={28} />
                        </IconButton>
                    </div>

                    <menu className={classes.list}>
                        {countries.map((country) => (
                            <SidebarCountryLink
                                key={country.code}
                                country={country}
                                selected={country.code === currentCountry.code}
                                onClick={onClose}
                            />
                        ))}
                    </menu>
                </nav>
            </div>
        </Dialog>
    );
}
