import { faBirthdayCake, faChild } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Typography } from '../../components/common/Typography/Typography';
import classes from './AttendenceList.module.scss';

interface AttendanceListProps {
    noAnswer?: number;
    accepted?: number;
    declined?: number;
    birthdayKids?: number;
    className?: string;
}

export const AttendanceList: FunctionComponent<AttendanceListProps> = ({ noAnswer, accepted, declined, birthdayKids, className }) => {
    return (
        <div className={clsx(classes.container, className)}>
            {noAnswer !== undefined && (
                <Typography className={classes.item}>
                    <FontAwesomeIcon icon={faChild} className={classes.iconInvited} />
                    <span>{noAnswer}</span>
                </Typography>
            )}

            {accepted !== undefined && (
                <Typography className={classes.item}>
                    <FontAwesomeIcon icon={faChild} className={classes.iconAccepted} />
                    <span>{accepted}</span>
                </Typography>
            )}

            {declined !== undefined && (
                <Typography className={classes.item}>
                    <FontAwesomeIcon icon={faChild} className={classes.iconDeclined} />
                    <span>{declined}</span>
                </Typography>
            )}

            {birthdayKids !== undefined && (
                <Typography className={classes.item}>
                    <FontAwesomeIcon icon={faBirthdayCake} className={classes.iconBirthdayKids} />
                    <span>{birthdayKids}</span>
                </Typography>
            )}
        </div>
    );
};
