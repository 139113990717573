import { faBirthdayCake, faCheckCircle, faCircle, faFutbol } from '@fortawesome/pro-solid-svg-icons';
import { IntervalType } from 'api/api.generated';
import clsx from 'clsx';
import { FontAwesomeIcon } from 'components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { ComboButton, ComboButtonAction, ComboButtonContent, ComboButtonTitle } from '../../../components/common/ComboButton/ComboButton';
import { Typography } from '../../../components/common/Typography/Typography';
import classes from './PlaytimePicker.module.scss';

export interface PlaytimePickerProps {
    playtimes: PlayTime[] | null;
    partyLengthHours: number;
    isLoading: boolean;
    value: PlayTime | null;
    onChange(playtime: PlayTime): void;
}

export interface PlayTime {
    arrivalTime: string;
    available: boolean;
    timeIntervals: PlayTimeInterval[];
}

export interface PlayTimeInterval {
    displayStart: string;
    displayEnd: string;
    type: IntervalType;
}

export const PlaytimePicker: FunctionComponent<PlaytimePickerProps> = ({ playtimes, partyLengthHours, isLoading, value, onChange }) => {
    if (!playtimes) {
        return null;
    }

    return (
        <div className={classes.wrapper}>
            {playtimes && (
                <div className={classes.header}>
                    <Typography variant="title5" component="span">
                        <FormattedMessage id="select-arrival-time.title" defaultMessage="Välj ankomsttid" />
                    </Typography>
                    <Typography variant="body" component="p">
                        <FormattedMessage
                            id="select-arrival-time.play-time-message"
                            defaultMessage="Din lektid är {partyLengthHours} timmar. Vill du leka före, efter eller både före och efter din tid i kalasrummet."
                            values={{ partyLengthHours }}
                        />
                    </Typography>
                </div>
            )}
            {playtimes?.map((item, index) => {
                const comboButtonClasses = clsx({
                    [classes.disabledText]: !item.available,
                });

                return (
                    <div key={item.arrivalTime} className={classes.container}>
                        <ComboButton disabled={!item.available} onClick={() => onChange(item)} className={comboButtonClasses}>
                            <ComboButtonContent>
                                <ComboButtonTitle>
                                    <FormattedMessage id="select-arrival-time.arrival-time" defaultMessage="Ankomsttid" />{' '}
                                    {item.arrivalTime}
                                </ComboButtonTitle>
                            </ComboButtonContent>
                            {item.available && (
                                <ComboButtonAction>
                                    {item.arrivalTime === value?.arrivalTime ? (
                                        <FontAwesomeIcon icon={faCheckCircle} className={classes.iconSelected} height={48} />
                                    ) : (
                                        <FontAwesomeIcon icon={faCircle} className={classes.icon} height={48} />
                                    )}
                                </ComboButtonAction>
                            )}
                        </ComboButton>
                        <div className={classes.content}>
                            {item.available ? (
                                <>
                                    <div className={classes.intervals}>
                                        {item.timeIntervals.map((time) => (
                                            <Fragment key={`${item.arrivalTime}${time.displayStart}`}>
                                                {time.type === IntervalType.Party ? (
                                                    <>
                                                        <div>
                                                            <FontAwesomeIcon icon={faBirthdayCake} width={15} />
                                                        </div>
                                                        <div>
                                                            <Typography variant="body" component="span" className={classes.intervalText}>
                                                                <FormattedMessage
                                                                    id="select-arrival-time.time-interval-party"
                                                                    defaultMessage="Kalasrum"
                                                                />
                                                            </Typography>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>
                                                            <FontAwesomeIcon icon={faFutbol} width={15} />
                                                        </div>
                                                        <div>
                                                            <Typography variant="body" component="span" className={classes.intervalText}>
                                                                <FormattedMessage
                                                                    id="select-arrival-time.time-interval-play"
                                                                    defaultMessage="Lektid"
                                                                />
                                                            </Typography>
                                                        </div>
                                                    </>
                                                )}
                                                <div>
                                                    <Typography variant="body" component="span" className={classes.intervalText}>
                                                        {time.displayStart} - {time.displayEnd}
                                                    </Typography>
                                                </div>
                                            </Fragment>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <Typography variant="body" component="span" className={classes.disabledText}>
                                    <FormattedMessage
                                        id="select-arrival-time.not-available-info"
                                        defaultMessage="Ej valbart på grund av Leklandets öppettider."
                                    />
                                </Typography>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
