import { FunctionComponent } from 'react';
import { InviteDate } from '../InviteDate/InviteDate';
import classes from './InviteTicket.module.scss';

interface InviteTicketProps {
    className?: string;
    date?: Date;
    time?: string;
    withBackground?: boolean;
}

export const InviteTicket: FunctionComponent<InviteTicketProps> = ({ className, date, time, withBackground, children }) => {
    const classNames = [classes.container];
    if (withBackground) {
        classNames.push(classes.withBackground);
    }

    if (date && time) {
        classNames.push(classes.withDate);
    }

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')}>
            <div className={classes.maskTop}>
                <div className={classes.maskImage} />
            </div>

            <div className={classes.maskBottom}>
                <div className={classes.maskImage} />
            </div>

            <div className={classes.content}>{children}</div>

            {date && time && <InviteDate date={date} time={time} className={classes.date} />}
        </div>
    );
};
