import axios, { AxiosError } from 'axios';
import { addYears, differenceInCalendarMonths, differenceInYears } from 'date-fns';
import { DimensionOptions, IImageDto, IMediaItemDto } from './api/api.generated';
import { Config } from './config';
import { Guest } from './model';
import { mediaTypeFromExtension } from './utils/mediaTypeFromExtension';

interface ResponseApiError {
    message?: string | unknown;
    errors?: Record<string, unknown[] | string[]>;
}

/**
 * Parses an unknown error into a possible ApiError.
 */
export class ApiError {
    static Cancellation = new ApiError();

    static fromError(error: unknown): ApiError {
        if (error instanceof ApiError) {
            return error;
        }

        if (axios.isCancel(error)) {
            return this.Cancellation;
        }

        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError<ResponseApiError>;
            if (
                axiosError.response &&
                typeof axiosError.response.data === 'object' &&
                typeof axiosError.response.data.message === 'string'
            ) {
                let { message, errors } = axiosError.response.data;
                const additionalErrors: string[] = [];

                if (typeof errors === 'object') {
                    for (const key of Object.keys(errors)) {
                        if (Array.isArray(errors[key])) {
                            for (const err of errors[key]) {
                                if (typeof err === 'string') {
                                    additionalErrors.push(err);
                                }
                            }
                        }
                    }
                }

                if (additionalErrors.length) {
                    message += `: ${additionalErrors.join(', ')}`;
                }

                return new ApiError(message, axiosError.response.status);
            }
        }

        if (error instanceof Error) {
            return new ApiError(error.message);
        }

        return new ApiError();
    }

    private readonly message?: string;
    private readonly statusCode?: number;

    constructor(message?: string, statusCode?: number) {
        this.message = message;
        this.statusCode = statusCode;
    }

    public getMessage(fallback: string): string {
        return this.message ?? fallback;
    }

    public getStatusCode(): number | undefined {
        return this.statusCode;
    }
}

export function isEmptyGuid(guid: string) {
    return guid === '00000000-0000-0000-0000-000000000000';
}

export function isEmptyGuest(guest: Guest): boolean {
    return isEmptyGuid(guest.guestid) || guest.guestname === null;
}

export function getFirstEmptyGuest(guests: Guest[]): Guest | null {
    return guests.find((g) => isEmptyGuest(g)) ?? null;
}

export function sortGuests(guests: Guest[], locale: string): Guest[] {
    return guests.sort((a, b) => a.guestname.localeCompare(b.guestname, locale));
}

/**
 * Returns an image url for a ImageDto or MediaItemDto.
 *
 * @param image The IImageDto or IMediaItemDto
 * @param width Target width of the image.
 * @param height Target height of the image.
 * @param mode The resize mode to use.
 * @returns The image url.
 */
export function imageUrl(
    image: IImageDto | IMediaItemDto,
    width: number = 120,
    height: number = 120,
    mode: DimensionOptions = DimensionOptions.Cover,
): string {
    const version = 'version' in image ? image.version : 1;
    const mediaType = 'mediaType' in image ? image.mediaType : mediaTypeFromExtension(image.extension);

    const url = new URL(Config.baseUrlCdn);
    url.pathname = `/media/resizemedia/${image.guid}/${version}/${mode}/${mediaType}`;

    url.searchParams.set('width', width.toString());
    url.searchParams.set('height', height.toString());

    return url.toString();
}

/**
 * Checks if both arrays contain the same elements (strict check), independent of order.
 */
export function hasEqualElements(a: unknown[], b: unknown[]): boolean {
    if (a.length !== b.length) {
        return false;
    }

    const aSorted = a.slice().sort();
    const bSorted = b.slice().sort();

    for (let i = 0; i < aSorted.length; i++) {
        if (aSorted[i] !== bSorted[i]) {
            return false;
        }
    }

    return true;
}

/**
 * Calculates an approximation of what age the child is celebrating on a specific date.
 *
 * If it's less than 6 months since they had a birthday, we'll assume they're celebrating that birthday.
 * If it's more than 6 months since they had a birthday, we'll assume they're celebrating their next birthday.
 */
export function celebrationAge(birthdate: Date, at: Date) {
    let age = differenceInYears(at, birthdate);

    // Add the current age to get the last birthday date
    const lastBirthday = addYears(birthdate, age);

    // Check how long ago the last birthday was when they arrive at the playground
    const monthsDiff = differenceInCalendarMonths(at, lastBirthday);

    // If it was more than 6 months since the last birthday, assume the child is celebrating it's
    // next birthday.
    if (monthsDiff > 6) {
        age += 1;
    }

    // Cap age at 1
    return Math.max(age, 1);
}

/**
 * This can be called in a default case of a switch statement to ensure the switch is exhaustive compile-time.
 *
 * If a case is missing from the switch, the compiler will create a warning of this function argument.
 */
export function assertUnreachable(val: never): never {
    throw new Error(`Reached an unreachable value: ${val}`);
}
