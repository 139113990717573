import { useIntl } from 'react-intl';
import { useLocation as useRouterLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from '../contexts/LocationProvider';
import { Playground } from '../model';
import { BookingDetails } from '../model/BookingDetails';
import { Stage } from '../model/stage';

type PathParams = 'playground' | 'guid';

interface BookingPathParams {
    playground?: Playground | null;
    stage: Stage;
    guid?: string;
}

interface BookingParamsService {
    bookingParams: BookingPathParams;
    stagePath: (stage: Stage, details?: BookingDetails, excludeLocale?: boolean) => string;
    openStage: (stage: Stage, details?: BookingDetails, replace?: boolean) => void;
}

export const useBookingParams = (): BookingParamsService => {
    const { locale } = useIntl();
    const navigate = useNavigate();
    const { pathname } = useRouterLocation();
    const params = useParams<PathParams>();
    const { getPlayground } = useLocation();

    // Extract stage from last path segment
    const lastPathSegment = pathname.substring(pathname.lastIndexOf('/') + 1) as Stage;
    const stage = Object.values(Stage).includes(lastPathSegment) ? lastPathSegment : Stage.BookingOverview;

    // Validate playground
    let playground: Playground | undefined | null = null;
    if (params.playground) {
        playground = getPlayground(params.playground);
    }

    // Builds a path to a specific stage
    const stagePath = (stage: Stage, details?: BookingDetails, excludeLocale?: boolean) => {
        let path = excludeLocale ? '' : `/${locale}`;
        if (details) {
            path += `/${details.playground.slug}/${details.guid}`;
        } else if (playground) {
            path += `/${playground.slug}`;
        }

        if (stage !== Stage.BookingOverview) {
            path += `/${stage}`;
        }

        return path;
    };

    // Used to open a specific stage
    const openStage = (stage: Stage, details?: BookingDetails, replace?: boolean) => {
        const path = stagePath(stage, details);
        navigate(path, { replace });
    };

    return {
        bookingParams: {
            playground,
            stage,
            guid: params.guid,
        },
        stagePath,
        openStage,
    };
};
