import { IApplicationUserDto } from '../api/api.generated';
import { maskString } from './maskString';

/** Declare the Analytics Data Layer on the Window object */
declare const window: Window & {
    dataLayer?: Record<string, unknown>[];
};

/**
 * Updates the current user state in Google Analytics.
 *
 * @param user The user to set in analytics.
 */
export function setAnalyticsUser(user: IApplicationUserDto | null): void {
    const analyticsUser = user
        ? {
              userType: 'member',
              firstName: user.firstname,
              lastName: user.lastname ? maskString(user.lastname, 1) : null,
              email: user.email ? maskString(user.email, 1, 4) : null,
              phoneNumber: user.phone ? maskString(`+${user.countrycode}${user.phone}`, 4, 2) : null,
              memberNumber: user.guid,
          }
        : {
              userType: 'guest',
          };

    if (process.env.NODE_ENV === 'development') {
        console.info('[GoogleAnalytics]: Setting user to analytics', analyticsUser);
    }

    window.dataLayer?.push({ user: analyticsUser });
}

/**
 * Pushes a page view to the data layer.
 */
export function trackPageView(location: string, title: string) {
    if (process.env.NODE_ENV === 'development') {
        console.info('[GoogleAnalytics]: Tracking page view to analytics', {
            event: 'page_view',
            page_location: location,
            page_title: title,
        });
    }

    window.dataLayer?.push({
        event: 'page_view',
        page_location: location,
        page_title: title,
    });
}
