import { FunctionComponent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoginIntent } from '../../../api/api-identity.generated';
import { Button } from '../../../components/common/Button/Button';
import { Card, CardText, CardTitle } from '../../../components/common/Card/Card';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { FadeInImage } from '../../../components/utils/FadeInImage/FadeInImage';
import { useBooking } from '../../../contexts/BookingProvider';
import { InviteCard } from '../../../features/invite/InviteCard/InviteCard';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { Stage } from '../../../model/stage';
import { useAuth } from '../../../services/auth/AuthProvider';
import ImageInviteCardPreviewBackground from './invite-card-preview-background.jpg';
import classes from './PreConfirmBooking.module.scss';

export const PreConfirmBooking: FunctionComponent = () => {
    const booking = useBooking();
    const { formatMessage } = useIntl();
    const { user, signIn } = useAuth();
    const { stagePath, openStage } = useBookingParams();

    useEffect(() => {
        document.body.classList.add('white');
        return () => {
            document.body.classList.remove('white');
        };
    }, []);

    const onBackClick = () => {
        openStage(Stage.EditBirthdayKids, booking.details);
    };

    const onConfirmClick = () => {
        if (!user) {
            signIn(LoginIntent.Booking, stagePath(Stage.ConfirmBooking, booking.details));
        } else {
            openStage(Stage.ConfirmBooking, booking.details);
        }
    };

    if (!booking.details) {
        return null;
    }

    return (
        <PageContainer title={formatMessage({ id: 'pre-confirm-booking.title', defaultMessage: 'Inbjudan' })} onBackClick={onBackClick}>
            <Card dark className={classes.card}>
                <CardTitle>
                    <FormattedMessage id="pre-confirm-booking.card-title" defaultMessage="Din bokning är nästan klar!" />
                </CardTitle>
                <CardText>
                    <FormattedMessage
                        id="pre-confirm-booking.card-text"
                        defaultMessage="Vi har skapat ett inbjudningskort att använda när ni bjuder in era gäster efter slutförd bokning. Du kan göra inbjudan mer personlig senare."
                    />
                </CardText>
            </Card>

            <div className={classes.inviteWrapper}>
                <FadeInImage className={classes.inviteBackground} src={ImageInviteCardPreviewBackground} />

                <InviteCard
                    className={classes.inviteCard}
                    hostNames={booking.details.birthdayKids.map((g) => g.guestname)}
                    guestName="[———]"
                    roomName={booking.details.room.name}
                    playgroundName={booking.details.playground.name}
                    date={booking.details.slot.date}
                    startTime={booking.details.startTime}
                    endTime={booking.details.invitation.endTime}
                    invitationMessage={booking.details.invitation.message}
                    preview
                    companyName={booking.details.country.companyname}
                    streetAdress={booking.details.playground.streetadress}
                    playcentertype={booking.details.playground.playcentertype}
                />
            </div>

            <PageButtons alignBottom>
                <Button color="minionYellow" onClick={onConfirmClick}>
                    <FormattedMessage id="general.continue" defaultMessage="Gå vidare" />
                </Button>

                <Button color="transparent" onClick={onBackClick}>
                    <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                </Button>
            </PageButtons>
        </PageContainer>
    );
};
