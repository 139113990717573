import clsx from 'clsx';
import { BaseHTMLAttributes, FunctionComponent } from 'react';
import classes from './Typography.module.scss';

type DisplayVariants = 'display1' | 'display2' | 'display3' | 'display4' | 'display5' | 'display6';
type TitleVariants = 'title1' | 'title2' | 'title3' | 'title4' | 'title5' | 'title6';
type TextVariants = 'preamble' | 'body' | 'label' | 'subtitle' | 'links';

type HeadingComponents = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type TextComponents = 'a' | 'p' | 'span' | 'label' | 'div';

export interface TypographyProps extends BaseHTMLAttributes<HTMLElement> {
    variant?: DisplayVariants | TitleVariants | TextVariants;
    component?: HeadingComponents | TextComponents;
    className?: string;
}

/**
 * Design system Typography with support for all variants.
 */
export const Typography: FunctionComponent<TypographyProps> = ({ variant = 'body', component = 'p', className, children, ...args }) => {
    const Container = component;

    return (
        <Container className={clsx(classes.container, classes[variant], className)} {...args}>
            {children}
        </Container>
    );
};
