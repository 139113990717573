import { formatISO } from 'date-fns';
import { PartyPackage } from 'model';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/common/Button/Button';
import { Card, CardText, CardTitle } from '../../../components/common/Card/Card';
import { PageButtons } from '../../../components/common/PageButtons/PageButtons';
import { PageContainer } from '../../../components/common/PageContainer/PageContainer';
import { useApi } from '../../../contexts/ApiProvider';
import { useBooking } from '../../../contexts/BookingProvider';
import { useGlobalLoading } from '../../../contexts/GlobalLoadingProvider';
import { PackageCarousel } from '../../../features/select-package/PackageCarousel/PackageCarousel';
import useApiError from '../../../hooks/useApiError';
import { useBookingParams } from '../../../hooks/useBookingParams';
import { BookingDetails, BookingStatus } from '../../../model/BookingDetails';
import { Stage } from '../../../model/stage';
import ApiClient from '../../../utils/ApiClient';
import { Error } from '../../Error';
import classes from './SelectPackage.module.scss';

export const SelectPackage: FunctionComponent = () => {
    const booking = useBooking();
    const { client } = useApi();
    const { formatMessage } = useIntl();
    const { incrementLoader, decrementLoader } = useGlobalLoading();
    const { openStage } = useBookingParams();

    const [partyPackages, setPartyPackages] = useState<PartyPackage[] | undefined>();
    const { apiError, setApiError, clearApiError, toastApiError } = useApiError();

    const loadPackages = () => {
        const { token, cancel } = ApiClient.createCancelToken();
        clearApiError();
        setPartyPackages(undefined);

        if (booking.details) {
            incrementLoader();

            const dateString = formatISO(booking.details.slot.date, { representation: 'date' });
            const bookingDateString = formatISO(booking.details.createdDate, { representation: 'date' });

            client
                .getPartyPackages(booking.details.playground.guid, dateString, bookingDateString, token)
                .then((packages) => {
                    setPartyPackages(packages.sort((a, b) => b.price.pricevalue - a.price.pricevalue));
                })
                .catch(setApiError)
                .finally(decrementLoader);
        }

        return cancel;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadPackages, [booking.details]);

    const onPartyPackageSelect = async (partyPackage: PartyPackage) => {
        if (!booking.details) {
            return;
        }

        if (booking.details.partyPackage?.guid === partyPackage.guid) {
            // We didn't change package, jump directly to addons
            return openStage(Stage.SelectAddons, booking.details);
        }

        // Get the default articles
        const defaultArticleGuids = partyPackage.articles.defaultarticles.map((a) => a.guid);

        incrementLoader();

        let details: BookingDetails | undefined;
        try {
            await client.changeArticles(booking.details.guid, defaultArticleGuids);
            details = await client.getBooking(booking.details.guid);
        } catch (error) {
            toastApiError(error);
        }

        decrementLoader();

        if (details) {
            booking.set(details);

            // The addons need to be re-selected once we change party package
            openStage(Stage.SelectAddons, details);
        }
    };

    const onBackClick = async () => {
        if (!booking.details) {
            return;
        }

        if (booking.details.status === BookingStatus.Confirmed && booking.details.hasBookingChanges) {
            // The booking is confirmed and we have changes, cancel the booking changes
            incrementLoader();

            let details: BookingDetails | undefined;
            try {
                details = await client.cancelBookingChanges(booking.details.guid);
            } catch (error) {
                toastApiError(error);
            }

            decrementLoader();

            if (details) {
                booking.set(details);
                openStage(Stage.SelectAddons, booking.details);
            }
        } else if (booking.details.status === BookingStatus.Confirmed) {
            // Confirmed bookings reached the package select stage from the addons page
            openStage(Stage.SelectAddons, booking.details);
        } else {
            // Go back to previous stage
            openStage(Stage.SelectDate, booking.details);
        }
    };

    if (apiError) {
        return <Error error={apiError} onRetryClick={loadPackages} />;
    }

    if (!partyPackages) {
        return null;
    }

    return (
        <PageContainer title={formatMessage({ id: 'select-package.title', defaultMessage: 'Kalaspaket' })} onBackClick={onBackClick}>
            <Card dark className={classes.card}>
                <CardTitle>
                    <FormattedMessage id="select-package.card-title" defaultMessage="Dags att välja paket" />
                </CardTitle>
                <CardText>
                    <FormattedMessage
                        id="select-package.card-text"
                        defaultMessage="Här hittar du våra mest populära kalaspaket. I nästa steg kan du göra fler tillval."
                    />
                </CardText>
            </Card>

            <PackageCarousel packages={partyPackages} className={classes.carousel} onSelect={onPartyPackageSelect} />

            <PageButtons alignBottom>
                <Button color="transparent" onClick={onBackClick}>
                    <FormattedMessage id="general.back" defaultMessage="Tillbaka" />
                </Button>
            </PageButtons>
        </PageContainer>
    );
};
