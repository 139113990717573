import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { currentDomainConfig } from '../../../domain-config';
import { useCountries } from '../../../hooks/useCountries';
import { FadeInImage } from '../../utils/FadeInImage/FadeInImage';
import { FontAwesomeIcon } from '../FontAwesomeIcon/FontAwesomeIcon';
import { IconButton } from '../IconButton/IconButton';
import classes from './Header.module.scss';
import ImageLogoCoBranded from './leos-hoplop.png';
import ImageLogo from './logo.png';

interface HeaderProps {
    onMenuClick?(): void;
}

/**
 * Design system header.
 */
export const Header: FunctionComponent<HeaderProps> = ({ onMenuClick }) => {
    const { formatMessage } = useIntl();
    const { currentCountry } = useCountries();

    const isCoBranded = currentDomainConfig.countrySlug === 'finland';

    return (
        <>
            <div className={classes.container}>
                <a href={currentCountry.urlHome}>
                    <FadeInImage
                        className={isCoBranded ? classes.logoCoBranded : classes.logo}
                        src={isCoBranded ? ImageLogoCoBranded : ImageLogo}
                        alt={formatMessage({ id: 'general.leos-play', defaultMessage: 'Leo´s lekland' })}
                    />
                </a>

                {onMenuClick && (
                    <IconButton className={classes.menuButton} rippleClassName={classes.menuButtonRipple} onClick={onMenuClick}>
                        <FontAwesomeIcon icon={faBars} width={27} />
                    </IconButton>
                )}
            </div>
        </>
    );
};
