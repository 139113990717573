import { faCalendarAlt, faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import {
    faBirthdayCake,
    faCheck,
    faChevronRight,
    faChild,
    faEnvelopeOpenText,
    faMapMarkerAlt,
    faQuestionCircle,
    faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { FunctionComponent } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { IconBalloons } from '../../assets/icons';
import {
    ComboButton,
    ComboButtonAction,
    ComboButtonBottomText,
    ComboButtonContent,
    ComboButtonTitle,
    ComboButtonTopText,
} from '../../components/common/ComboButton/ComboButton';
import { FontAwesomeIcon } from '../../components/common/FontAwesomeIcon/FontAwesomeIcon';
import { Config } from '../../config';
import { BookingService } from '../../contexts/BookingProvider';
import { useBookingParams } from '../../hooks/useBookingParams';
import { celebrationAge } from '../../utils';
import { AttendanceList } from '../common/AttendenceList';
import classes from './OverviewCard.module.scss';

interface OverviewCardBaseProps {
    disabled?: boolean;
    done?: boolean;
    overlay?: boolean;
    className?: string;

    onClick?(): void;
}

interface OverviewCardProps {
    booking: BookingService;
    className?: string;

    onClick?(): void;
}

/**
 * Generic booking-overview card, used by the specific booking-overview cards.
 */
const OverviewCardBase: FunctionComponent<OverviewCardBaseProps> = ({ children, disabled, done, overlay, className, onClick }) => {
    return (
        <ComboButton className={className} disabled={disabled} overlay={overlay} onClick={onClick}>
            {children}

            {(done || !disabled) && (
                <ComboButtonAction color={done && disabled ? 'dinosaurGreen' : 'ballPitBlue'}>
                    {done && disabled && <FontAwesomeIcon icon={faCheck} width={29} />}
                    {!disabled && <FontAwesomeIcon icon={faChevronRight} height={23.46} />}
                </ComboButtonAction>
            )}
        </ComboButton>
    );
};

/**
 * Overview card for selected playground.
 */
export const OverviewCardPlayground: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    const { bookingParams } = useBookingParams();
    const { formatMessage } = useIntl();

    const title = formatMessage({ id: 'overview.card-select-playground-title', defaultMessage: 'Välj lekland' });

    return (
        <OverviewCardBase className={className} disabled={!!booking.details} done={!!bookingParams.playground} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faMapMarkerAlt} height={34} />
            </ComboButtonAction>

            <ComboButtonContent>
                {bookingParams.playground ? (
                    <>
                        <ComboButtonTopText>{title}</ComboButtonTopText>
                        <ComboButtonTitle children={bookingParams.playground.name} />
                    </>
                ) : (
                    <ComboButtonTitle>{title}</ComboButtonTitle>
                )}
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for date and room.
 */
export const OverviewCardDateRoom: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    const { formatMessage } = useIntl();
    const { bookingParams } = useBookingParams();

    let disabled = !bookingParams.playground;
    if (booking.details && !booking.details.canChangeDate) {
        disabled = true;
    }

    const title = formatMessage({ id: 'overview.card-date-room-title', defaultMessage: 'Datum & tid' });

    return (
        <OverviewCardBase className={className} disabled={disabled} done={!!booking.details} overlay={!booking.details} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faCalendarAlt} height={28} />
            </ComboButtonAction>

            <ComboButtonContent>
                {booking.details ? (
                    <>
                        <ComboButtonTopText>{title}</ComboButtonTopText>

                        <ComboButtonTitle>
                            <FormattedDate value={booking.details.slot.date} weekday="long" day="numeric" month="long" />
                        </ComboButtonTitle>

                        <ComboButtonBottomText>
                            {booking.details.room.name}
                            <br />
                            <FormattedMessage
                                id="overview.card-date-room-time"
                                defaultMessage="Klockan {time}"
                                values={{ time: booking.details.startTime }}
                            />
                        </ComboButtonBottomText>
                    </>
                ) : (
                    <ComboButtonTitle>{title}</ComboButtonTitle>
                )}
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for the guest count.
 */
export const OverviewCardGuestCount: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    const done = !!booking.details?.guestCount;
    const disabled = !booking.details?.canChangeGuests;

    return (
        <OverviewCardBase className={className} disabled={disabled} done={done} overlay={!booking.details} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faChild} width={24} />
            </ComboButtonAction>

            <ComboButtonContent>
                {booking.details ? (
                    <>
                        <ComboButtonTopText>
                            <FormattedMessage id="booking-overview.guest-count-title" defaultMessage="Antal gäster" />
                        </ComboButtonTopText>
                        <ComboButtonTitle>
                            <FormattedMessage
                                id="booking-overview.guest-count-text"
                                defaultMessage="{count} stycken"
                                values={{ count: booking.details.guestCount }}
                            />
                        </ComboButtonTitle>
                    </>
                ) : (
                    <ComboButtonTitle>
                        <FormattedMessage id="booking-overview.guest-count-title" defaultMessage="Antal gäster" />
                    </ComboButtonTitle>
                )}
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for the birthday kids.
 */
export const OverviewCardBirthdayKids: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    const done = !!booking.details?.birthdayKids.length;
    const disabled = !booking.details?.canChangeGuests;

    const kids = booking.details?.birthdayKids.map((kid, index, birthdayKids) => {
        const birthdate = kid.birthdaydate ? parseISO(kid.birthdaydate) : new Date();
        const bookingDate = booking.details?.slot.date ?? new Date();

        return (
            <span key={kid.inviteid}>
                <FormattedMessage
                    id="overview.card-birthday-kids-name"
                    defaultMessage="{name}, {age} år"
                    values={{
                        name: kid.guestname,
                        age: celebrationAge(birthdate, bookingDate),
                    }}
                />
                {index < birthdayKids.length - 1 && <br />}
            </span>
        );
    });

    return (
        <OverviewCardBase className={className} disabled={disabled} done={done} overlay={!booking.details} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faBirthdayCake} width={28} />
            </ComboButtonAction>

            <ComboButtonContent>
                {booking.details ? (
                    <>
                        <ComboButtonTopText>
                            <FormattedMessage id="booking-overview.birthday-kids-title" defaultMessage="Födelsedagsbarn" />
                        </ComboButtonTopText>
                        <ComboButtonTitle children={kids} />
                    </>
                ) : (
                    <ComboButtonTitle>
                        <FormattedMessage id="booking-overview.birthday-kids-title" defaultMessage="Födelsedagsbarn" />
                    </ComboButtonTitle>
                )}
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for the party package and addons.
 */
export const OverviewCardPackageAndAddons: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    const { formatMessage } = useIntl();
    const addonArticles = booking.details?.articles.filter((article) => article.itemtype === 'Addon' || article.itemtype === 'Exchange');
    const disabled = !booking.details?.canChangeArticles ?? false;
    const done = !!addonArticles?.length;

    const title = formatMessage({ id: 'overview.card-package-and-addons-title', defaultMessage: 'Kalaspaket & tillval' });

    return (
        <OverviewCardBase className={className} disabled={disabled} done={done} overlay={!booking.details} onClick={onClick}>
            <ComboButtonAction>
                <IconBalloons height="40" />
            </ComboButtonAction>

            <ComboButtonContent>
                {booking.details ? (
                    <>
                        <ComboButtonTopText>{title}</ComboButtonTopText>
                        <ComboButtonTitle>{booking.details.partyPackage?.name}</ComboButtonTitle>

                        {addonArticles && addonArticles?.length > 0 && (
                            <ComboButtonBottomText>
                                {addonArticles.map((article, index) => (
                                    <span key={article.guid}>
                                        {article.name}
                                        {index < addonArticles.length - 1 && <br />}
                                    </span>
                                ))}
                            </ComboButtonBottomText>
                        )}
                    </>
                ) : (
                    <ComboButtonTitle>{title}</ComboButtonTitle>
                )}
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for the invite design.
 */
export const OverviewCardInvite: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    return (
        <OverviewCardBase className={className} disabled={!booking.details} overlay={!booking.details} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faEnvelopeOpenText} width={30} />
            </ComboButtonAction>

            <ComboButtonContent>
                <ComboButtonTitle>
                    <FormattedMessage id="overview.card-design-invite-card-title" defaultMessage="Designa inbjudningskort" />
                </ComboButtonTitle>
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for the invite design.
 */
export const OverviewCardInviteAndGuests: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    return (
        <OverviewCardBase className={className} disabled={!booking.details} overlay={!booking.details} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faChild} width={24} />
            </ComboButtonAction>

            <ComboButtonContent>
                <ComboButtonTitle>
                    <FormattedMessage id="overview.card-design-and-invite-title" defaultMessage="Bjud in gäster och designa kort" />
                </ComboButtonTitle>
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for the guest list.
 */
export const OverviewCardGuests: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    const { formatMessage } = useIntl();
    const statusInvited = booking.details?.guestList.filter((g) => !g.ismainguest && g.status.toLowerCase() === 'invited').length ?? 0;
    const statusAccepted = booking.details?.guestList.filter((g) => !g.ismainguest && g.status.toLowerCase() === 'accepted').length ?? 0;
    const statusDeclined = booking.details?.guestList.filter((g) => !g.ismainguest && g.status.toLowerCase() === 'cancelled').length ?? 0;
    const birthdayKids = booking.details?.birthdayKids.length ?? 0;
    const hasInvitedGuests = !!booking.details?.guests.length;

    const cardTitle = formatMessage({ id: 'overview.card-guests-title', defaultMessage: 'Inbjudningar' });

    return (
        <OverviewCardBase className={className} disabled={!booking.details} overlay={!booking.details} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faChild} width={24} />
            </ComboButtonAction>

            <ComboButtonContent>
                {hasInvitedGuests ? (
                    <>
                        <ComboButtonTopText>{cardTitle}</ComboButtonTopText>

                        <AttendanceList
                            noAnswer={statusInvited}
                            accepted={statusAccepted}
                            declined={statusDeclined}
                            birthdayKids={birthdayKids}
                            className={classes.guestList}
                        />
                    </>
                ) : (
                    <ComboButtonTitle>
                        {!booking.details || booking.details?.canChangeGuests ? (
                            <FormattedMessage id="overview.card-guests-invite-title" defaultMessage="Bjud in gäster" />
                        ) : (
                            cardTitle
                        )}
                    </ComboButtonTitle>
                )}
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for the booking details.
 */
export const OverviewCardDetails: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    const { formatNumber } = useIntl();

    if (!booking.details) {
        return null;
    }

    return (
        <OverviewCardBase className={className} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faCreditCard} width={32} />
            </ComboButtonAction>

            <ComboButtonContent>
                <ComboButtonTopText>
                    <FormattedMessage id="overview.card-details-title" defaultMessage="Bokningsbekräftelse" />
                </ComboButtonTopText>
                <ComboButtonTitle>
                    {formatNumber(booking.details.price.pricevalue, {
                        style: 'currency',
                        currency: booking.details.price.currency,
                        minimumFractionDigits: 0,
                    })}
                </ComboButtonTitle>
                <ComboButtonBottomText>
                    <FormattedMessage id="overview.card-details-text" defaultMessage="Du betalar på leklandet" />
                </ComboButtonBottomText>
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for booking cancellation.
 */
export const OverviewCardCancel: FunctionComponent<OverviewCardProps> = ({ booking, className, onClick }) => {
    const bookingDate = booking.details?.slot.date ?? new Date();
    const daysToParty = differenceInCalendarDays(bookingDate, new Date());
    const disabled = daysToParty < Config.cancelDeadlineInDays;

    return (
        <OverviewCardBase className={className} disabled={disabled} overlay={!booking.details} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faTimesCircle} width={26} />
            </ComboButtonAction>

            <ComboButtonContent>
                <ComboButtonTitle>
                    <FormattedMessage id="overview.card-cancellation-title" defaultMessage="Avboka kalas" />
                </ComboButtonTitle>
                {disabled && (
                    <ComboButtonBottomText>
                        <FormattedMessage id="overview.card-cancellation-text" defaultMessage="Kontakta ditt lekland vid avbokning" />
                    </ComboButtonBottomText>
                )}
            </ComboButtonContent>
        </OverviewCardBase>
    );
};

/**
 * Overview card for the FAQ.
 */
export const OverviewCardFAQ: FunctionComponent<OverviewCardProps> = ({ className, onClick }) => {
    return (
        <OverviewCardBase className={className} onClick={onClick}>
            <ComboButtonAction>
                <FontAwesomeIcon icon={faQuestionCircle} width={26} />
            </ComboButtonAction>

            <ComboButtonContent>
                <ComboButtonTitle>
                    <FormattedMessage id="overview.card-faq-title" defaultMessage="Frågor & svar" />
                </ComboButtonTitle>
                <ComboButtonBottomText>
                    <FormattedMessage id="overview.card-faq-text" defaultMessage="Klicka för att komma till vår sida för frågor och svar" />
                </ComboButtonBottomText>
            </ComboButtonContent>
        </OverviewCardBase>
    );
};
