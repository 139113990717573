import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { currentDomainConfig } from '../domain-config';

/**
 * Redirects to the best matching language of the user's preference, or the default language.
 */
export const LanguageRedirect: FunctionComponent = () => {
    return <Navigate to={`/${currentDomainConfig.localeCode}`} replace />;
};
