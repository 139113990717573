import { SnackbarProvider } from 'notistack';
import { FunctionComponent } from 'react';
import { IntlProvider } from 'react-intl';
import { Outlet, useParams } from 'react-router-dom';
import classes from './App.module.scss';
import { Decoration } from './components/common/Decoration/Decoration';
import { EnvironmentBanner } from './components/common/EnvironmentBanner/EnvironmentBanner';
import { DocumentTitle } from './components/utils/DocumentTitle';
import { PageViewTracker } from './components/utils/PageViewTracker';
import { ApiProvider } from './contexts/ApiProvider';
import { GlobalLoadingProvider } from './contexts/GlobalLoadingProvider';
import { LocationProvider } from './contexts/LocationProvider';
import { currentDomainConfig } from './domain-config';
import { PartyHeader } from './features/common/PartyHeader';
import { NotFound } from './routes/NotFound';
import { AuthProvider } from './services/auth/AuthProvider';
import { injectCookieBot } from './utils/cookieBot';
import { defaultLocale, LocaleCode, localeMessages, locales } from './utils/locale';

type AppParams = 'locale';

export const App: FunctionComponent = () => {
    const { locale } = useParams<AppParams>() as { locale: LocaleCode };

    // Always show a NotFound page here if we have a locale in the path (the route is matching) but it's not valid.
    // The router will still try to render the child routes.
    const invalidLocale = locale && !locales.includes(locale);

    // Update html lang
    document.documentElement.lang = currentDomainConfig.localeCode;

    injectCookieBot(currentDomainConfig.localeCode);

    return (
        <IntlProvider
            locale={currentDomainConfig.localeCode}
            messages={localeMessages[currentDomainConfig.localeCode]}
            defaultLocale={defaultLocale}
        >
            <DocumentTitle />
            <PageViewTracker />
            <Decoration />
            <SnackbarProvider maxSnack={3} className={classes.snackbar}>
                <GlobalLoadingProvider>
                    <AuthProvider>
                        <ApiProvider>
                            <PartyHeader />
                            <LocationProvider>{invalidLocale ? <NotFound /> : <Outlet />}</LocationProvider>
                        </ApiProvider>
                    </AuthProvider>
                </GlobalLoadingProvider>
            </SnackbarProvider>
            <EnvironmentBanner />
        </IntlProvider>
    );
};
